import React from 'react';
import { Skeleton } from '@mantine/core';
import PostLayoutSkeleton from './PostLayoutSkeleton';

const TextPostSkeleton = () => {
  return (
    <PostLayoutSkeleton>
      <div style={{ padding: '0 0 15px 0' }}>
        <Skeleton height={14} width="100%" mb={8} />
        <Skeleton height={14} width="90%" mb={8} />
        <Skeleton height={14} width="95%" />
      </div>
    </PostLayoutSkeleton>
  );
};

export default TextPostSkeleton; 