import { useMutation } from "@tanstack/react-query";
import { usePost } from "./usePost";
import { updatePost } from "../../services/apiPost";

export const useUpdatePost = ({closeModal, resetStates,community_id,post_tab,label,post_id}) => {
    const {invalidatePostQueries, handleError, handleSuccess} = usePost();
    return useMutation({
        mutationFn: ({communityId,postId,data}) => updatePost({communityId,postId,data}),
        onSuccess: (_,variables) => {
            console.log('variables',variables);
            invalidatePostQueries(community_id,post_id,'posts',null,null,post_tab,null);
            handleSuccess('Post uğurla yeniləndi');
            resetStates();
            closeModal();
        },
        onError: (error) => handleError(error),
    })
}   
