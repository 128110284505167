import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";

import parnot_1 from "../../../Assets/SVGs/landing/partnor-1.svg";
import parnot_2 from "../../../Assets/SVGs/landing/partnor-2.svg";
import parnot_3 from "../../../Assets/SVGs/landing/partnor-3.svg";
import parnot_4 from "../../../Assets/SVGs/landing/partnor-4.svg";
import parnot_5 from "../../../Assets/SVGs/landing/partnor-5.svg";

const PartnerWrapper = styled.section`
  padding: 50px 0;
  background: #fff;
  
  .swiper {
    padding: 15px 0;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    
    @media (max-width: 768px) {
      height: 80px;
    }
  }

  .partner-container {
    background: #fff;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    
    @media (max-width: 768px) {
      margin-top: 15px;
    }
  }
`;

const Title = styled(motion.h2)`
  color: #0d4b6b;
  text-align: center;
  font-size: 32px;
  max-width: 90%;
  margin: 15px auto;
  margin-bottom: 40px;

  
  @media (max-width: 768px) {
    font-size: 24px;
    margin: 0px auto;
    margin-bottom: 30px;
  }
`;

const PartnerImage = styled.img`
  max-width: 160px;
  height: auto;
  object-fit: contain;
  margin: 0 auto;
  
  @media (max-width: 1024px) {
    max-width: 140px;
  }
  
  @media (max-width: 768px) {
    max-width: 120px;
  }
`;

const Partner = ({props}) => {
  const { t } = props;
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.3 });

  const titleVariants = {
    hidden: { 
      opacity: 0, 
      y: 50 // Başlanğıc pozisiyası - 50px aşağıda
    },
    visible: {
      opacity: 1,
      y: 0, // Son pozisiya - normal yerində
      transition: {
        duration: 0.8,
        ease: "easeOut"
      }
    }
  };

  const partners = [
    { id: 1, image: parnot_1 },
    { id: 2, image: parnot_2 },
    { id: 3, image: parnot_3 },
    { id: 4, image: parnot_4 },
    { id: 5, image: parnot_5 },
  ];

  return (
    <PartnerWrapper ref={ref}>
      <Title
        variants={titleVariants}
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
      >
        {t("partners")}
      </Title>
      <div className="partner-container">
        <Swiper
          modules={[Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          loop={true}
          speed={10000}
          autoplay={{
            delay: 0,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 4,
            },
          }}
        >
          {partners.map((partner) => (
            <SwiperSlide key={partner.id}>
              <PartnerImage src={partner.image} alt={`Partner ${partner.id}`} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </PartnerWrapper>
  );
};

export default Partner;
