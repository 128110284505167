import { useQuery } from "@tanstack/react-query"
import { getHighlightReactions } from "../../../services/apiPost"

export const useGetAllHighlightReactions = (community_id, post_id, highlight_id) => {

    return useQuery({
        queryKey: ['all-highlight-reactions', community_id, post_id, highlight_id],
        queryFn: () => getHighlightReactions({community_id, post_id, highlight_id}),
        enabled: !!community_id && !!post_id && !!highlight_id,
    })
}
