import { createContext, useContext, useState } from 'react';

const CommunityContext = createContext();

export const CommunityProvider = ({ children }) => {
  const [communityData, setCommunityData] = useState({
    cover_photo: null,
    category: null,
    name: '',
    description: '',
    lang_id: null,
    price: '',
    is_free: false,
    is_active: true
  });
  const [communityId, setCommunityId] = useState(null);
  const [highlightId, setHighlightId] = useState(null);
  const [openedCommunityEditModal, setOpenedCommunityEditModal] = useState(false);
  const [openedHighlightEditModal, setOpenedHighlightEditModal] = useState(false);
  const updateCommunityData = (field, value) => {
    setCommunityData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const resetCommunityData = () => {
    setCommunityData({
      cover_photo: null,
      category: null,
      name: '',
      description: '',
      lang_id: null,
      price: '',
      is_free: false,
      is_active: true
    });
  };

  return (
    <CommunityContext.Provider 
      value={{ 
        communityData, 
        updateCommunityData,
        setCommunityData,
        resetCommunityData,
        communityId,
        setCommunityId,
        openedCommunityEditModal,
        setOpenedCommunityEditModal,
        openedHighlightEditModal,
        setOpenedHighlightEditModal,
        highlightId,
        setHighlightId,
      }}
    >
      {children}
    </CommunityContext.Provider>
  );
};

export const useCommunityContext = () => {
  const context = useContext(CommunityContext);
  if (!context) {
    throw new Error('useCommunity must be used within a CommunityProvider');
  }
  return context;
};
