import axios from "../../../Helpers/Axios";

export const createCommunity = async (data) => {
  const response = await axios.post("/community/create-community", data);
  return response.data;
};

export const updateCommunity = async ({id, data}) => {
  const response = await axios.put(`/community/${id}/`, data);
  return response.data;
};

export const getAllCommunity = async (params, page = 1, queryfilterParams) => {
  const { search, category } = queryfilterParams || {};
  const response = await axios.get(`/community/communities/search`, {
    params: {
      ...params,
      page,
      name: search || undefined,
      category: category || undefined
    }
  });
  return response.data;
};
export const getCommunity = async (id) => {
  const response = await axios.get(`/community/${id}/`);
  return response.data;
};


export const joinCommunity = async (id) => {
  const response = await axios.post(`/community/${id}/join-the-community`);
  return response.data;
};
