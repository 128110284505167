import { useQuery } from "@tanstack/react-query";
import { getAwardBadges } from "../../services/apiBadges";


export const useGetAwardBadges = (id) => {
    return useQuery({
        queryKey: ['awardBadges', id],
        queryFn: () => getAwardBadges(id),
    });
};
