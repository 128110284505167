import React, { Suspense } from "react";
import { AppContextProvider } from "./Helpers/Context";
import { SuspenseSpinner } from "./Components/Loaders/SuspenseSpinner";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { StudyAbroadProvider } from "./Helpers/Contexts/StudyAbroadContext";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import AppContent from "./Routes/AppContent"; // Import the AppContent component
import { HelmetProvider } from "react-helmet-async";
import { LanguageProvider } from "./Helpers/Contexts/LanguageContext";
import LottieLoading from "./Components/Loaders/LottieLoading";
import { CommunityProvider } from "./features/Community/context/CommunityContext";

function App() {
  console.log(process.env.REACT_APP_VERSION);
  return (
    <HelmetProvider>
      <MantineProvider
        theme={{
          colorScheme: "light",
          fontFamily: "Euclid, sans-serif",
          colors: { ts: ["#ffffff", "#ffe01b"], white: ["#ffffff"] },
        }}
      >
        <AppContextProvider>
          <LanguageProvider>
            <StudyAbroadProvider>
              <CommunityProvider>
                <ReactQueryDevtools />
                <SuspenseSpinner>
                  <Suspense fallback={<LottieLoading />}>
                    <NotificationsProvider position="top-center">
                      <BrowserRouter>
                        <ScrollToTop />
                        <AppContent />
                      </BrowserRouter>
                    </NotificationsProvider>
                  </Suspense>
                </SuspenseSpinner>
              </CommunityProvider>
            </StudyAbroadProvider>
          </LanguageProvider>
        </AppContextProvider>
      </MantineProvider>
    </HelmetProvider>
  );
}

export default App;
