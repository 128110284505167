import { useQuery } from "@tanstack/react-query"
import { getComments } from "../../../services/apiPost"
import { usePost } from "../usePost";

export const useGetComment = (community_id, post_id, page) => {
    const {queryKeys} = usePost();
    return useQuery({
        queryKey: ["comments",community_id,post_id,page],
        queryFn: () => getComments({ community_id, post_id, page }),
    })
}
