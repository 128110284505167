import React, { useState } from "react";
import PostLayout from "./PostLayout";
import { QuizPostStyled } from "../../style";
import { useParams } from "react-router-dom";
import { useAnsweredToPost } from "../../hooks/post/useAnsweredToPost";
import { useAnswerToHighlightPost } from "../../hooks/highlight/useAnswerToHighlightPost";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
const QuizPost = ({type,post,postTab,label}) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(null);
  const {id: communityId,highlightId} = useParams();
  const { text } = post;
  const question = post?.questions[0];
  const options = question?.community_question_answers_output;
  const totalVotes = options.reduce((sum, option) => sum + option.votes, 0);
  const {mutate: answeredToPost} = useAnsweredToPost(communityId,type,postTab,label);
  const {mutate: answeredToHighlightPost} = useAnswerToHighlightPost(communityId,highlightId,type,postTab,label);

  const handleAnswer = (optionId) => {
    if (!question.is_answered) {
      setSelectedOption(optionId);
      // Call API to register answer
      if(highlightId){
        answeredToHighlightPost({
          post_id: post.id,
          question_id: question.id,
          answer_id: optionId
        });
      }else{
        answeredToPost({
          post_id: post.id,
          question_id: question.id,
          answer_id: optionId
        });
      }
    }
  };

  const calculatePercentage = (votes) => {
    if (totalVotes === 0) return 0;
    return ((votes / totalVotes) * 100).toFixed(1);
  };
  const xs = useMediaQuery('(max-width: 768px)');

  return (
      <QuizPostStyled>
        <h3 className="quiz-question">{text}</h3>
        <div className="quiz-options">
          {options.map((option) => (
            <div
              key={option.id}
              className={`quiz-option ${
                question.is_answered ? (option.is_true_output ? 'correct' : 'incorrect') : ''
              } ${(selectedOption === option.id || option.user_answer) ? 'selected' : ''}`}
              onClick={() => handleAnswer(option.id)}
            >
              {question.is_answered && (
                <div
                  className="progress-bar"
                  style={{ width: `${calculatePercentage(option.votes)}%` }}
                />
              )}
              
              <div className="option-content">
                <div className={`radio-button ${(selectedOption === option.id || option.user_answer) ? 'selected' : ''}`} />
                <span className="option-text">{option?.answer_text}</span>
                {question.is_answered && (
                  <div className="option-stats visible">
                    <span>{calculatePercentage(option.votes)}%</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className={`total-answers ${question.is_answered ? 'visible' : ''}`}>
          {t("total-answers")}: {totalVotes}
        </div>
      </QuizPostStyled>
  );
};

export default QuizPost;
