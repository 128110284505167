import { useMutation } from "@tanstack/react-query";
import { useCommunity } from "./useCommunity";
import { createCommunity } from "../../services/apiCommunity";
import { useNavigate } from "react-router-dom";

export const useCreateCommunity = () => {
  const {invalidateCommunityQueries, handleError, handleSuccess } = useCommunity();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data) => createCommunity(data),
    onSuccess: (data) => {
      invalidateCommunityQueries();
      handleSuccess('Community created successfully');
      localStorage.setItem('isFirstHighlight',true);
      navigate(`/communities?id=${data.message.id}&createHighlight=active`);
      localStorage.setItem('communityId', data.message.id);
      localStorage.removeItem('communityData');
    },
    onError: (error) => handleError(error),
  });
};
