import React, { useEffect, useState } from "react";
import { Accordion, Text, Group } from "@mantine/core";
import { CommunityTabsStyle } from "../../../../../Components/NewDashboard/Navbar";
import CommunityTabs from "../../../../../Components/NewDashboard/Navbar/CommunityTabs";
import { useLocation, useParams } from "react-router-dom";
import {
  Home,
  Award,
  ChartBar,
  Bulb,
  Bookmark,
  Users,
} from "tabler-icons-react";
import { useTranslation } from "react-i18next";
const ManageAccordion = () => {
  const location = useLocation();
  const { id } = useParams();
  const [activeTabTitle, setActiveTabTitle] = useState("");
  const [accordionOpen, setAccordionOpen] = useState(false);
  const { t } = useTranslation();

  const tabs = [
    {
      title: t("home-community"),
      icon: Home,
      path: `/community/${id}/owner`,
    },
    {
      title: t("badges"),
      icon: Award,
      path: `/community/${id}/badges`,
    },
    {
      title: t("collaborator-list"),
      icon: Users,
      path: `/community/${id}/collaborators`,
    },
    {
      title: t("admin-analytics"),
      icon: ChartBar,
      path: `/community/${id}/analytics`,
    },
    {
      title: t("community-highlights"),
      icon: Bulb,
      path: `/community/${id}/highlights`,
    },
    {
      title: t("saved-posts"),
      icon: Bookmark,
      path: `/community/${id}/saved`,
    },
  ];
  useEffect(() => {
    const currentPath = location.pathname;
    const activeTab = tabs.find((tab) => tab.path === currentPath);
    if (activeTab) {
      setActiveTabTitle(activeTab);
    }
  }, [location.pathname]);
  return (
    <CommunityTabsStyle>
      <Accordion
        className="manage-accordion"
        value={accordionOpen ? "manage" : null}
        onChange={(value) => setAccordionOpen(value === "manage")}
      >
        <Accordion.Item value="manage">
          <Accordion.Control>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Text size={17} weight={600} color="#029199">
                {t("manage")}
              </Text>
              {activeTabTitle && !accordionOpen && (
                <Group mt={4} spacing={6} align="center" gap="4px">
                  <activeTabTitle.icon size={20} color="#595959" />
                  <Text mt={3} size={16} color="#595959" weight={500}>
                    {activeTabTitle.title}
                  </Text>
                </Group>
              )}
            </div>
          </Accordion.Control>
          <Accordion.Panel>
            <CommunityTabs
              tabs={tabs}
              onTabChange={(title) => setActiveTabTitle(title)}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </CommunityTabsStyle>
  );
};

export default ManageAccordion;
