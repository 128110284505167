import React, { useState, useEffect } from "react";
import { Select, TextInput, ActionIcon } from "@mantine/core";
import styled from "styled-components";
import {

  IconCheck,
} from "@tabler/icons-react";
import { CustomSelectStyled } from "../../style";
import { useGetBadgesList } from "../../hooks/badges/useGetBadges";
import { useTranslation } from "react-i18next";

const TableContainer = styled.div`
  padding-bottom: 40px;
`;


const TableHeaderRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr auto;
  gap: 16px;
  padding: 12px 16px;
  background: rgba(2, 145, 153, 0.1);
  border-radius: 8px;
  margin-bottom: 12px;

  div {
    color: #029199;
    font-weight: 500;
  }
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 0.9fr 3fr;
  gap: 18px;
  align-items: center;
  padding: 8px 25px 8px 0px;

  .actions {
    display: flex;
    gap: 8px;
  }
`;

const StyledInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1.5fr auto;
  align-items: center;
  gap: 16px;
  width: 100%;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 18px 14px;
`;

const StyledTextInput = styled(TextInput)`
  input {
    border: 1px solid #D2D2D2;
    border-radius: 7px;
    height: 39px;
    font-size: 14px;

    &:focus {
      border-color: #029199;
    }

    &::placeholder {
      color: #adb5bd;
    }
  }
`;

const CustomSelect = ({ currentValue, onChange }) => {
  
  const {t} = useTranslation();

  const [value, setValue] = useState(currentValue);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const {data} = useGetBadgesList();

  const handleSelect = (item) => {
    setValue(item.id);
    setIsOpen(false);
    onChange(item.id);
  };

  useEffect(() => {
    setValue(currentValue);
    setSelectedItem(data?.find((item) => item.id === currentValue));
  }, [currentValue, data]);


  return (
    <CustomSelectStyled>
      <div className="select-header" onClick={() => setIsOpen(!isOpen)}>
        {selectedItem ? (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <div style={{marginTop: 4}} dangerouslySetInnerHTML={{ __html: selectedItem.svg_icon }} />
              {selectedItem.name}
          </div>
        ) : (
          t("select-a-badge")
        )}
      </div>
      {isOpen && (
        <div className="select-dropdown">
          {data?.map((item) => (
            <div
              key={item.id}
              className={`select-item ${
                value === item.id ? "selected" : ""
              }`}
              onClick={() => handleSelect(item)}
            >
              <div dangerouslySetInnerHTML={{ __html: item.svg_icon }} />
              {item.name}
            </div>
          ))}
        </div>
      )}
    </CustomSelectStyled>
  );
};



const BadgeTable = ({ onAddBadge, editingBadge }) => {

  const {t} = useTranslation();

  const [newBadge, setNewBadge] = useState({
    badge: editingBadge?.id || '',
    posts_count: editingBadge?.posts_count || '',
    description: editingBadge?.description || '',
  });

  // Update form when editingBadge changes
  useEffect(() => {
    if (editingBadge) {
      setNewBadge({
        badge: editingBadge.id,
        posts_count: editingBadge.posts_count,
        description: editingBadge.description,
      });
    }
  }, [editingBadge]);

  const handleAddBadge = () => {
    onAddBadge(newBadge);
  };

  return (
    <TableContainer>
      <TableHeaderRow>
        <div>{t("badge")}</div>
        <div>{t("post-count-badge")}</div>
        <div>{t("description")}</div>
        <div></div>
      </TableHeaderRow>

      <TableRow>
        <CustomSelect
          currentValue={newBadge.badge}
          onChange={(value) => setNewBadge({ ...newBadge, badge: value })}
        />
        <StyledInputWrapper>
          <StyledTextInput
            type="number"
            value={newBadge.posts_count}
            onChange={(e) => setNewBadge({ ...newBadge, posts_count: e.target.value })}
            placeholder={t("enter-count")}
          />
          <StyledTextInput
            value={newBadge.description}
            onChange={(e) => setNewBadge({ ...newBadge, description: e.target.value })}
            placeholder={t("enter-description")}
          />
          <div className="actions">
            <ActionIcon 
              color="green" 
              variant="subtle" 
              onClick={handleAddBadge}
              disabled={!newBadge.badge || !newBadge.posts_count || !newBadge.description}
            >
              <IconCheck size={18} />
            </ActionIcon>
          </div>
        </StyledInputWrapper>
      </TableRow>

    </TableContainer>
  );
};

export default BadgeTable;
