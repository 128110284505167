import { useMutation } from "@tanstack/react-query";
import { pinPost } from "../../services/apiPost";
import { usePost } from "./usePost";
import { useHighlight } from "../highlight/useHighlight";

export const usePinPost = ({community_id,highlightId,post_id,post_tab,label}) => {
    const {invalidatePostQueries,handleSuccess,handleError} = usePost();
    const {invalidateHighlightQueries} = useHighlight();

    return useMutation({
        mutationFn: ({community_id,post_id}) => pinPost({community_id,post_id}),
        onSuccess: () => {
            if(highlightId){
                invalidateHighlightQueries(community_id,highlightId,post_id,"allPost",post_tab,label);
            }else{
                invalidatePostQueries(community_id,post_id,"posts",null,null,post_tab,null);
            }
            handleSuccess('Post uğurla pinlendi');
        },
        onError: (error) => handleError(error),
    });

};
