import { useInfiniteQuery } from "@tanstack/react-query";
import { usePost } from "./usePost";
import { getAllPost } from "../../services/apiPost";

export const useGetAllPost = (communityId, type, postTab,label) => {
    const { queryKeys, handleError } = usePost();

    return useInfiniteQuery({
        queryKey: queryKeys.all(communityId,postTab,label),
        queryFn: ({ pageParam = 1 }) => getAllPost(communityId,postTab,label,pageParam),
        getNextPageParam: (lastPage) => {
            if (lastPage.next) {
                // URL-dən page parametrini çıxarırıq
                const nextPage = new URLSearchParams(new URL(lastPage.next).search).get('page');
                return nextPage;
            }
            return undefined;
        },
        onError: (error) => handleError(error),
        enabled: !!communityId && type === "community",
    });
};
