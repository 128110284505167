import { useQuery } from "@tanstack/react-query";
import { getAnalyticsData } from "../../services/apiAnalytics";

const formatDate = (date) => {
  if (!date) return null;
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

export const useAnalytics = (communityId, dateType, singleDate, dateRange) => {
  return useQuery({
    queryKey: ["analytics", communityId, dateType, singleDate, dateRange],
    queryFn: () => {
      if (dateType === "single") {
        return getAnalyticsData(communityId, { 
          to_date: formatDate(singleDate) 
        });
      } else {
        return getAnalyticsData(communityId, {
          to_date: formatDate(dateRange[1]),
          from_date: formatDate(dateRange[0])
        });
      }
    },
    enabled: Boolean(communityId && ((dateType === "single" && singleDate) || (dateType === "range" && dateRange[0] && dateRange[1])))
  });
};
