import { Skeleton } from "@mantine/core";
import { useTranslation } from "react-i18next";

const MembersSection = ({ isLoading, data }) => {
  const {t} = useTranslation()
  return (
  <div className="panel-section members">
    <h4 style={{textTransform: 'capitalize'}}>{t('members')}</h4>
    {isLoading ? (
      <Skeleton height={20} width={40} radius="md" />
    ) : (
      <p >{data?.members || data?.join_count}</p>
    )}
  </div>
);
};

export default MembersSection; 