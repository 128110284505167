import React from 'react';
import { Skeleton } from '@mantine/core';
import PostLayoutSkeleton from './PostLayoutSkeleton';

const QuizPostSkeleton = () => {
  return (
    <PostLayoutSkeleton>
      <div>
        {/* Question */}
        <Skeleton height={18} width="80%" mb={20} />
        
        {/* Options */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
          {[1, 2, 3, 4].map((_, index) => (
            <div 
              key={index}
              style={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 12 
              }}
            >
              <Skeleton circle height={20} width={20} />
              <Skeleton height={44} width="100%" radius={8} />
            </div>
          ))}
        </div>
      </div>
    </PostLayoutSkeleton>
  );
};

export default QuizPostSkeleton; 