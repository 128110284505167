import { useMutation } from "@tanstack/react-query";
import { useCommunity } from "./useCommunity";
import { updateCommunity } from "../../services/apiCommunity";
import { useNavigate } from "react-router-dom";

export const useUpdateCommunity = (communityId) => {
    const {invalidateCommunityQueries, handleError, handleSuccess } = useCommunity();
    const navigate = useNavigate();
    return useMutation({
        mutationFn: ({id, data}) => updateCommunity({id, data}),
        onSuccess: (data) => {
            invalidateCommunityQueries(communityId);
            handleSuccess('Community updated successfully');
            navigate(`/community/${communityId}`);
        },
        onError: (error) => handleError(error),
    })
}
