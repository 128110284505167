import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from 'react-i18next';

const EditableDiv = styled.div`
  border: none;
  padding: 10px;
  min-height: 50px;
  border-radius: 8px;
  outline: none;
  height: auto;
  overflow: hidden;
  word-wrap: break-word;
  resize: none;
  margin-top: 10px;

  &:empty:before {
    content: attr(data-placeholder);
    color: #aaa;
  }

  .hashtag {
    font-weight: bold;
    color: #0073e6;
  }

  @media (max-width: 768px) {
    min-height: 150px;
    font-size: 14px;
    padding: 10px 10px 10px 5px;
  }
`;

const HashtagHighlighter = ({ text = "", setText, placeholder = "Write something..." }) => {
  const { t } = useTranslation();
  const editableRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const lastCursorPosition = useRef(0);

  const saveSelection = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(editableRef.current);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      const position = preCaretRange.toString().length;
      setCursorPosition(position);
      lastCursorPosition.current = position;
    }
  };

  const restoreSelection = () => {
    const selection = window.getSelection();
    const range = document.createRange();
    let currentPos = 0;
    let done = false;

    function traverseNodes(node) {
      if (done) return;

      if (node.nodeType === Node.TEXT_NODE) {
        const nodeLength = node.length;
        if (currentPos + nodeLength >= lastCursorPosition.current) {
          range.setStart(node, lastCursorPosition.current - currentPos);
          range.collapse(true);
          done = true;
          return;
        }
        currentPos += nodeLength;
      } else {
        for (const childNode of node.childNodes) {
          traverseNodes(childNode);
        }
      }
    }

    if (editableRef.current) {
      traverseNodes(editableRef.current);
      if (!done) {
        const lastChild = editableRef.current.lastChild;
        if (lastChild) {
          range.setStartAfter(lastChild);
          range.collapse(true);
        }
      }
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  useEffect(() => {
    if (editableRef.current && text !== undefined) {
      const updatedContent = text.replace(
        /(#\w+)/g,
        '<span class="hashtag">$1</span>'
      );
      
      if (editableRef.current.innerHTML !== updatedContent) {
        editableRef.current.innerHTML = updatedContent;
        setTimeout(restoreSelection, 0);
      }
    }
  }, [text]);

  const handleInput = (event) => {
    saveSelection();
    if (setText) {
      setText(event.target.innerText);
    }
  };

  return (
    <EditableDiv
      ref={editableRef}
      contentEditable
      onInput={handleInput}
      onKeyUp={saveSelection}
      onMouseUp={saveSelection}
      suppressContentEditableWarning={true}
      data-placeholder={placeholder === "Write something..." ? t("write-something") : placeholder}
    />
  );
};

export default HashtagHighlighter;
