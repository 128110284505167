import { useQuery } from "@tanstack/react-query";
import { getCategoryCover } from "../../services/apiCategory";
import { useCategory } from "./useCategory";
export const useGetCategoryCover = (id) => {
    const {queryKeys,handleError} = useCategory();

    return useQuery({
        queryKey: queryKeys.cover(id),
        queryFn: () => getCategoryCover(id),
        onError: (error) => handleError(error),
    })
}