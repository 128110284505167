import { useMutation } from "@tanstack/react-query";
import { answeredToHighlightPost } from "../../services/apiHighlight";
import { usePost } from "../post/usePost";
import { useHighlight } from "./useHighlight";

export const useAnswerToHighlightPost = (communityId,highlight_id,type,postTab,label) => {
    const {handleError, invalidateHighlightQueries} = useHighlight();

    return useMutation({
        mutationFn: ({post_id,question_id,answer_id}) => answeredToHighlightPost({community_id:communityId,highlight_id,post_id,question_id,answer_id}),
        onSuccess: () => {
            invalidateHighlightQueries(communityId,highlight_id,null,"allPost",postTab,label);
        },
        onError: (error) => {
            handleError(error);
        },
    })
}
