import React from 'react';
import { Skeleton } from '@mantine/core';
import { PostLayoutStyled } from '../../style';

const PostLayoutSkeleton = ({ children }) => {
  return (
    <PostLayoutStyled>
      {/* Header */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
        <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
          {/* Avatar */}
          <Skeleton circle height={42} width={42} />
          <div>
            {/* Name */}
            <Skeleton height={16} width={120} mb={4} />
            {/* Date */}
            <Skeleton height={14} width={80} />
          </div>
        </div>
        {/* Menu icon */}
        <Skeleton circle height={28} width={28} />
      </div>

      {/* Post Content */}
      {children}

      {/* Footer */}
      <div style={{ marginTop: 11 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', gap: 15, alignItems: 'center' }}>
            <Skeleton height={20} width={60} />
            <Skeleton height={20} width={80} />
          </div>
          <Skeleton height={20} width={80} />
        </div>
      </div>
      <div className="line" />
      <div style={{ 
        display: 'flex', 
        justifyContent: 'space-around', 
        alignItems: 'center',
        padding: '10px 0'
      }}>
        <Skeleton height={24} width={70} />
        <Skeleton height={24} width={70} />
        <Skeleton height={24} width={70} />
      </div>
    </PostLayoutStyled>
  );
};

export default PostLayoutSkeleton; 