import React, { useState } from "react";
import { CommunityLayout } from "./CommunityPanel";
import {
  AdminAnalyticsCardStyled,
  AdminAnalyticsStyled,
} from "../../features/Community/style";
import {
  Text,
  Group,
  SegmentedControl,
  Stack,
  Image,
  Paper,
  Skeleton,
} from "@mantine/core";
import { DatePicker, DateRangePicker } from "@mantine/dates";
import {
  IconCalendarDue,
  IconTrendingDown,
  IconTrendingUp,
} from "@tabler/icons-react";
import analytics1 from "../../Assets/SVGs/community/analytics/count-of-post.svg";
import analytics2 from "../../Assets/SVGs/community/analytics/group-members.svg";
import { useAnalytics } from "../../features/Community/hooks/analytics/useAnalytics";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
const MostUsedLabels = ({ analyticsData ,xs, loading }) => {
  const { t } = useTranslation();
  if (loading) {
    return (
      <Stack spacing={10}>
        <Skeleton height={xs ? 18 : 20} width={150} />
        {[1, 2, 3].map((_, index) => (
          <Skeleton key={index} height={xs ? 41 : 49} radius="md" />
        ))}
      </Stack>
    );
  }

  return (
    <Stack spacing={10}>
      <Text color="#202020" size={xs ? 18 : 20} fw={500}>
        {t("most-used-labels")}
      </Text>
      {analyticsData?.most_labels?.map((item, index) => (
        <Group
          key={index}
          position="apart"
          p={xs ? 10 : 12}
          style={{
            backgroundColor: "#F8F8F8",
            borderRadius: "8px",
          }}
        >
          <Group align="center" >
          <div
                  style={{
                    width: xs ? 21 : 25,
                    height: xs ? 21 : 25,
                    marginBottom: 1,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: item.svg_icon?.replace(
                      "<svg",
                      `<svg width="${xs ? 21 : 30}" height="${xs ? 21 : 25}"`
                    ),
                  }}
                />
            <Text size={xs ? 14 : 16} color="#202020">{item.name}</Text>
          </Group>
          <Text size={xs ? 14 : 16} color="#606060">{item.label_count}</Text>
        </Group>
      ))}
    </Stack>
  );
};

const CommunityHighlight = ({ analyticsData,xs, loading }) => {
  const { t } = useTranslation();
  if (loading) {
    return (
      <Stack spacing={20}>
        <Skeleton height={xs ? 18 : 20} width={200} />
        {[1, 2].map((_, index) => (
          <Skeleton key={index} height={xs ? 95 : 112} radius="md" />
        ))}
      </Stack>
    );
  }

  if (!analyticsData?.most_highlights?.length) {
    return null;
  }

  const highlights = analyticsData?.most_highlights?.map(highlight => ({
    title: highlight.name,
    description: highlight.description,
    posts: `${highlight.posts_count} ${t("post-count")}`,
    members: `${highlight.join_count} ${t("member-count")}`,
    image: `https://taskilled.blob.core.windows.net/taskool-store/${highlight.image}`,
    author: highlight.author,
    badge: highlight.badge
  })) || [];

  return (
    <Stack spacing={20}>
      <Text color="#202020" size={xs ? 18 : 20} fw={500}>
        {t("most-interested-community-highlight")}
      </Text>

      {highlights.map((highlight, index) => (
        <Paper
          key={index}
          p={xs ? 14 : 16}
          style={{
            border: "1px dashed #E7E7E7",
            borderRadius: "8px",
          }}
        >
          <Group position="apart" align="flex-start">
            <Image
              src={highlight.image}
              width={xs ? 100 : 120}
              height={xs ? 65 : 80}
              radius="md"
              style={{ objectFit: "cover" }}
            />
            <div style={{ flex: 1, marginLeft:xs ? 10 :  20 }}>
              <Text size={xs ?  17 : 18} fw={500} color="#202020">
                {highlight.title}
              </Text>
              <Text size={xs ? 14 :17} color="#606060" mt={xs ? 2 : 4}>
                {highlight.description}
              </Text>
              <Group spacing={xs ? 10 : 20} mt={ xs ? 4 :8}>
                <Text size={xs ? 12 : 14} color="#606060">{highlight.posts}</Text>
                <Text size={xs ? 12 : 14} color="#606060">{highlight.members}</Text>
              </Group>
            </div>
          </Group>
        </Paper>
      ))}
    </Stack>
  );
};

const AdminAnalytics = () => {
  const { t } = useTranslation();
  const [dateType, setDateType] = useState("single");
  const [singleDate, setSingleDate] = useState(new Date());
  const [dateRange, setDateRange] = useState([null, null]);
  const { id } = useParams();
  
  const { data: analyticsData, isLoading } = useAnalytics(id, dateType, singleDate, dateRange);

  const commonStyles = {
    input: {
      backgroundColor: "transparent",
      border: "none",
      color: "#029199",
      cursor: "pointer",
      "&::placeholder": {
        color: "#029199",
      },
    },
  };

  const xs = useMediaQuery('(max-width : 768px)')

  return (
    <CommunityLayout>
      <AdminAnalyticsStyled>
        <Text
          mb={10}
          pb={10}
          style={{ borderBottom: "1px solid #E7E7E7" }}
          color="#202020"
          size={ xs ? 18 :20}
          fw={500}
        >
          {t("admin-analytics")}
        </Text>

        <Group>
          <SegmentedControl
            value={dateType}
            onChange={setDateType}
            data={[
              { label: t("single-day"), value: "single" },
              { label: t("date-range"), value: "range" },
            ]}
            color="teal"
          />

          {dateType === "single" ? (
            <DatePicker
              value={singleDate}
              onChange={setSingleDate}
              placeholder={t("select-date")}
              clearable
              icon={<IconCalendarDue color="#029199" size={xs ? 18 : 20} />}
              styles={commonStyles}
              format="DD-MM-YYYY"
            />
          ) : (
            <DateRangePicker
              value={dateRange}
              onChange={setDateRange}
              placeholder={t("select-date-range")}
              clearable
              icon={<IconCalendarDue color="#029199" size={xs ? 18 : 20} />}
              styles={commonStyles}
              format="DD-MM-YYYY"
            />
          )}
        </Group>
        <Group mt={20} spacing={20}>
          <AdminAnalyticsCard img={analytics1} data={analyticsData} xs={xs} loading={isLoading} />
          <AdminAnalyticsCard img={analytics2} data={analyticsData} xs={xs} loading={isLoading} />
        </Group>

        <div style={{ marginTop: "30px" }}>
          <MostUsedLabels analyticsData={analyticsData} xs={xs} loading={isLoading} />
        </div>

        <div style={{ marginTop: "30px" }}>
          <CommunityHighlight analyticsData={analyticsData} xs={xs} loading={isLoading} />
        </div>
      </AdminAnalyticsStyled>
    </CommunityLayout>
  );
};

export default AdminAnalytics;

export const AdminAnalyticsCard = ({ img, data, loading, xs }) => {
  const { t } = useTranslation();
  if (loading) {
    return (
      <AdminAnalyticsCardStyled>
        <Group align="flex-start" position="apart">
          <Skeleton height={xs ? 15 : 16} width={120} />
          <Skeleton height={xs ? 35 : 49} width={xs ? 35 : 49} radius="md" />
        </Group>
        <Skeleton height={xs ? 20 : 24} width={80} mt={20} />
        <Skeleton height={xs ? 18 : 20} width={150} mt={10} />
      </AdminAnalyticsCardStyled>
    );
  }

  const isPostCount = img === analytics1;
  const value = isPostCount ? data?.posts_count : data?.members;
  const count = isPostCount ? value?.until_the_selected_to_date : value?.until_the_selected_to_date;

  const percentage = isPostCount ? value?.range_difference : value?.range_difference;
  const up = percentage ? percentage?.slice(0,1) === "+" : false;

  return (
    <AdminAnalyticsCardStyled>
      <Group align="flex-start" position="apart">
        <Text mt={4} color="#202224" size={xs ? 15 : 16} weight={500}>
          {isPostCount ? t("count-of-posts") : t("group-members")}
        </Text>
        <img style={{
          width: xs ? 35 :  49,
          height: xs ? 35 : 49,
        }} src={img} alt="analytics" />
      </Group>

      <Text size={xs ? 20 : 24} fw={500} color="#202224">
        {count || 0}
      </Text>

      <Group mt={4} spacing={6} align="center">
        {up ? (
          <IconTrendingUp color="#00B69B" size={xs ? 18 : 20} />
        ) : (
          <IconTrendingDown color="#FF5A5A" size={xs ? 18 : 20} />
        )}
        <Text size={xs ? 13 : 14} fw={500} color={up ? "#00B69B" : "#FF5A5A"}>
          {Math.abs(percentage)}%
        </Text>
        <Text size={xs ? 13 : 14} fw={500} color="#606060">
          {t("up-from-month")}
        </Text>
      </Group>
    </AdminAnalyticsCardStyled>
  );
};
