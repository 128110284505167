import { useState, useEffect } from 'react';
import axios from '../../../../Helpers/Axios';

export const useVimeoVideos = (videos) => {
  const [videoThumbnails, setVideoThumbnails] = useState({});
  const [embedUrls, setEmbedUrls] = useState({});
  const [selectedVideo, setSelectedVideo] = useState(null);

  // Vimeo video ID-sini URL-dən çıxarmaq
  const extractVimeoId = (url) => {
    const match = url.match(/(?:vimeo\.com\/|video\/)(\d+)/);
    return match ? match[1] : null;
  };

  // Vimeo API-dən thumbnail almaq
  const fetchVimeoThumbnail = async (videoId) => {
    const accessTokenX = "38c6f5ca7dab2349d12ad6d5e716c759";

    try {
      const apiUrl = `https://api.vimeo.com/videos/${videoId}`;
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${accessTokenX}`,
        },
      });
      return response.data.pictures.sizes[0]?.link || '/path/to/placeholder.jpg';
    } catch (error) {
      if (error.response?.status === 404) {
        console.error(`Video mövcud deyil və ya gizlidir: ${videoId}`);
      } else {
        console.error('API çağırışında problem:', error);
      }
      return '/path/to/placeholder.jpg';
    }
  };

  const getEmbedUrl = (videoUrl) => {
    const videoId = extractVimeoId(videoUrl);
    if (!videoId) return null;
    return `https://player.vimeo.com/video/${videoId}`;
  };

  // Video thumbnail-ları əldə etmək
  useEffect(() => {
    const fetchThumbnails = async () => {
      if (!videos?.length) return;

      try {
        const thumbnailMap = {};
        for (const video of videos) {
          const videoId = extractVimeoId(video.video_url);
          if (videoId) {
            const thumbnailUrl = await fetchVimeoThumbnail(videoId);
            thumbnailMap[video.video_url] = thumbnailUrl || null;
          }
        }
        setVideoThumbnails(thumbnailMap);
      } catch (error) {
        console.error('Error fetching thumbnails:', error);
      }
    };

    fetchThumbnails();
  }, [videos]);

  // Embed URL-ləri əldə etmək
  useEffect(() => {
    const fetchEmbedUrls = async () => {
      if (!videos?.length) return;

      try {
        const urlMap = {};
        for (const video of videos) {
          const videoId = extractVimeoId(video.video_url);
          // CORS-sız endpoint istifadə edirik
          urlMap[video.video_url] = `https://player.vimeo.com/video/${videoId}`;
        }
        setEmbedUrls(urlMap);
      } catch (error) {
        console.error('Error fetching embed URLs:', error);
      }
    };

    fetchEmbedUrls();
  }, [videos]);

  return {
    videoThumbnails,
    embedUrls,
    selectedVideo,
    setSelectedVideo,
    getEmbedUrl,
    extractVimeoId
  };
};
