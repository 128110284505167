import { useMutation } from "@tanstack/react-query";
import { usePost } from "./usePost";
import { unpinPost } from "../../services/apiPost";

export const useUnpinPost = ({community_id,highlightId,post_id,post_tab,label}) => {
    const {invalidatePostQueries,handleSuccess,handleError} = usePost();

    return useMutation({
        mutationFn: ({community_id,post_id}) => unpinPost({community_id,post_id}),
        onSuccess: () => {
            invalidatePostQueries(community_id,post_id,"posts",null,null,post_tab,null);
            handleSuccess('Post uğurla unpinlendi');
        },
        onError: (error) => handleError(error),
    });
};