import { Button, Group, Flex } from '@mantine/core';
import CategoryTag from '../../features/Community/components/category/CategoryTag';
import { mockCategories } from '../../features/Community/mockData';
import { useEffect, useState } from 'react';
import { useCommunityContext } from '../../features/Community/context/CommunityContext';
import { Link, useNavigate } from 'react-router-dom';
import { useGetAllCategories } from '../../features/Community/hooks/category/useGetAllCategories';
import CategoryTagSkeleton from '../../features/Community/components/skeletons/CategoryTagSkeleton';
import { useMediaQuery } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

const CategoryList = ({type}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { updateCommunityData } = useCommunityContext();
  const {data : categories , isLoading} = useGetAllCategories();
  const localCommunityData = JSON.parse(localStorage.getItem('communityData'));
  const navigate = useNavigate();

  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId);
    updateCommunityData('category', categoryId);
    localCommunityData.category = categoryId;
    localStorage.setItem('communityData', JSON.stringify(localCommunityData));
  };

  const handleNext = () => {
    if (type === "create") {
      navigate(`/categories/${selectedCategory}/cover-photo`)
    } else {
      navigate(`/categories/${selectedCategory}/cover-photo/edit`)
    }
  }

  useEffect(() => {
    if (localCommunityData?.category) {
      setSelectedCategory(localCommunityData?.category);
    }
  }, [localCommunityData]);

  const xs = useMediaQuery('(max-width: 768px)');
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div style={{maxWidth: "70%",margin: "0 auto 50px auto", background: "#fff", padding: '40px',borderRadius: "11px" }}>
        <Flex 
          gap={30} 
          justify="center" 
          align="center" 
          wrap="wrap"
          style={{ maxWidth: "84%", margin: '0 auto 50px auto' }}
        >
          {Array.from({ length: 15 }, (_, index) => (
            <CategoryTagSkeleton key={index} />
          ))}
        </Flex>
        
        <Group position="right" mt={50}>
          <Button 
            variant="outline" 
            color="dark" 
            radius="md"
            style={{ padding: '8px 32px' }}
            disabled
            component={Link}
            to='/communities'
          >
            {t("back")}
          </Button>
          <Button 
            color="teal" 
            radius="md"
            disabled
            style={{ 
              padding: '8px 32px',
              backgroundColor: '#029199'
            }}
          >
            {t("next")}
          </Button>
        </Group>
      </div>
    );
  }

  return (
    <div style={{maxWidth: "70%",margin: "0 auto 50px auto", background: "#fff", padding: '40px',borderRadius: "11px" }}>
      <Flex 
        gap={30} 
        justify="center" 
        align="center" 
        wrap="wrap"
        style={{ maxWidth: "84%", margin: '0 auto' }}
      >
        {categories?.map((category) => (
          <CategoryTag 
            key={category.id} 
            category={category}
            isSelected={selectedCategory === category.id}
            onClick={() => handleCategoryClick(category.id)}
          />
        ))}
      </Flex>
      
      <Group position={xs ? "center" : "right"} mt={50}>
        <Button 
          variant="outline" 
          color="dark" 
          radius="md"
          style={{ padding: '8px 32px' }}
          component={Link}
          to='/communities'
          styles={(theme) => ({
            root: {
              border: '1px solid #CDD5DF',
              color: '#5B5B5B',
              fontSize: '16px',
              fontWeight: 500,
              padding: '8px 32px',
              '@media (max-width: 768px)': {
                fontSize: '14px',
                padding: '8px 24px',
              }
            }
          })}
        >
          {t("back")}
        </Button>
        <Button 
          color="teal" 
          radius="md"
          disabled={!selectedCategory}
          onClick={handleNext}
          styles={(theme) => ({
            root: {
              fontSize: '15px',
              fontWeight: 500,
              padding: '8px 32px',
              backgroundColor: '#029199',
              '@media (max-width: 768px)': {
                fontSize: '14px',
                padding: '8px 24px',
              }
            }
          })}
        >
          {t("next")}
        </Button>
      </Group>
    </div>
  );
};

export default CategoryList;