import { useMutation } from "@tanstack/react-query";
import { deletePostImage } from "../../services/apiPost";
import { usePost } from "./usePost";


export const useDeletePostImg = (community_id,post_id) => {
    const {handleError, handleSuccess, invalidatePostQueries} = usePost();

    return useMutation({
        mutationFn: ({community_id,post_id,image_id}) => deletePostImage({community_id,post_id,image_id}),
        onSuccess: () => {
            invalidatePostQueries(community_id,post_id);
            handleSuccess("Şəkil uğurla silindi");
        },
        onError: (error) => handleError(error),
    })
}
