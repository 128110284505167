import { useMutation } from "@tanstack/react-query";
import { savePost } from "../../services/apiPost";
import { usePost } from "./usePost";
import { useHighlight } from "../highlight/useHighlight";


export const useSavePost = (community_id,highlightId,postTab,label,type) => {
    const {queryKeys,invalidatePostQueries,handleSuccess} = usePost();
    const {invalidateHighlightQueries,} = useHighlight();
    return useMutation({
        mutationFn: ({community_id,post_id}) => savePost({community_id,post_id}),
        onSuccess: () => {
            handleSuccess('Post uğurla saxlandi');
            if(type === "community"){
                invalidatePostQueries(community_id,null,null,null,null,postTab,label);
            } else if(type === "highlight"){
                invalidateHighlightQueries(community_id,highlightId,null,"allPost",postTab,label);
            }
        },
        onError: (error) => {
        }
    })
}