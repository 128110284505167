import React, { useState, useEffect, useContext, useRef } from "react";
import { Avatar, Group, Text, Stack, Button } from "@mantine/core";
import styled from "styled-components";
import { useCreateComment } from "../../hooks/post/comment/useCreateComment";
import { useGetComment } from "../../hooks/post/comment/useGetComment";
import { useReplyComment } from "../../hooks/post/comment/useReplyComment";
import {
  useGetCurrentCommentReplies,
  useGetOtherRepliesComments,
  useGetReplyComments,
} from "../../hooks/post/comment/useGetReplyComments";
import { CornerUpRight, Message, MoodSmile } from "tabler-icons-react";
import {
  IconFileSmile,
  IconMoodSmile,
  IconPhoto,
  IconSend2,
  IconThumbUp,
} from "@tabler/icons-react";
import { AppContext } from "../../../../Helpers/Context";
import { formatDistanceToNow, formatDistance } from "date-fns";
import { az } from "date-fns/locale";
import { useReactToComment } from "../../hooks/post/reaction/comment/useReactToComment";
import { useUnreactToComment } from "../../hooks/post/reaction/comment/useUnreactToComment";
import { useGetCommentReaction } from "../../hooks/post/reaction/comment/useGetCommentReaction";
import { useGetAllCommentReactions } from "../../hooks/post/reaction/comment/useGetAllCommentReactions";
import like from "../../../../Assets/SVGs/community/post/reactions/like.svg";
import CustomEmojiPicker from '../../components/EmojiPicker';
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

const formatTimeAgo = (dateString) => {
  // Tarixi UTC formatından lokal vaxta çeviririk
  const date = new Date(dateString.replace(" ", "T") + "Z");
  const now = new Date();

  const diffInSeconds = Math.floor((now - date) / 1000);

  // Müxtəlif vaxt intervalları üçün yoxlamalar
  if (diffInSeconds < 60) {
    return "İndicə";
  } else if (diffInSeconds < 3600) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} dəq`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} saat`;
  } else if (diffInSeconds < 2592000) {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} gün`;
  } else if (diffInSeconds < 31536000) {
    const months = Math.floor(diffInSeconds / 2592000);
    return `${months} ay`;
  } else {
    const years = Math.floor(diffInSeconds / 31536000);
    return `${years} il`;
  }
};

// Comment Item Component
const CommentItem = ({
  comment,
  onReply,
  type,
  onComment,
  setCommentId,
  handleReactToComment,
  likeCount,
  isLiked,
  community_id,
  post_id,
  repliesCount,
  setReplyToUser,
}) => {
  const xs = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation();
  return (
    <CommentItemStyled type={type}>
      <Stack spacing={xs ? 3 : 4}>
        <Group align="center" spacing={0}>
          <div
            style={{
              width: xs ? 25 : 30,
              height: xs ? 25 : 30,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={comment.author.profile_img}
              alt="user"
              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
            />
          </div>
          <Group ml={xs ? 6 : 8} align="center" spacing={xs ? 6 : 8}>
            <Text color="#000" size={xs ? 12 : 16} weight={500}>
              {comment.author.first_name} {comment.author.last_name}
            </Text>
            <Text
              color="#B4BBC6"
              mt={xs ? 1 : 2}
              size={xs ? 10 : 12}
              weight={400}
            >
              {formatTimeAgo(comment.created_at)}
            </Text>
          </Group>
        </Group>
        <Group mt={3} spacing={8} align="center">
          {type === "reply" && (
            <Text size={xs ? 13 : 15} color="#029199" weight={500}>
              {comment?.tagged_user?.first_name}{" "}
              {comment?.tagged_user?.last_name}
            </Text>
          )}
          <Text
            mb={1}
            style={{ lineHeight: "25px" }}
            size={xs ? 13 : 15}
            weight={400}
            color="#272727"
          >
            {comment.comment}
          </Text>
        </Group>

        <Group spacing={xs ? 10 : 13} mt={xs ? 3 : 4}>
          <ActionButton
            onClick={() => {
              handleReactToComment({
                community_id: community_id,
                post_id: post_id,
                comment_id: comment.id,
                data: { reaction: 1 },
              });
            }}
          >
            <Text weight={500} span color={isLiked ? "#029199" : "#8991A0"}>
              {isLiked ? t("liked") : t("like")}
            </Text>
            {isLiked ? (
              <img
                style={{
                  marginLeft: 2,
                  marginBottom: 1.5,
                  width: xs ? 10 : 13,
                  height: xs ? 10 : 13,
                }}
                src={like}
                alt="like"
              />
            ) : (
              <IconThumbUp
                style={{ marginLeft: 5, marginBottom: 1 }}
                size={xs ? 12 : 16}
                color="#666"
              />
            )}
            <Text span color="#8991A0">
              {likeCount}
            </Text>
          </ActionButton>
          {type !== "reply" && (
            <ActionButton
              onClick={() => {
                onComment();
              }}
            >
              <Text span color="gray.6">
                {t("comments")}
              </Text>
              <Message
                style={{ marginLeft: 5 }}
                size={xs ? 12 : 16}
                color="#666"
              />
              <Text span color="gray.6">
                {repliesCount}
              </Text>
            </ActionButton>
          )}
          <ActionButton onClick={onReply}>
            <Group spacing={4}>
              <CornerUpRight size={xs ? 12 : 18} color="#8991A0" />
              <Text span color="#8991A0">
                {t("reply")}
              </Text>
            </Group>
          </ActionButton>
        </Group>
      </Stack>
    </CommentItemStyled>
  );
};

const CommentItemSkeleton = ({ type }) => (
  <CommentItemStyled type={type}>
    <Stack spacing={4}>
      {/* User info section */}
      <Group align="center" spacing={0}>
        {/* Avatar skeleton */}
        <div
          style={{
            width: 30,
            height: 30,
            borderRadius: "50%",
            background: "#e0e0e0",
            animation: "pulse 1.5s infinite",
          }}
        />
        {/* Name and time skeletons */}
        <Group ml={8} align="center" spacing={8}>
          <div
            style={{
              width: 120,
              height: 16,
              borderRadius: 4,
              background: "#e0e0e0",
              animation: "pulse 1.5s infinite",
            }}
          />
          <div
            style={{
              width: 50,
              height: 12,
              borderRadius: 4,
              background: "#e0e0e0",
              animation: "pulse 1.5s infinite",
            }}
          />
        </Group>
      </Group>

      {/* Comment text skeleton */}
      <Group mt={3} spacing={8} align="center">
        {type === "reply" && (
          <div
            style={{
              width: 80,
              height: 15,
              borderRadius: 4,
              background: "#e0e0e0",
              animation: "pulse 1.5s infinite",
            }}
          />
        )}
        <div
          style={{
            width: "100%",
            maxWidth: 800,
            height: 15,
            borderRadius: 4,
            background: "#e0e0e0",
            animation: "pulse 1.5s infinite",
          }}
        />
        <div
          style={{
            width: "100%",
            maxWidth: 600,
            height: 15,
            borderRadius: 4,
            background: "#e0e0e0",
            animation: "pulse 1.5s infinite",
          }}
        />
      </Group>

      {/* Action buttons skeleton */}
      <Group spacing={13} mt={4}>
        {/* Like button skeleton */}
        <div
          style={{
            width: 60,
            height: 16,
            borderRadius: 4,
            background: "#e0e0e0",
            animation: "pulse 1.5s infinite",
          }}
        />
        {/* Comments button skeleton (only for non-reply comments) */}
        {type !== "reply" && (
          <div
            style={{
              width: 80,
              height: 16,
              borderRadius: 4,
              background: "#e0e0e0",
              animation: "pulse 1.5s infinite",
            }}
          />
        )}
        {/* Reply button skeleton */}
        <div
          style={{
            width: 50,
            height: 16,
            borderRadius: 4,
            background: "#e0e0e0",
            animation: "pulse 1.5s infinite",
          }}
        />
      </Group>
    </Stack>
  </CommentItemStyled>
);

const CommentInputWrapper = ({ 
  user, 
  inputValue, 
  setInputValue, 
  onSubmit, 
  replyToUser = null,
  xs 
}) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const { t } = useTranslation();
  const onEmojiClick = (emojiObject) => {
    setInputValue((prev) => prev + emojiObject.emoji);
    setShowEmojiPicker(false);
  };

  return (
    <CommentInputWrapperStyled>
      <div
        style={{
          width: xs ? 25 : 30,
          height: xs ? 25 : 30,
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={user.profile_img}
          alt="user"
          style={{ width: "100%", height: "100%", borderRadius: "50%" }}
        />
      </div>
      <StyledTextInput>
        {replyToUser && (
          <span className="reply-to">
            {replyToUser.first_name} {replyToUser.last_name}
          </span>
        )}
        <input
          placeholder={t("add-comment")}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && inputValue.trim()) {
              onSubmit();
            }
          }}
        />
        <div className="input-actions">
          <CustomEmojiPicker
            onEmojiClick={onEmojiClick}
            opened={showEmojiPicker}
            setOpened={setShowEmojiPicker}
          />
          {inputValue && (
            <ActionButton onClick={onSubmit}>
              <IconSend2 size={xs ? 16 : 20} stroke={2} color="#393838" />
            </ActionButton>
          )}
        </div>
      </StyledTextInput>
    </CommentInputWrapperStyled>
  );
};

// Comment Section Component
const CommentSection = ({
  setCommentCount,
  showCommentSection,
  community_id,
  post_id,
  type,
  setShowShareDropdown,
}) => {
  const { t } = useTranslation();
  const [newComment, setNewComment] = useState("");
  const [replyingToComment, setReplyingToComment] = useState("");
  const [replyingTo, setReplyingTo] = useState(null);
  const [replyToUser, setReplyToUser] = useState(null);
  const [replyingToReply, setReplyingToReply] = useState(null);
  const [commentPage, setCommentPage] = useState(1);
  const [replyPage, setReplyPage] = useState(1);
  const [comments, setComments] = useState([]);
  const [replies, setReplies] = useState([]);
  const [defaultShowComment, setDefaultShowComment] = useState(4);
  const [defaultReplyShowComment, setDefaultReplyShowComment] = useState(2);
  const addCount = 2;
  const { user } = useContext(AppContext);
  const [sectionType, setSectionType] = useState("");
  const [allRepliesIds, setAllRepliesIDs] = useState([]);
  const [allCommentIds, setAllCommentIds] = useState([]);
  const [commentId, setCommentId] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const commentInputRef = useRef(null);
  const xs = useMediaQuery("(max-width: 768px)");

  const { mutate: createComment } = useCreateComment(
    community_id,
    post_id,
    commentPage
  );

  const { mutate: createReplyComment } = useReplyComment(
    community_id,
    post_id,
    replyingTo,
    replyPage,
    replyingToReply
  );

  const { data: commentsList, isLoading: commentsLoading } = useGetComment(
    community_id,
    post_id,
    commentPage,
    showCommentSection
  );

  const { data: repliesList, isLoading: repliesLoading } = useGetReplyComments(
    community_id,
    post_id,
    replyingTo,
    replyPage
  );

  const { mutate: reactToComment } = useReactToComment();

  const { mutate: unreactToComment } = useUnreactToComment();

  const commentReplies = useGetCurrentCommentReplies(
    community_id,
    post_id,
    replyPage,
    allCommentIds
  );

  const commentReaction = useGetCommentReaction(
    community_id,
    post_id,
    allCommentIds
  );

  const replyToCommentReaction = useGetCommentReaction(
    community_id,
    post_id,
    allRepliesIds
  );

  const allCommentReactions = useGetAllCommentReactions(
    community_id,
    post_id,
    allCommentIds
  );

  const replyToCommentReactions = useGetAllCommentReactions(
    community_id,
    post_id,
    allRepliesIds
  );

  const commentReactionData = commentReaction.map((query) => query.data);

  const allCommentReactionsData = allCommentReactions?.map(
    (query) => query.data
  );
  const isAllCommentReactionsLoading = allCommentReactions.some(
    (query) => query.isLoading
  );

  const replyToCommentReactionsData = replyToCommentReactions.map(
    (query) => query.data
  );
  const isAllReplyToCommentReactionsLoading = replyToCommentReactions.some(
    (query) => query.isLoading
  );

  const replyToCommentReactionData = replyToCommentReaction.map(
    (query) => query.data
  );
  const isAllReplyToCommentReactionDataLoading = replyToCommentReactions.some(
    (query) => query.isLoading
  );
  const commentRepliesData = commentReplies.map((query) => query.data);
  const isAllCommentRepliesLoading = commentReplies.some(
    (query) => query.isLoading
  );

  useEffect(() => {
    if (commentsList?.results) {
      if (commentPage === 1) {
        setComments(commentsList.results);
      } else {
        setComments((prev) => {
          const existingIds = new Set(prev.map((comment) => comment.id));
          const newComments = commentsList.results.filter(
            (comment) => !existingIds.has(comment.id)
          );
          return [...prev, ...newComments];
        });
      }
    }
  }, [commentsList, commentPage]);

  useEffect(() => {
    if (repliesList?.results && (replyingToReply || replyingTo)) {
      if (replyPage === 1) {
        setReplies(repliesList.results);
      } else {
        setReplies((prev) => {
          const existingIds = new Set(prev.map((reply) => reply.id));
          const newReplies = repliesList.results.filter(
            (reply) => !existingIds.has(reply.id)
          );
          return [...prev, ...newReplies];
        });
      }
    }
  }, [repliesList, replyingToReply, replyingTo, replyPage]);

  const hasMoreComments = comments.length > defaultShowComment;
  const hasNextComment = commentsList?.next !== null;
  const hasMoreReplies = replies.length > defaultReplyShowComment;
  const hasNextReply = repliesList?.next !== null;

  const handleAddComment = () => {
    createComment({ data: { comment: newComment } });
    setNewComment("");
    setReplyToUser(null);
  };

  const handleAddReplyComment = () => {
    createReplyComment({ data: { comment: replyingToComment } });
    setReplyingToComment("");
    setReplyToUser(null);
  };

  const handleShowMoreComments = () => {
    if (comments.length >= defaultShowComment) {
      setDefaultShowComment((prev) => prev + addCount);
    }
  };

  const handleShowMoreReplies = () => {
    if (replies.length >= defaultReplyShowComment) {
      setDefaultReplyShowComment((prev) => prev + addCount);
    }
  };

  useEffect(() => {
    if (
      hasNextReply &&
      replies?.length > 0 &&
      defaultReplyShowComment % 10 === 0
    ) {
      setReplyPage((prev) => prev + 1);
    }
  }, [hasNextReply, replies, defaultReplyShowComment]);

  useEffect(() => {
    if (
      hasNextComment &&
      comments.length > 0 &&
      defaultShowComment % 10 === 0
    ) {
      setCommentPage((prev) => prev + 1);
    }
  }, [hasNextComment, comments, defaultShowComment]);

  useEffect(() => {
    if (comments) {
      setAllCommentIds(comments.map((comment) => comment.id));
    }
  }, [comments]);

  useEffect(() => {
    if (replies) {
      setAllRepliesIDs(replies.map((reply) => reply.id));
    }
  }, [replies]);

  const handleCommentClick = (commentId) => {
    setReplyingTo(commentId);
    setSectionType("comment");
    setDefaultReplyShowComment(4);

    setTimeout(() => {
      if (commentInputRef.current) {
        const element = commentInputRef.current;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - 200; // 100px yuxarı

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }, 100);
  };

  return (
    <CommentSectionStyled type={type}>
      {type !== "media" && (
        <CommentInputWrapper
          user={user}
          inputValue={newComment}
          setInputValue={setNewComment}
          onSubmit={handleAddComment}
          xs={xs}
        />
      )}
      <Stack spacing={xs ? 12 : 16}>
        {isAllCommentRepliesLoading || isAllCommentReactionsLoading
          ? // Show skeletons while loading
            Array(3)
              .fill(0)
              .map((_, index) => <CommentItemSkeleton key={index} />)
          : // Show actual comments when data is available
            comments.slice(0, defaultShowComment).map((comment, index) => (
              <div key={comment.id}>
                <CommentItem
                  comment={comment}
                  repliesCount={commentRepliesData[index]?.count}
                  community_id={community_id}
                  post_id={post_id}
                  handleReactToComment={
                    commentReactionData[index]?.id
                      ? unreactToComment
                      : reactToComment
                  }
                  likeCount={allCommentReactionsData[index]?.count}
                  isLiked={commentReactionData[index]?.id}
                  allCommentReactions={allCommentReactionsData}
                  setReplyToUser={setReplyToUser}
                  onComment={() => handleCommentClick(comment.id)}
                  onReply={() => {
                    setReplyingToReply(comment.id);
                    setReplyingTo(comment.id);
                    setSectionType("reply");
                    setReplyToUser(comment?.author);
                  }}
                />

                <div
                  ref={replyingTo === comment.id ? commentInputRef : null}
                  style={{ marginTop: xs ? 8 : 10 }}
                >
                  {replyingTo === comment.id && replies.length > 0 && (
                    <Stack
                      spacing={xs ? 12 : 18}
                      ml={xs ? 25 : 33}
                      mb={xs ? 0.5 : 10}
                      mt={xs ? 0.5 : 1}
                    >
                      {isAllReplyToCommentReactionsLoading ||
                      isAllReplyToCommentReactionDataLoading ? (
                        // Show skeletons while loading
                        Array(3)
                          .fill(0)
                          .map((_, index) => (
                            <CommentItemSkeleton key={index} type="reply" />
                          ))
                      ) : (
                        <>
                          {replies
                            .slice(0, defaultReplyShowComment)
                            .map((reply, index) => (
                              <CommentItem
                                key={reply.id}
                                comment={reply}
                                type="reply"
                                community_id={community_id}
                                post_id={post_id}
                                handleReactToComment={
                                  replyToCommentReactionData[index]?.id
                                    ? unreactToComment
                                    : reactToComment
                                }
                                likeCount={
                                  replyToCommentReactionsData[index]?.count
                                }
                                isLiked={replyToCommentReactionData[index]?.id}
                                onReply={() => {
                                  setSectionType("reply");
                                  setReplyingToReply(reply.id);
                                  setReplyToUser(reply?.author);
                                }}
                              />
                            ))}
                          {hasMoreReplies && (
                            <Group noWrap align="center" spacing={6}>
                              <div
                                style={{
                                  width: xs ? "15%" : "20%",
                                  height: "1px",
                                  background: "#8991A0",
                                }}
                              ></div>
                              <ShowMoreButton
                                style={{ marginBottom: xs ? 5 : 7 }}
                                onClick={handleShowMoreReplies}
                              >
                                {t("show-comment-section")}
                              </ShowMoreButton>
                            </Group>
                          )}
                        </>
                      )}
                    </Stack>
                  )}
                  {(replyingTo === comment.id ||
                    replyingToReply === comment.id) &&
                    sectionType === "reply" && (
                      <CommentInputWrapper
                        user={user}
                        inputValue={replyingToComment}
                        setInputValue={setReplyingToComment}
                        onSubmit={handleAddReplyComment}
                        replyToUser={replyToUser}
                        xs={xs}
                      />
                    )}
                </div>
              </div>
            ))}
        {hasMoreComments && (
          <Group noWrap align="center" spacing={6}>
            <div
              style={{
                width: xs ? "15%" : "20%",
                height: "1px",
                background: "#8991A0",
              }}
            ></div>
            <ShowMoreButton
              style={{ marginBottom: xs ? 5 : 7 }}
              onClick={handleShowMoreComments}
            >
              {t("show-comment-section")}
            </ShowMoreButton>
          </Group>
        )}
      </Stack>
      {type === "media" && (
        <CommentInputWrapper
          user={user}
          inputValue={newComment}
          setInputValue={setNewComment}
          onSubmit={handleAddComment}
          xs={xs}
        />
      )}
    </CommentSectionStyled>
  );
};

// Styled Components
const CommentSectionStyled = styled.div`
  margin-top: 16px;
  height: ${(props) => (props.type === "media" ? "100%" : "auto")};
  display: ${(props) => (props.type === "media" ? "flex" : "block")};
  flex-direction: ${(props) => (props.type === "media" ? "column" : "row")};
  justify-content: ${(props) =>
    props.type === "media" ? "space-between" : "flex-start"};
  margin-bottom: ${(props) => (props.type === "media" ? "10px" : "0px")};

  ${(props) =>
    props.type === "media" &&
    `
    & > div:first-child {
      flex: 1;
      min-height: 250px;
      max-height: 460px;
      overflow-y: auto;
      padding-right: 10px;

      @media(max-width:768px) {
      max-height:300px;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  `}
`;

const CommentItemStyled = styled.div`
  padding: 4px 0;
  border-left: ${(props) =>
    props.type === "reply" ? "3px dashed #E7E7ED" : "none"};
  padding-left: ${(props) => (props.type === "reply" ? "10px" : "0px")};
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  color: #666;
  font-size: 14px;

  &:hover {
    text-decoration: underline;
  }
`;

const ShowMoreButton = styled.button`
  background: none;
  border: none;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  padding: 2px 0;
  width: 100%;
  text-align: left;
  margin-top: 4px;

  &:hover {
    text-decoration: underline;
  }
`;

const CommentInputWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  padding: 4px 8px 4px 4px;
  border-radius: 27px;
  background: #fff;
  border: 1px solid #b2b2b2;
`;

const StyledTextInput = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  
  .input-actions {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    flex: 1;
    border: none;
    background: transparent;
    padding: 8px 40px 8px 8px;
    font-size: 12px;
    color: #333;
    width: 100%;

    @media (max-width: 768px) {
      font-size: 10px;
      padding: 6px 40px 6px 8px;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #666;
    }
  }
`;

export default CommentSection;
