import React, { useContext } from "react";
import { AppContext } from "../Helpers/Context";
import { Outlet, Navigate, useParams } from "react-router-dom";
import LottieLoading from "../Components/Loaders/LottieLoading";
import { getCookie } from "../Helpers/useCookie";
import { useGetCommunity } from "../features/Community/hooks/community/useGetCommunity";

export const PrivateRoutes = () => {
  const { user, isAuthenticating } = useContext(AppContext);
  let aTokenX = getCookie("access");
  if (isAuthenticating) {
    return (<LottieLoading />);
  }

  return user || aTokenX ? <Outlet /> : <Navigate to="/login" />;
};

export const CommunityRoutes = () => {
  const { user } = useContext(AppContext);
  const { id } = useParams();
  const { data: community, isLoading } = useGetCommunity(id);
  const isOwner = user?.id === community?.author?.id;
  return isOwner ? <Outlet /> : <Navigate to={`/community/${id}`} />;
};

export const SignRoutes = () => {
  const { user, isAuthenticating } = useContext(AppContext);

  if (isAuthenticating) {
    return (<LottieLoading />);
  }

  return !user ? <Outlet /> : <Navigate to="/" />;
};

export const AuthRoutes = ({ children }) => {
  const { user, isAuthenticating } = useContext(AppContext);

  if (isAuthenticating) {
    return (<LottieLoading />);
  }
  return !user ? children : user && user?.required_empty_data?.length > 0 ? <Navigate to="/profile" /> : <Navigate to="/home" />;
};
