import { Skeleton } from "@mantine/core";
import { useTranslation } from "react-i18next";

const CreatorSection = ({ isLoading, data }) => {
  const {t} = useTranslation()
  return (
  <div className="panel-section creator">
    <h4>{t('creator')}</h4>
    <div className="creator-info">
      {isLoading ? (
        <>
          <Skeleton height={45} width={45} circle />
          <Skeleton height={20} width={100} radius="md" />
        </>
      ) : (
        <>
          <div className="avatar">
            <img
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                borderRadius: "50%",
              }}
              src={data?.author?.profile_img}
              alt="avatar"
            />
          </div>
          <p>
            {data?.author?.first_name} {data?.author?.last_name}
          </p>
        </>
      )}
    </div>
  </div>
);
};

export default CreatorSection; 