// ShareDropdown.jsx
import React, { useState } from "react";
import styled from "styled-components";
import { IconX } from "@tabler/icons-react";
import facebookIcon from "../../../../../Assets/SVGs/community/social/fb.svg";
import instagramIcon from "../../../../../Assets/SVGs/community/social/insta.svg";
import whatsappIcon from "../../../../../Assets/SVGs/community/social/wp.svg";
import linkedinIcon from "../../../../../Assets/SVGs/community/social/in.svg";
import { useTranslation } from "react-i18next";

const ShareDropdown = ({ isOpen, onClose, url }) => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  const shareVia = (platform) => {
    const shareUrls = {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        url
      )}`,
      instagram: `https://www.instagram.com`, // Instagram direct sharing not supported
      whatsapp: `https://wa.me/?text=${encodeURIComponent(url)}`,
      linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
        url
      )}`,
    };

    window.open(shareUrls[platform], "_blank");
  };

  return (
    <DropdownOverlay onClick={onClose}>
      <DropdownContent onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <IconX size={20} />
        </CloseButton>

        <Title>{t("share-via")}</Title>

        <SocialButtons>
          <SocialButton onClick={() => shareVia("facebook")}>
            <img src={facebookIcon} alt="Facebook" />
          </SocialButton>
          <SocialButton onClick={() => shareVia("instagram")}>
            <img src={instagramIcon} alt="Instagram" />
          </SocialButton>
          <SocialButton onClick={() => shareVia("whatsapp")}>
            <img src={whatsappIcon} alt="WhatsApp" />
          </SocialButton>
          <SocialButton onClick={() => shareVia("linkedin")}>
            <img src={linkedinIcon} alt="LinkedIn" />
          </SocialButton>
        </SocialButtons>

        <CopySection>
          <CopyText>{t("copy-link")}</CopyText>
          <LinkInput>
            <LinkText>{url}</LinkText>
          </LinkInput>
          <CopyButton onClick={handleCopy}>
            {copied ? t("copied") : t("copy")}
          </CopyButton>
        </CopySection>
      </DropdownContent>
    </DropdownOverlay>
  );
};

const DropdownOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const DropdownContent = styled.div`
  background: white;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  max-width: 309px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: #666;

  &:hover {
    color: #000;
  }
`;

const Title = styled.h3`
  margin: 0 0 20px;
  font-size: 16px;
  font-weight: 500;
  color: #202020;
`;

const SocialButtons = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  justify-content: flex-start;
`;

const SocialButton = styled.button`
  width: 45px;
  height: 42px;
  border-radius: 11px;
  border: 1px solid #d9d9d9;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 28px;
    height: 28px;
  }

  &:hover {
    background: #f5f5f5;
  }
`;

const CopySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CopyText = styled.p`
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
  color: #202020;
`;

const LinkInput = styled.div`
  display: flex;
  align-items: center;
  border-radius: 11px;
  border: 1px solid #029199;
  padding: 8px 12px;
  gap: 8px;
`;

const LinkText = styled.div`
  flex: 1;
  font-size: 15px;
  font-weight: 500;
  color: #029199;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CopyButton = styled.button`
  border-radius: 11px;
  background: #029199;
  color: white;
  border: none;
  padding: 12px 16px;
  cursor: pointer;
  white-space: nowrap;
  margin-top: 10px;
  &:hover {
    background: #00796b;
  }
`;

export default ShareDropdown;
