import React, { useContext, useEffect, useState } from "react";
import Footer from "../Components/NewDashboard/Footer";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import NavbarContent from "../Components/NewDashboard/Navbar";
import AsideContent from "../Components/NewDashboard/Aside";
import { AppContext } from "../Helpers/Context";
import UserHeader from "../Components/NewDashboard/Header/UserHeader";
import NonUserHeader from "../Components/NewDashboard/Header/NonUserHeader";
import { useTranslation } from "react-i18next";
import SteamSidebarContent from "../Components/NewDashboard/Sidebar/SteamSidebarContent";
import { useQuery } from "@tanstack/react-query";
import LottieLoading from "../Components/Loaders/LottieLoading";
import axios from "../Helpers/Axios";

export const MainLayout = ({ props }) => {
  const { isSidebar, isAside } = props;
  let { user } = useContext(AppContext);
  const { pathname } = useLocation();
  const { t } = useTranslation();


  return (
    <LayoutContainer pathname={pathname}>
      {user && pathname !== "/tip/videos" && pathname !== "/tip/vision" ? (
        <UserHeader />
      ) : !user && pathname !== "/tip/videos" && pathname !== "/tip/vision" ? (
        <NonUserHeader />
      ) : null}

      <main style={{minHeight : '100vh'}}>
        <Container
          isAside={isAside}
          isSidebar={isSidebar}
          className="container"
        >
          <Flex>
            {isSidebar && (
              <SideBarContainer pathname={pathname} user={user} isSidebar={isSidebar}>
                <NavbarContent t={t} />
              </SideBarContainer>
            )}
            <MainContainer isAside={isAside} isSidebar={isSidebar}>
              <Outlet />
            </MainContainer>
            {isAside && (
              <AsideContainer user={user} isAside={isAside}>
                <AsideContent t={t} />
              </AsideContainer>
            )}
          </Flex>
        </Container>
      </main>

      {pathname !== "/tip/videos" && pathname !== "/tip/vision" && (
        <footer>
          <Footer />
        </footer>
      )}
    </LayoutContainer>
  );
};

export const SteamLayout = () => {
  const { pathname } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate()
  const [steamStatus, setSteamStatus] = useState('');
  const newArr = pathname.split('/')
  const type = newArr[1]
  const { isLoading: appliedLoading } = useQuery({
    queryKey: ["isApplied", id],
    queryFn: async () => {
      const response = await axios.get(`/${type === "bootcamp" ? "bootcamp" : "steam/steam"}/${id}/apply`);
      return response.data;
    },
    onSuccess: (data) => {
      setSteamStatus(data?.status);
    },

    enabled: !!id, // Moved inside the options object
  });

  useEffect(() => {
    if(steamStatus === "Fail") {
      navigate(`/${type}/${id}`)
    }
  },[steamStatus, navigate, id,type])

  if(appliedLoading) {
    return <LottieLoading />
  }

  return (
    <>
      <UserHeader />
      <SteamFlexBox>
        <SteamSideBar>
          <SteamSidebarContent type={type} id={id} />
        </SteamSideBar>
        <NavigationBar>
          <SteamSidebarContent type={type} id={id} />
        </NavigationBar>

        <SteamMainSide path={pathname}>
          <div className="outlet-wrapper">
            <Outlet />
          </div>
        </SteamMainSide>
      </SteamFlexBox>
    </>
  );
};
const SteamSideBar = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 15px 0px #0000001a;
  position: fixed;
  top: 85px;
  width: 17%;
  height: 100vh;
  z-index: 10;

  @media screen and (max-width: 576px) {
    display: none;
  }
`;

const NavigationBar = styled.div`
  box-shadow: 0px -1px 5px 0px #0000000d;
  background-color: #fff;
  margin: 0 10px;
  @media screen and (min-width: 576px) {
    display: none;
  }
`;

const SteamMainSide = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  background-color: ${(props) =>
    props.path.includes("day") || props.path.includes('task-comment') ? "#EFF3F4" : "#fffefd"};
  .outlet-wrapper {
    width: 83%;
  }
  @media screen and (max-width: 576px) {
    .outlet-wrapper {
      width: 100%;
    }
  }
`;

const SteamFlexBox = styled.div`
  display: flex;
  @media screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

const LayoutContainer = styled.div`
  background-color: ${(props) =>
    props.pathname === "/home"
      ? "#f2f2f2"
      : props.pathname === "/profile"
      ? "#eff3f4"
      : props.pathname.includes("/courses")
      ? "#f6f6f6"
      : props.pathname.includes("/steam")
      ? "#F6F6F6"
      : props.pathname.includes("/communities")
      ? "#F6F6F6"
      : props.pathname.includes("/categories")
      ? "#f6f6f6"
      : props.pathname.includes("/cover-photo")
      ? "#f6f6f6"
      : props.pathname.includes("/community")
      ? "#F6F6F6"
      : "#fff"};
`;

const Container = styled.div`
  max-width: ${(props) => (props.isAside || props.isSidebar ? "95%" : "100%")};
  margin: 0 auto;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SideBarContainer = styled.div`
  width: ${(props) => (props.isSidebar ? "19.5%" : "0%")};
  height: ${(props) => (props.pathname.includes("/community") ? "1400px" : "1000px")};
  position: sticky;
  top: ${(props) => (props.user ? "-250px" : "100px")};
  @media screen and (max-width: 1376px) {
    width: ${(props) => (props.isSidebar ? "21.5%" : "0%")};
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
`;

const AsideContainer = styled.div`
  width: ${(props) => (props.isAside ? "19.5%" : "0%")};
  position: sticky;
  height: 800px;
  top: ${(props) => (props.user ? "-260px" : "-285px")};
  @media screen and (max-width: 1376px) {
    width: ${(props) => (props.isAside ? "21.5%" : "0%")};
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
`;

const MainContainer = styled.div`
  width: ${(props) =>
    props.isAside && props.isSidebar
      ? "57%"
      : props.isSidebar && !props.isAside
      ? "79%"
      : "100%"};

  @media screen and (max-width: 1376px) {
    width: ${(props) =>
      props.isAside && props.isSidebar
        ? "50%"
        : props.isSidebar && !props.isAside
        ? "70%"
        : "100%"};
  }
  @media screen and (max-width: 576px) {
    width: 100%;
  }
`;
