import { useQueries, useQuery } from "@tanstack/react-query"
import { getReplies } from "../../../services/apiPost"
import { usePost } from "../usePost";

export const useGetReplyComments = (community_id, post_id, replyingTo, page) => {
    const {queryKeys} = usePost();
    return useQuery({
        queryKey: queryKeys.replies(community_id, post_id, replyingTo, page,null),
        queryFn: () => getReplies({community_id, post_id, replyingTo, page}),
        enabled: !!replyingTo
    })
}


export const useGetCurrentCommentReplies = (community_id, post_id, page,allCommentIds) => {
    const {queryKeys} = usePost();
    const queries = useQueries({
        queries:allCommentIds.map(commentId=>({
            queryKey: queryKeys.replies(community_id, post_id, commentId, page),
            queryFn: () => getReplies({community_id, post_id, replyingTo:commentId, page}),
        }))
    })
    return queries
}
