import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePost } from "../usePost";
import { createComment } from "../../../services/apiPost";


export const useCreateComment = (community_id,post_id,page) => {
    const queryClient = useQueryClient();

    const { invalidatePostQueries, handleError } = usePost();

    return useMutation({
        mutationFn: ({data}) => createComment({community_id,post_id,data}),
        onSuccess: () => {

            queryClient.invalidateQueries({queryKey: ["comments",community_id,post_id,page]});
        },
        onError: (error) => {
            handleError(error);
        },
    })
}
