import { useQuery } from "@tanstack/react-query";
import { getAllCategory } from "../../services/apiCategory";
import { useCategory } from "./useCategory";
export const useGetAllCategories = () => {
    const {queryKeys,handleError} = useCategory();

    return useQuery({
        queryKey: queryKeys.all,
        queryFn: getAllCategory,
        onError: (error) => handleError(error),
    })
}
