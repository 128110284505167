import React, { useContext } from "react";
import { HighlightStyled } from "../../features/Community/style";
import { Group, Text, Skeleton } from "@mantine/core";
import Button from "../../Components/FormComponent/Button";
import { IconBell } from "@tabler/icons-react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetHighlight } from "../../features/Community/hooks/highlight/useGetHighlight";
import { useGetCommunity } from "../../features/Community/hooks/community/useGetCommunity";
import { useJoinCommunity } from "../../features/Community/hooks/community/useJoinCommunity";
import { AppContext } from "../../Helpers/Context";
import { useJoinHighlight } from "../../features/Community/hooks/highlight/useJoinHighlight";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

const JoinPanel = ({ type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { communityId, highlightId } = useParams();
  const { user } = useContext(AppContext);
  const { data: highlight } = useGetHighlight(communityId, highlightId);
  const { data: community } = useGetCommunity(communityId, highlightId);
  const { mutate: joinCommunity } = useJoinCommunity(communityId);
  const { mutate: joinHighlight } = useJoinHighlight(communityId, highlightId);
  const commonData = type === "highlight" ? highlight : community;
  const imageSrc =
    type === "highlight"
      ? `https://taskilled.blob.core.windows.net/taskool-store/${commonData?.image}`
      : `${commonData?.cover_photo_url}`;

  const handleJoinCommunity = () => {
    if (type === "highlight" && (isOwner || commonData?.is_joined)) {
      navigate(`/community/${communityId}/highlight/${highlightId}`);
    } else if (type === "highlight" && !commonData?.is_joined) {
      joinHighlight();
    } else if (type === "community" && !commonData?.is_free) {
      navigate(`/community/${communityId}/payment`);
    } else {
      joinCommunity(commonData?.id);
    }
  };

  const isOwner = user?.id === commonData?.author?.id;
  const xs = useMediaQuery('(max-width: 768px)');

  if (!commonData) {
    return (
      <HighlightStyled>
        <div className="highlight-join-container">
          <div className="highlight-join-banner">
            <Skeleton height={200} radius="md" animate={true} />
          </div>
          <div style={{ marginTop: 40 }} className="highlight-join-about">
            <Group align="center" position="apart">
              <Skeleton height={24} width={200} radius="md" />
              <Group align="center" gap={10}>
                <Skeleton height={20} width={40} radius="md" />
                <Skeleton height={40} width={100} radius="md" />
                <Skeleton height={40} width={40} radius="md" circle />
              </Group>
            </Group>

            <Skeleton height={80} mt={20} radius="md" />

            <Group mt={20} align="center" spacing={5}>
              <Skeleton height={20} width={80} radius="md" />
              <Skeleton height={20} width={30} radius="md" />
            </Group>
          </div>
        </div>
      </HighlightStyled>
    );
  }


  return (
    <HighlightStyled>
      <div className="highlight-join-container">
        <div className="highlight-join-banner">
          <img src={imageSrc} alt="highlight-banner" />
        </div>
        <div className="highlight-join-about">
          <Group align="center" position="apart">
            <Text size={xs ? 18 :20} weight={600} color="#202020">
              {commonData?.name}
            </Text>

            <Group align="center" gap={10}>
              <Button
                disabled={false}
                loading={false}
                variant="primary"
                backgroundColor="transparent"
                color="#364152"
                padding={xs ? "0px" : "8px 8px"}
                border="none"
              >
                {(type === "community" && community?.is_free) ||
                type === "highlight"
                  ? t("free")
                  : `${t("fee")} ${community?.price}₼`}
              </Button>
              <Button
                variant="primary"
                backgroundColor="#FE6513"
                color="#fff"
                padding={xs ? "6px 14px" : "9px 28px"}
                onClick={handleJoinCommunity}
              >
                {commonData?.is_joined || isOwner
                  ? t("enter")
                  : commonData?.is_free === false
                  ? t("pay")
                  : t("join")}
              </Button>
              <div className="notification-circle">
                <IconBell color="#202020" size={xs ? 20 : 24} />
              </div>
            </Group>
          </Group>
          <Text
            mt={xs ? 15 : 20}
            size={xs ? 15 : 16}
            weight={400}
            color="#364152"
            style={{
              lineHeight: "29px",
            }}
          >
            {commonData?.description}
          </Text>
          <Group mt={xs ? 15 : 20} align="center" spacing={5}>
            <Text style={{textTransform: "capitalize"}} size={xs ? 14 : 16} weight={500} color="#029199">
              {t("members")}:
            </Text>
            <Text mt={2} size={xs ? 12 :14} weight={500} color="#202020">
              {commonData?.join_count || 1}
            </Text>
          </Group>
        </div>
      </div>
    </HighlightStyled>
  );
};

export default JoinPanel;
