import React from 'react';
import { CategoryTagStyled } from '../../style';
import { Skeleton } from '@mantine/core';

const CategoryTagSkeleton = () => {
  return (
    <CategoryTagStyled style={{ cursor: 'default' }}>
      {/* Icon skeleton */}
      <Skeleton 
        width={24} 
        height={24} 
        radius="sm"
      />
      
      {/* Text skeleton */}
      <Skeleton 
        height={16} 
        width={80} 
        radius="sm" 
        ml={8}
      />
    </CategoryTagStyled>
  );
};

export default CategoryTagSkeleton;
