import React, { useState } from "react";
import { CreatePostSideStyled } from "../../style";
import { Group, Text, TextInput } from "@mantine/core";
import photo_video from "../../../../Assets/SVGs/community/post/photo-video.svg";
import poll from "../../../../Assets/SVGs/community/post/poll.svg";
import quiz from "../../../../Assets/SVGs/community/post/quiz.svg";
import ModalPostLayout from "../modal/ModalPostLayout";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from 'react-i18next';

const CreatePostSide = ({
  type,
  communityId,
  openModal,
  setOpenModal,
  activeTab,
  setActiveTab,
  postId,
  postTab,
  label,
}) => {
  const {highlightId} = useParams();
  const { t } = useTranslation();

  const handleOpenModal = (tab) => {
    if (postTab === "quizes" && tab !== "quiz") {
      return;
    }
    if (postTab === "hashtags" && (tab === "poll" || tab === "quiz")) {
      return;
    }
    setActiveTab(tab);
    setOpenModal(true);
  };

  const getDisabledStyle = (tab) => {
    if (postTab === "quizes" && tab !== "quiz") {
      return { opacity: 0.5, cursor: "not-allowed" };
    }
    if (postTab === "hashtags" && (tab === "poll" || tab === "quiz")) {
      return { opacity: 0.5, cursor: "not-allowed" };
    }
    return { opacity: 1, cursor: "pointer" };
  };

  const xs = useMediaQuery('(max-width: 768px)');

  return (
    <CreatePostSideStyled>
      <Text size={xs ? 16 : 18} weight={500} color="#707070" mb={10}>
        {t("posts")}
      </Text>

      <TextInput
        onClick={() => handleOpenModal((postTab === "posts" || postTab === "hashtags") ? "text" : "quiz")}
        className="create-post-input"
        placeholder={t("create-post")}
      />
      <Group
        style={{
          marginTop: 14,
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Group
          onClick={() => handleOpenModal("media")}
          align="center"
          spacing={xs ? 8 : 10}
          style={getDisabledStyle("media")}
        >
          <img style={{width: xs ? 17 : 20 , height: xs ? 17 : 20}} src={photo_video} alt="photo_video" />
          <Text size={xs ? 14 : 16} weight={500} color="#393838">
            {t("photo-video")}
          </Text>
        </Group>
        <Group
          onClick={() => handleOpenModal("poll")}
          align="center"
          spacing={xs ? 8 : 10}
          style={getDisabledStyle("poll")}
        >
          <img style={{width: xs ? 18 : 21 , height: xs ? 18 : 21}} src={poll} alt="poll" />
          <Text size={xs ? 14 : 16} weight={500} color="#393838">
            {t("poll")}
          </Text>
        </Group>
        <Group
          onClick={() => handleOpenModal("quiz")}
          align="center"
          spacing={xs ? 8 : 10}
          style={getDisabledStyle("quiz")}
        >
          <img style={{width: xs ? 19 : 22 , height: xs ? 19 : 22}} src={quiz} alt="quiz" />
          <Text size={xs ? 14 : 16} weight={500} color="#393838">
            {t("quiz")}
          </Text>
        </Group>
      </Group>
      <ModalPostLayout
        type={type}
        highlightId={highlightId}
        communityId={communityId}
        opened={openModal}
        onClose={() => setOpenModal(false)}
        active={activeTab}
        postId={postId}
        postTab={postTab}
        label={label}
      />
    </CreatePostSideStyled>
  );
};

export default CreatePostSide;
