import axios from "../../../Helpers/Axios";

// highlight

export const getHighlight = async ({community_id,highlight_id}) => {
  const response = await axios.get(`/community/${community_id}/highlights/${highlight_id}/`);
  return response.data;
};

export const getAllHighlight = async (id,page=1) => {
  const response = await axios.get(`/community/${id}/highlights/?page=${page}`);
  return response.data;
};

export const createHighlight = async (id, data) => {
  const response = await axios.post(`/community/${id}/create-highlight`, data);
  return response.data;
};

export const updateHighlight = async ({community_id,highlight_id,data}) => {
  const response = await axios.put(`/community/${community_id}/highlights/${highlight_id}/`, data);
  return response.data;
};

export const joinHighlight = async ({community_id,highlight_id}) => {
  const response = await axios.post(`/community/${community_id}/highlights/${highlight_id}/join-the-highlight`);
  return response.data;
};

// highligh post

export const createHighlightPost = async ({community_id,highlight_id,data}) => {
  const response = await axios.post(`/community/${community_id}/highlights/${highlight_id}/create-post`, data);
  return response.data;
};

export const updateHighlightPost = async ({community_id,highlight_id,post_id,data}) => {
  const response = await axios.put(`/community/${community_id}/highlights/${highlight_id}/posts/${post_id}`, data);
  return response.data;
};

export const getHighlightPost = async ({community_id,highlight_id,post_id}) => {
  const response = await axios.get(`/community/${community_id}/highlights/${highlight_id}/posts/${post_id}`);
  return response.data;
};

export const getAllHighlightPost = async ({community_id,highlight_id,page=1,post_tab,label}) => {
  const params = new URLSearchParams();
  if(post_tab === "quizes"){
    params.append("is_quiz", "true");
  } else if(post_tab === "hashtags"){
    params.append("has_hashtags", "true");
  }
  if(label){
    params.append("label", label);
  }
  params.append("page", page);
  const response = await axios.get(`/community/${community_id}/highlights/${highlight_id}/posts/search?${params.toString()}`);
  return response.data;
};

export const deleteHighlightPost = async ({community_id,highlight_id,post_id}) => {
  const response = await axios.delete(`/community/${community_id}/highlights/${highlight_id}/posts/${post_id}`);
  return response.data;
};

export const deleteHighlightPostImage = async ({community_id,highlight_id,post_id,image_id}) => {
  const response = await axios.delete(`/community/${community_id}/highlights/${highlight_id}/posts/${post_id}/image/${image_id}`);
  return response.data;
};

export const deleteHighlightPostVideo = async ({community_id,highlight_id,post_id,video_id}) => {
  const response = await axios.delete(`/community/${community_id}/highlights/${highlight_id}/posts/${post_id}/video/${video_id}`);
  return response.data;
};

export const deleteHighlightPostAnswer = async ({community_id,highlight_id,post_id,question_id,answer_id}) => {
  const response = await axios.delete(`/community/${community_id}/highlights/${highlight_id}/posts/${post_id}/question/${question_id}/answer/${answer_id}`);
  return response.data;
};

export const answeredToHighlightPost = async ({community_id,highlight_id,post_id,question_id,answer_id}) => {
  const response = await axios.post(`/community/${community_id}/highlights/${highlight_id}/posts/${post_id}/question/${question_id}/answer/${answer_id}`);
  return response.data;
};

export const pinHighlightPost = async ({community_id,highlightId,post_id}) => {
  const response = await axios.put(`/community/${community_id}/highlights/${highlightId}/posts/${post_id}/pin-the-post`);
  return response.data;
};


