import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Bookmark } from "tabler-icons-react";
import { Skeleton } from "@mantine/core";
import { useGetAllCategories } from "../../../hooks/category/useGetAllCategories";
import { useGetCollaboratorsByBadgeId } from "../../../hooks/badges/useCollaboratorList";
import { Link, useParams } from "react-router-dom";
import { useGetBadgesList } from "../../../hooks/badges/useGetBadges";
import { useGetAllCollaboratorList } from "../../../hooks/badges/useGetAllCollaboratorList";
import AboutSection from "./components/AboutSection";
import CategorySection from "./components/CategorySection";
import MembersSection from "./components/MembersSection";
import CreatorSection from "./components/CreatorSection";
import CollaboratorSection from "./components/CollaboratorSection";
import SavedPostsSection from "./components/SavedPostsSection";

export const AboutPanelStyled = styled.div`
  .panel-section {
    background: #fff;
    padding: 19px 29px 17px 17px;
    border-radius: 11px;
    margin-bottom: 14px;
    @media (max-width: 768px) {
      margin-bottom: 2px;
      padding: 8px 5px;
    }
    h4 {
      font-size: 17px;
      font-weight: 500;
      color: #029199;
      @media (max-width: 768px) {
        font-size: 16px;
        border-bottom: none;
      }
    }
    p {
      color: #202020;
      text-align: justify;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px; /* 162.5% */
      @media (max-width: 768px) {
        font-size: 14px;
        margin-top: 0px;
      }
    }
  }

  .about {
    h4 {
      border-bottom: none;
      padding-bottom: 8px;
      @media (max-width: 768px) {
        padding-bottom: 0px;
        border-bottom: none;
      }
    }
    p {
      margin-top: 6px;
      @media (max-width: 768px) {
        margin-top: 0px;
      }
    }
  }
  .category {
    .category-title {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 8px;
      font-size: 14px;
      color: #202020;
      @media (max-width: 768px) {
        font-size: 12px;
        gap: 5px;
        align-items: flex-end;
        margin-top: 5px;
      }
      svg {
        width: 20px;
        height: 20px;
        @media (max-width: 768px) {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .members {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
    }
  }

  .creator {
    .creator-info {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 10px;
    }
  }

  .collaborator {
    position: relative;
    @media (max-width: 768px) {
      margin-top: 10px;
    }
    h4 {
      margin-bottom: 4px;
      @media (max-width: 768px) {
        margin-bottom: 8px;
      }
    }

    .swiper-button-next {
      width: 24px;
      height: 24px;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

      &::after {
        font-size: 12px;
        color: #000000;
      }
    }

    .swiper-button-prev {
      display: none;
    }
  }
  .saved-posts {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }

  .section-title {
    color: #029199;
    font-size: 16px;
    font-weight: 500;
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  .avatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      width: 35px;
      height: 35px;
    }
  }
`;

const AboutPanel = ({ highlight, community, isLoading }) => {
  const data = highlight || community;
  const { id } = useParams();
  const { data: categories } = useGetAllCategories();
  const { data: collaborators } = useGetAllCollaboratorList(id);
  const category = categories && community ? categories[community.category] : null;

  return (
    <AboutPanelStyled>
      <AboutSection isLoading={isLoading} data={data} />
      {category && <CategorySection isLoading={isLoading} category={category} />}
      <MembersSection isLoading={isLoading} data={data} />
      <CreatorSection isLoading={isLoading} data={data} />
     {collaborators && collaborators?.results?.length > 0 && <CollaboratorSection
        isLoading={isLoading}
        collaborators={collaborators}
      />}
      <SavedPostsSection id={id} />
    </AboutPanelStyled>
  );
};

export default AboutPanel;
