import { useMutation } from "@tanstack/react-query";
import { useHighlight } from "./useHighlight";
import { updateHighlight } from "../../services/apiHighlight";


export const useUpdateHighlight = (communityId, highlightId,onClose) => {
    const {queryKeys, handleError , invalidateHighlightQueries, handleSuccess} = useHighlight();
    return useMutation({
        mutationFn: ({community_id,highlight_id,data}) => updateHighlight({community_id,highlight_id,data}  ),
        onSuccess: () => {
            invalidateHighlightQueries(communityId,highlightId,null,"detail");
            handleSuccess('Highlight redaktəlendi');
            onClose();
        },
        onError: (error) => {
            handleError(error);
        }
    })
}
