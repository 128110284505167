import { useQuery } from "@tanstack/react-query";
import { usePost } from "./usePost";
import { getPost } from "../../services/apiPost";


export const useGetPost = (communityId,postId,type) => {
    const {queryKeys, handleError} = usePost();

    return useQuery({
        queryKey: queryKeys.detail(communityId,postId),
        queryFn: () => getPost(communityId,postId),
        onError: (error) => handleError(error),
        enabled: !!communityId && !!postId && type === "community",
    })
}
