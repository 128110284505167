import { Skeleton } from "@mantine/core";
import { useTranslation } from "react-i18next";

const CategorySection = ({ isLoading, category }) => {
  const { t } = useTranslation();

  const svgBlob = category?.svg_icon ? 
    new Blob([category.svg_icon], { type: 'image/svg+xml' }) : null;
  const svgUrl = svgBlob ? URL.createObjectURL(svgBlob) : null;

  return (
    <div className="panel-section category">
      <h4>{t('category')}</h4>
      {isLoading ? (
        <Skeleton height={20} width={40} radius="md" />
      ) : (
        <div className="category-title">
          {category?.svg_icon && (
            <object 
              type="image/svg+xml"
              data={svgUrl}
              width={21}
              height={21}
              style={{ pointerEvents: 'none' }}
              aria-label={category?.name || 'Category icon'}
            />
          )}
          <p>{category?.name}</p>
        </div>
      )}
    </div>
  );
};

export default CategorySection; 