import { useMutation } from "@tanstack/react-query";
import { answeredToPost } from "../../services/apiPost";
import { usePost } from "./usePost";

export const useAnsweredToPost = (communityId,type,postTab,label) => {
    const {handleError, invalidatePostQueries, queryKeys} = usePost();

    return useMutation({
        mutationFn: ({post_id,question_id,answer_id}) => answeredToPost({community_id:communityId,post_id,question_id,answer_id}),
        onSuccess: () => {
            if(type === "savedPosts"){
                invalidatePostQueries(communityId,null,"savedPosts",null,null,postTab,null);
            }else{
                invalidatePostQueries(communityId,null,"posts",null,null,postTab,null);
            }
        },
        onError: (error) => {
            handleError(error);
        },
    })
}
