import React from "react";
import styled from "styled-components";
import { Accordion, Grid, Group, Stack, Text, Title } from "@mantine/core";
import { Plus } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

// Taskool haqqında məlumatlar

export const ForthPart = () => {
  const matchesXs = useMediaQuery("(max-width: 576px)");
  const { t } = useTranslation();

  const taskoolData = [
    {
      question: t("question_one"),
      answer: t("answer_one"),
    },
    {
      question: t("question_two"),
      answer: t("answer_two"),
    },
    {
      question: t("question_three"),
      answer: t("answer_three"),
    },
    {
      question: t("question_four"),
      answer: t("answer_four"),
    },
    {
      question: t("question_five"),
      answer: t("answer_five"),
    },
    {
      question: t("question_six"),
      answer: t("answer_six"),
    },
    {
      question: t("question_seven"),
      answer: t("answer_seven"),
    },
    {
      question: t("question_eight"),
      answer: t("answer_eight"),
    },
  ];
  const underTextVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.1,
        duration: 0.2,
      },
    },
  };
  const leftVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
        duration: 0.5,
      },
    }),
  };

  const rightVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
        duration: 0.5,
      },
    }),
  };

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [activeSlide, setActiveSlide] = React.useState(0);

  return (
    <ForthPartStyle ref={ref}>
      {!matchesXs ? (
        <motion.div
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={underTextVariants}
        >
          <Group position="center" my={40}>
            <Title color="#1A5059" size={matchesXs ? 25 : 30} weight="bold">
              {t("frequently_asked_question")}
            </Title>
          </Group>
        </motion.div>
      ) : (
        <Group position="center" my={40}>
          <Title color="#1A5059" size={matchesXs ? 25 : 30} weight="bold">
            {t("frequently_asked_question")}
          </Title>
        </Group>
      )}

      <Accordion
        chevron={<Plus size={16} />}
        value={`focus-ring-${activeSlide + 1}`}
        styles={{
          chevron: {
            "&[data-rotate]": {
              transform: "rotate(45deg)",
            },
          },
        }}
      >
        {matchesXs ? (
          <Swiper
            slidesPerView={1}
            spaceBetween={60}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper faq"
            onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
          >
            {taskoolData.map((item, index) => (
              <SwiperSlide key={index}>
                <Accordion.Item
                  style={{
                    borderLeft: "3.5px solid #1A5059",
                    borderRadius: "3px",
                    backgroundColor: "#fff",
                    boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.1)",
                    borderBottom: "none",
                  }}
                  value={`focus-ring-${index + 1}`}
                >
                  <Accordion.Control
                    style={{
                      color: "#1A5059",
                      fontSize: "18px",
                      fontWeight: "600",
                    }}
                  >
                    <Title color="#1A5059" size={18} weight={600}>
                      {item.question}
                    </Title>
                  </Accordion.Control>
                  <Accordion.Panel>
                    <Text color="#575757" size={16} weight={400}>
                      {item.answer}
                    </Text>
                  </Accordion.Panel>
                </Accordion.Item>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Grid ref={ref} gutter={120}>
            <Grid.Col span={6}>
              <Stack>
                {taskoolData
                  .slice(0, Math.ceil(taskoolData.length / 2))
                  .map((item, index) => (
                    <motion.div
                      custom={index}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={leftVariants}
                      key={index}
                    >
                      <Accordion.Item
                        style={{
                          borderLeft: "3.5px solid #1A5059",
                          borderRadius: "3px",
                          backgroundColor: "#fff",
                          boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.1)",
                          borderBottom: "none",
                        }}
                        value={`focus-ring-${index + 1}`}
                      >
                        <Accordion.Control
                          style={{
                            color: "#1A5059",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          <Title color="#1A5059" size={18} weight={600}>
                            {item.question}
                          </Title>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <Text color="#575757" size={16} weight={400}>
                            {item.answer}
                          </Text>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </motion.div>
                  ))}
              </Stack>
            </Grid.Col>
            <Grid.Col span={6}>
              <Stack>
                {taskoolData
                  .slice(Math.ceil(taskoolData.length / 2))
                  .map((item, index) => (
                    <motion.div
                      custom={index}
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={rightVariants}
                      key={index}
                    >
                      <Accordion.Item
                        style={{
                          borderLeft: "3.5px solid #1A5059",
                          borderRadius: "3px",
                          backgroundColor: "#fff",
                          boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.1)",
                          borderBottom: "none",
                        }}
                        value={`focus-ring-${
                          Math.ceil(taskoolData.length / 2) + index + 1
                        }`}
                      >
                        <Accordion.Control
                          style={{
                            color: "#1A5059",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          <Title color="#1A5059" size={18} weight={600}>
                            {item.question}
                          </Title>
                        </Accordion.Control>
                        <Accordion.Panel>
                          <Text color="#575757" size={16} weight={400}>
                            {item.answer}
                          </Text>
                        </Accordion.Panel>
                      </Accordion.Item>
                    </motion.div>
                  ))}
              </Stack>
            </Grid.Col>
          </Grid>
        )}
      </Accordion>
    </ForthPartStyle>
  );
};

const ForthPartStyle = styled.div`
  padding: 40px 20px;

  .mySwiper.faq {
    padding-bottom: 25px;
    .swiper-pagination {
      bottom: 0px;
    }
  }
`;
