import axios from "../../../Helpers/Axios";

export const getAllPostLabel = async () => {
    const response = await axios.get("/community/post-labels/");
    return response.data;
};

export const getPost = async (communityId,postId) => {
    const response = await axios.get(`/community/${communityId}/posts/${postId}`);
    return response.data;
};

export const getAllPost = async (communityId, postTab, label, page = 1) => {
    const params = new URLSearchParams();
    
    if (postTab === "quizes") {
        params.append("is_quiz", "true");
    } else if (postTab === "hashtags") {
        params.append("has_hashtags", "true");
    } 
    
    if (label) {
        params.append("label", label);
    }
    
    params.append("page", page);

    const response = await axios.get(`/community/${communityId}/posts/search?${params.toString()}`);
    return response.data;
};

export const createPost = async ({id,data}) => {
    const response = await axios.post(`/community/${id}/create-post`, data);
    return response.data;
};

export const updatePost = async ({communityId,postId, data}) => {
    const response = await axios.put(`/community/${communityId}/posts/${postId}`, data);
    return response.data;
};

export const deletePost = async ({community_id,id}) => {
    const response = await axios.delete(`/community/${community_id}/posts/${id}`);
    return response.data;
};

export const pinPost = async ({community_id,post_id}) => {
    const response = await axios.put(`/community/${community_id}/posts/${post_id}/pin-the-post`);
    return response.data;
};

export const unpinPost = async ({community_id,post_id}) => {
    const response = await axios.delete(`/community/${community_id}/posts/${post_id}/pin-the-post`);
    return response.data;
};

export const deleteAnswer = async ({community_id,post_id,question_id,answer_id}) => {
    const response = await axios.delete(`/community/${community_id}/posts/${post_id}/question/${question_id}/answer/${answer_id}`);
    return response.data;
};

export const deletePostImage = async ({community_id,post_id,image_id}) => {
    const response = await axios.delete(`/community/${community_id}/posts/${post_id}/image/${image_id}`);
    return response.data;
};
export const deletePostVideo = async ({community_id,post_id,video_id}) => {
    const response = await axios.delete(`/community/${community_id}/posts/${post_id}/video/${video_id}`);
    return response.data;
};

export const savePost = async ({community_id,post_id}) => {
    const response = await axios.post(`/community/${community_id}/posts/${post_id}/save-the-post`);
    return response.data;
};

export const unsavePost = async ({community_id,post_id}) => {
    const response = await axios.delete(`/community/${community_id}/posts/${post_id}/save-the-post`);
    return response.data;
};

export const getSavedPosts = async ({community_id,page = 1}) => {
    const response = await axios.get(`/community/${community_id}/saved-posts?page=${page}`);
    return response.data;
};

export const answeredToPost = async ({community_id,post_id,question_id,answer_id}) => {
    const response = await axios.post(`/community/${community_id}/posts/${post_id}/question/${question_id}/answer/${answer_id}`);
    return response.data;
};

// Reaction
// (post)
export const reactToCommunityPost = async ({community_id,post_id,data}) => {
    const response = await axios.post(`/community/${community_id}/posts/${post_id}/reactions/react-the-post`,data);
    return response.data;
};

export const unreactToCommunityPost = async ({community_id,post_id}) => {
    const response = await axios.delete(`/community/${community_id}/posts/${post_id}/reactions/react-the-post`);
    return response.data;
};
export const getReactions = async ({community_id,post_id}) => {
    const response = await axios.get(`/community/${community_id}/posts/${post_id}/reactions/react-the-post`);
    return response.data;
};
export const getAllReactions = async ({community_id,post_id}) => {
    const response = await axios.get(`/community/${community_id}/posts/${post_id}/reactions`);
    return response.data;
};
export const deleteReaction = async ({community_id,post_id}) => {
    const response = await axios.delete(`/community/${community_id}/posts/${post_id}/reactions/react-the-post`);
    return response.data;
};

// (highlight)

// Highlight Reactions
export const getHighlightReactions = async ({community_id, post_id, highlight_id}) => {
    const response = await axios.get(`/community/${community_id}/posts/${post_id}/highlights/${highlight_id}/reactions`);
    return response.data;
};

export const getHighlightReaction = async ({community_id, post_id, highlight_id}) => {
    const response = await axios.get(`/community/${community_id}/posts/${post_id}/highlights/${highlight_id}/reactions/react-the-post`);
    return response.data;
};

export const reactToHighlight = async ({community_id, post_id, highlight_id, data}) => {
    const response = await axios.post(`/community/${community_id}/posts/${post_id}/highlights/${highlight_id}/reactions/react-the-post`, data);
    return response.data;
};

export const unreactToHighlight = async ({community_id, post_id, highlight_id}) => {
    const response = await axios.delete(`/community/${community_id}/posts/${post_id}/highlights/${highlight_id}/reactions/react-the-post`);
    return response.data;
};

// Comment Reactions
export const getCommentReactions = async ({community_id, post_id, comment_id}) => {
    const response = await axios.get(`/community/${community_id}/posts/${post_id}/comments/${comment_id}/reactions`);
    return response.data;
};

export const getCommentReaction = async ({community_id, post_id, comment_id}) => {
    const response = await axios.get(`/community/${community_id}/posts/${post_id}/comments/${comment_id}/reactions/react-the-post`);
    return response.data;
};

export const reactToComment = async ({community_id, post_id, comment_id, data}) => {
    const response = await axios.post(`/community/${community_id}/posts/${post_id}/comments/${comment_id}/reactions/react-the-post`, data);
    return response.data;
};

export const unreactToComment = async ({community_id, post_id, comment_id}) => {
    const response = await axios.delete(`/community/${community_id}/posts/${post_id}/comments/${comment_id}/reactions/react-the-post`);
    return response.data;
};



// Comment

export const createComment = async ({community_id,post_id,data}) => {
    const response = await axios.post(`/community/${community_id}/posts/${post_id}/comments`,data);
    return response.data;
};

export const replyComment = async ({community_id,post_id,comment_id,data}) => {
    const response = await axios.post(`/community/${community_id}/posts/${post_id}/comments/${comment_id}`,data);
    return response.data;
};

export const getComments = async ({community_id,post_id,page = 1}) => {
    const response = await axios.get(`/community/${community_id}/posts/${post_id}/comments?page=${page}`);
    return response.data;
};
export const getReplies = async ({community_id,post_id,replyingTo,page = 1}) => {
    const response = await axios.get(`/community/${community_id}/posts/${post_id}/comments/${replyingTo}?page=${page}`);
    return response.data;
};






  