import { useMutation } from "@tanstack/react-query";
import { usePost } from "./usePost";
import { deleteAnswer } from "../../services/apiPost";

export const useDeleteAnswer = ({community_id,post_id}) => {
  const { handleError, handleSuccess,invalidatePostQueries } = usePost();
  return useMutation({
    mutationFn: ({ community_id, post_id, question_id, answer_id }) =>
      deleteAnswer({ community_id, post_id, question_id, answer_id }),
    onSuccess: () => {
      invalidatePostQueries(community_id,post_id);
      handleSuccess("Cevap uğurla silindi");
    },
    onError: (error) => handleError(error),
  });
};
