import React from 'react';
import { CoverCardImageStyled } from '../../style';
import { Skeleton } from '@mantine/core';

const CoverCardSkeleton = () => {
  return (
    <CoverCardImageStyled style={{ cursor: 'default' }}>
      <Skeleton 
        height={180}
        style={{
          minWidth: '180px',
          width: '100%',
          borderRadius: '12px'
        }}
      />
    </CoverCardImageStyled>
  );
};

export default CoverCardSkeleton;
