import React, { useState } from "react";
import { CommunityLayout } from "./CommunityPanel";
import styled from "styled-components";
import PostList from "../../features/Community/components/community-panel/post-panel/PostList";
import ModalPostLayout from "../../features/Community/components/modal/ModalPostLayout";

const SavedPost = () => {
  const [openModal, setOpenModal] = useState(false);
  const [postId, setPostId] = useState(null);
  const [activeTab, setActiveTab] = useState("");


  return (
    <CommunityLayout>
      <SavedPostContainer>
        <PostList
         setActiveTab={setActiveTab}
          setOpenModal={setOpenModal}
          openModal={openModal}
          setPostId={setPostId}
          postId={postId}
          type="savedPosts"
        />
      </SavedPostContainer>
    </CommunityLayout>
  );
};

export default SavedPost;

const SavedPostContainer = styled.div`
  padding: 20px;
  background: white;
  border-radius: 8px;
  @media (max-width: 768px) {
    padding: 5px;
  }
`;
