import React from 'react'
import styled from 'styled-components'
import { Check } from 'tabler-icons-react'

const CardContainer = styled.div`
  border-radius: 8px;
  border: 1px solid #E7E7E7;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
  padding: 10px 8px 10px 8px;

  &.active {
    border: 2px solid #029199;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .check-circle {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #029199;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const CoverCard = ({img, isSelected, onClick}) => {
  return (
    <CardContainer 
      className={isSelected ? 'active' : ''} 
      onClick={onClick}
    >
        <img src={img} alt="cover" />
        {isSelected && (
          <div className="check-circle">
            <Check size={16} color="#fff" strokeWidth={3} />
          </div>
        )}
    </CardContainer>
  )
}

export default CoverCard