import { useMutation } from "@tanstack/react-query";
import { deleteHighlightPostVideo } from "../../services/apiHighlight";
import { useHighlight } from "./useHighlight";

export const useDeleteHighlightPostVideo = ({community_id, highlight_id, post_id}) => {
    const {invalidateHighlightQueries, handleError, handleSuccess} = useHighlight();
    return useMutation({
        mutationFn: ({community_id, highlight_id, post_id, video_id}) => deleteHighlightPostVideo({community_id, highlight_id, post_id, video_id}),
        onSuccess: () => {
            invalidateHighlightQueries(community_id,highlight_id,post_id,"allPost");
            handleSuccess('Highlight post video silindi');
        },
        onError: (error) => handleError(error),
    })
}

