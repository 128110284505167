import axios from "../../../Helpers/Axios";

export const getAllCategory = async () => {
  const response = await axios.get("/community/categories/");
  return response.data;
};

export const getCategoryCover = async (id) => {
  const response = await axios.get(`/community/categories/${id}/photos/`);
  return response.data;
};


