import { useMutation } from "@tanstack/react-query";
import { unsavePost } from "../../services/apiPost";
import { usePost } from "./usePost";
import { useHighlight } from "../highlight/useHighlight";

export const useUnsavePost = (community_id,highlightId,postTab,label,type) => {
    const {queryKeys,invalidatePostQueries,handleSuccess} = usePost();
    const {invalidateHighlightQueries} = useHighlight();
    return useMutation({
        mutationFn: ({community_id,post_id}) => unsavePost({community_id,post_id}),
        onSuccess: () => {
            handleSuccess('Post uğurla silindi');
            if(type === "community"){
                invalidatePostQueries(community_id,null,null,null,null,postTab,label);
            } else if(type === "highlight"){
                invalidateHighlightQueries(community_id,highlightId,null,"allPost",postTab,label);
            } else if(type === 'savedPosts'){
                invalidatePostQueries(community_id,null,'savedPosts',null,1,postTab,null);
                
            }
        },
        onError: (error) => {
            console.log(error);
        }
    })
}
