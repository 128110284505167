import { useMutation } from "@tanstack/react-query";
import { usePost } from "./usePost";
import { deletePost } from "../../services/apiPost";

export const useDeletePost = (community_id) => {
    const {invalidatePostQueries, handleError, handleSuccess} = usePost();

    return useMutation({
        mutationFn: ({community_id, id}) => deletePost({community_id, id}),
        onSuccess: (data) => {
            invalidatePostQueries(community_id,null,"");
            handleSuccess('Community post uğurla silindi');
        },
        onError: (error) => handleError(error),
    })
}
