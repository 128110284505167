import styled from "styled-components";
import steamLandBg from "../Assets/Images/steamland.png";
import bmu from "../Assets/SVGs/bmu.svg";
import { useState, useEffect } from "react";

const SteamLand = () => {
  const [activeSection, setActiveSection] = useState("innovation");

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      setActiveSection(sectionId);
      
      // H2 elementini tapırıq
      const h2Element = element.querySelector('h2');
      const targetElement = h2Element || element; // əgər h2 yoxdursa, section-un özünü götürürük
      
      const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
      const offset = 200; // h2-nin yuxarısından məsafə
      
      window.scrollTo({
        top: elementPosition - offset,
        behavior: 'smooth'
      });
    }
  };

  // Track scroll position to update active section
  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        "innovation",
        "projects",
        "project-based",
        "networking",
        "olympiad",
        "partners",
      ];

      for (const sectionId of sections) {
        const element = document.getElementById(sectionId);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 100 && rect.bottom >= 100) {
            setActiveSection(sectionId);
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <StyledSteamLand>
      <Banner>
        <BannerInner></BannerInner>
        <BannerTitle>Steamland</BannerTitle>
      </Banner>

      <Navigation>
        <nav>
          <NavItem
            onClick={() => scrollToSection("innovation")}
            $isActive={activeSection === "innovation"}
          >
            STEAMLAND: İnnovasiya Fabriki
          </NavItem>
          <NavItem
            onClick={() => scrollToSection("partners")}
            $isActive={activeSection === "partners"}
          >
            Partnyorlar
          </NavItem>
        </nav>
      </Navigation>

      <Content>
        <Section id="innovation" $isActive={activeSection === "innovation"}>
          <h2>STEAMLAND: İnnovasiya Fabriki</h2>
          <p>
            STEAMLAND Taskool-un STEAM İnnovasiya Fabriki və STEAM Bankını
            birləşdirərək məktəblilər üçün geniş və yenilikçi öyrənmə və inkişaf
            imkanları yaradan platformadır. Burada elm, texnologiya,
            mühəndislik, incəsənət və riyaziyyətin birləşdiyi, həmçinin süni
            intellekt, oyunların hazırlanması (game development), neyroelm kimi
            digər innovativ sahələri əhatə edən müxtəlif mövzularda təlim seçimi
            olunur.
          </p>
          <p>
            Universitetlər və tədqiqat institutlarının əməkdaşlığı ilə
            yaradılmış layihələr Taskool-un sayında yerləşdirilir və
            məktəblilərin istifadəsinə verilir. Bu layihələr şagirdlərə maraqlı
            sahələr üzrə praktiki öyrənmə imkanı yaradır və onların STEAM
            bacarıqlarını inkişaf etdirməyə kömək edir.
          </p>

          <h3>Müxtəlif sahələr üzrə layihələr</h3>
          <p>
            Şagirdlər süni intellekt, oyunların hazırlanması, mühəndislik,
            texnologiya və digər sahələri əhatə edən layihələrdə iştirak edir.
          </p>

          <h3>Layihə əsaslı öyrənmə</h3>
          <p>
            Real layihələr üzərində işləməklə şagirdlər praktiki bacarıqlar əldə
            edir və interdisiplinar yanaşmanı öyrənirlər.
          </p>

          <h3>Netvörkinq qrupları</h3>
          <p>
            STEAM layihələrində iştirak edən şagirdlər üçün netvörkinq qrupları
            yaradılır. Bu qruplar vasitəsilə məktəblilər həmyaşıdları və
            mütəxəssislərlə əlaqə quraraq ideya mübadiləsi edə və birlikdə
            işləyə bilirlər.
          </p>

          <h3>Olimpiada və Hakaton proqramları</h3>
          <p>
            STEAM layihələrinə uyğun mövzularda olimpiada və hakatonlar təşkil
            edilərək məktəblilərin öz bilik və bacarıqlarını sınama şansı
            yaradılır.
          </p>
        </Section>

        <PartnersSection id="partners" $isActive={activeSection === "partners"}>
          <h2>Partnyorlar</h2>
          <PartnersGrid>
            <PartnerLogo src={bmu} alt="BMU logo" />
            <PartnerLogo src={bmu} alt="BMU logo" />
          </PartnersGrid>
        </PartnersSection>
      </Content>
    </StyledSteamLand>
  );
};

const StyledSteamLand = styled.div`
  width: 100%;
`;

const Banner = styled.div`
  background-image: url(${steamLandBg});
  background-size: cover;
  background-position: center;
  height: 362px;
  position: relative;
  padding: 40px 120px 0 120px;
  z-index: 100;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(12, 8, 15, 0.4);
    backdrop-filter: blur(20px);
  }

  @media (max-width: 768px) {
    height: 200px;
    padding: 20px 20px 0 20px;
  }
`;

const BannerInner = styled.div`
  height: 100%;
  position: relative;
  background-image: url(${steamLandBg});
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  overflow: hidden;
`;

const BannerTitle = styled.h1`
  position: absolute;
  color: white;
  font-size: 36px;
  font-weight: 600;
  bottom: 40px;
  left: 40px;
  z-index: 1000;

  @media (max-width: 768px) {
    font-size: 24px;
    bottom: 20px;
    left: 20px;
  }
`;

const Navigation = styled.div`
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px 0;
  position: sticky;
  top: 70px;
  z-index: 100;
  transition: top 0.3s ease-in-out;

  @media (max-width: 768px) {
    top: 50px;
    padding: 5px 0;
    transition: top 0.3s ease-in-out;
  }

  nav {
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    gap: 40px;
    overflow-x: auto;

    @media (max-width: 768px) {
      gap: 0px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

const NavItem = styled.button`
  background: none;
  border: none;
  padding: 10px 0px;
  cursor: pointer;
  white-space: nowrap;
  color: #029199;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
  transition: color 0.3s;
  color: ${(props) => (props.$isActive ? "#029199" : "#333")};

  &:hover {
    color: #029199;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    padding: 8px 0;
  }
`;

const Content = styled.div`
  max-width: 90%;
  margin: 0 auto;
  padding: 40px 20px;

  @media (max-width: 768px) {
    padding: 20px 16px;
  }
`;

const Section = styled.section`
  scroll-margin-top: 100px;

  padding: 20px 0;

  &:first-child {
    padding: 0;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    color: ${(props) => (props.$isActive ? "#029199" : "#333")};
    transition: color 0.3s;

    @media (max-width: 768px) {
      font-size: 20px;
      margin-bottom: 16px;
      text-align: center;
    }
  }
  h3 {
    margin-top: 30px;
    color: #364152;

    @media (max-width: 768px) {
      font-size: 18px;
      margin-top: 24px;
      text-align: center;
    }
  }
  p {
    color: #697586;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    margin-top: 10px;

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 22px;
      text-align: center;
    }
  }

  p {
    color: #333;
    line-height: 1.6;
  }
`;
const PartnersSection = styled(Section)`
  text-align: left;
  padding-bottom: 200px; /* Ekstra boşluk eklemek için */
  padding-top: 120px;
`;

const PartnersGrid = styled.div`
  display: flex;
  justify-content: flec-start;
  gap: 40px;
  margin-top: 30px;
`;

const PartnerLogo = styled.img`
  height: 80px;
  object-fit: contain;
`;

export default SteamLand;
