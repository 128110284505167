import { useMutation } from "@tanstack/react-query";
import { usePost } from "../usePost";
import { replyComment } from "../../../services/apiPost";


export const useReplyComment = (community_id,post_id,comment_id,page,replyingToReply) => {
    const { invalidatePostQueries, handleError } = usePost();
    return useMutation({
        
        mutationFn: ({data}) => replyComment({community_id,post_id,comment_id:replyingToReply,data}),
        onSuccess: () => {
            invalidatePostQueries(community_id,post_id,"replies",comment_id,page,'posts',null);
        },
        onError: (error) => {
            handleError(error);
        },
    })
}
