
import { getAllHighlight } from '../../services/apiHighlight'
import { useHighlight } from './useHighlight'
import { useQuery } from '@tanstack/react-query'

const useGetAllHighligts = (id,page=1) => {
    const {queryKeys} = useHighlight()
    return useQuery({
        queryKey:queryKeys.all(id,page),
        queryFn:()=>getAllHighlight(id,page),
        enabled:!!id
    })
}

export default useGetAllHighligts