import React, { forwardRef } from 'react';
import { TextInput as MantineTextInput } from '@mantine/core';

const TextInput = forwardRef(({ label, error, withAsterisk, ...props }, ref) => {
  return (
    <MantineTextInput
      label={label}
      error={error}
      withAsterisk={withAsterisk}
      styles={(theme) => ({
        label: {
          fontSize: '15px',
          '@media (max-width: 768px)': {
            fontSize: '13px',
            marginBottom: '6px'
          },
          fontWeight: '400',
          color : '#364152',
          marginBottom: '10px'
        },
        input: {
          border: '1px solid #CDD5DF',
          borderRadius: '10px',
          backgroundColor: '#FFF',
          padding: '23px 14px',
          '@media (max-width: 768px)': {
            padding: '16px 12px',
            fontSize: '14px'
          },
          '&:focus': {
            border: '1px solid #029199',
          },
        },
      })}
      {...props}
    />
  );
});

export default TextInput; 