import { NumberInput as MantineNumberInput } from '@mantine/core';
import { forwardRef } from 'react';

const NumberInput = forwardRef(({ label, error, allowNegative, allowDecimal, ...props }, ref) => {
  return (
    <MantineNumberInput
      ref={ref}
      label={label}
      error={error}
      hideControls={true}
      min={0}
      styles={(theme) => ({
        label: {
          fontSize: '15px',
          fontWeight: '400',
          color: '#364152',
          marginBottom: '10px',
          '@media (max-width: 768px)': {
            fontSize: '13px',
            marginBottom: '6px'
          }
        },
        input: {
          border: '1px solid #CDD5DF',
          borderRadius: '10px',
          backgroundColor: '#FFF',
          padding: '23px 14px',
          '@media (max-width: 768px)': {
            padding: '16px 12px',
            fontSize: '14px'
          },
          '&:focus': {
            border: '1px solid #029199',
          },
        },
      })}
      onKeyDown={(e) => {
        if (
          !/[0-9]/.test(e.key) && 
          !['Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight'].includes(e.key)
        ) {
          e.preventDefault();
        }
        if (!allowNegative && e.key === '-') {
          e.preventDefault();
        }
      }}
      {...props}
    />
  );
});

export default NumberInput; 