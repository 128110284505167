import { useMutation } from "@tanstack/react-query";
import { useHighlight } from "./useHighlight";
import { deleteHighlightPostAnswer } from "../../services/apiHighlight";

export const useDeleteHighlightPostAnswer = ({community_id, highlight_id, post_id,post_tab,label}) => {
    const {invalidateHighlightQueries, handleError, handleSuccess} = useHighlight();
    return useMutation({
        mutationFn: ({community_id, highlight_id, post_id,question_id, answer_id}) => deleteHighlightPostAnswer({community_id, highlight_id, post_id,question_id, answer_id}),
        onSuccess: () => {
            invalidateHighlightQueries(community_id,highlight_id,post_id,"allPost",post_tab,1);
            handleSuccess('Highlight post cavab silindi');
        },
        onError: (error) => handleError(error),
    })
}
