import { useMutation } from "@tanstack/react-query";
import { usePost } from "./usePost";
import { deletePostVideo } from "../../services/apiPost";

export const useDeletePostVideo = (community_id,post_id) => {
    const {handleError, handleSuccess, invalidatePostQueries} = usePost();

    return useMutation({
        mutationFn: ({community_id,post_id,video_id}) => deletePostVideo({community_id,post_id,video_id}),
        onSuccess: () => {
            invalidatePostQueries(community_id,post_id);
            handleSuccess("Video uğurla silindi");
        },
        onError: (error) => handleError(error),
    })
}
