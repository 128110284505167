import React, { forwardRef } from "react";
import { Button as MantineButton } from "@mantine/core";
import { Link } from "react-router-dom";

const Button = forwardRef(({
  children,
  variant = "primary",
  backgroundColor,
  to,
  onClick,
  disabled = false,
  loading = false,
  color,
  padding,
  type = "button",
  borderRadius,
  border,
  ...props
}, ref) => {
  const getVariantProps = () => {
    switch (variant) {
      case "primary":
        return {
          variant: "filled",
          color: "#323922",
        };
      case "cancel":
        return {
          variant: "outline",
          color: "gray",
        };
      case "outline":
        return {
          variant: "outline",
          color: "#FE6513",
        };
      case "secondary":
        return {
          variant: "outline",
          color: "blue",
        };
      default:
        return {
          variant: "filled",
          color: "lime",
        };
    }
  };

  const buttonProps = {
    ...getVariantProps(),
    disabled: disabled || loading,
    loading: loading,
    loaderProps: {
      size: "sm",
      color: "gray",
    },
    type,
    onClick,
    ref,
    style: backgroundColor ? { backgroundColor, color: color } : undefined,
    styles: (theme) => ({
      root: {
        padding: padding || "12px 48px",
        borderRadius: borderRadius || "26px",
        color: color,
        border: border,
        height: "auto",
        lineHeight: "1.5",
        fontWeight: "500",
        fontSize: "15px",
        "@media (max-width: 768px)": {
          padding: padding || "8px 24px",
          fontSize: "13px",
          borderRadius: borderRadius || "16px",
        },
        "&[data-disabled]": {
          backgroundColor: "#E9ECEF !important",
          color: "#909296 !important",
          border: "none !important",
          cursor: "not-allowed !important",
        },
      },
    }),
    ...props,
  };

  if (to) {
    return (
      <MantineButton component={Link} to={to} {...buttonProps}>
        {children}
      </MantineButton>
    );
  }

  return <MantineButton {...buttonProps}>{children}</MantineButton>;
});

Button.displayName = 'Button';

export default Button;
