import { useMutation } from "@tanstack/react-query";
import { pinHighlightPost } from "../../services/apiHighlight";
import { useHighlight } from "./useHighlight";

export const usePinHighlightPost = ({community_id,highlightId,post_id,post_tab,label}) => {
    const {invalidateHighlightQueries,handleSuccess,handleError} = useHighlight();

    return useMutation({
        mutationFn: ({community_id,highlightId,post_id}) => pinHighlightPost({community_id,highlightId,post_id}),
        onSuccess: () => {
            invalidateHighlightQueries(community_id,highlightId,post_id,"allPost",post_tab,label);
            handleSuccess('Post uğurla pinlendi');
        },
        onError: (error) => handleError(error),
    });

}