import React, { useRef, forwardRef } from 'react';
import { Group, Stack, Text } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { IconUpload, IconX, IconPhoto } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

const DropzoneInput = forwardRef(({ 
  onDrop, 
  value, 
  onChange, 
  error, 
  maxSize = 5 * 1024 * 1024,
  accept = { 
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
  },
  styles
}, ref) => {
  const dropzoneRef = useRef(null);
  const { t } = useTranslation();

  const removeFile = () => {
    onChange(null);
  };

  return (
    <div>
      <Dropzone
        ref={ref}
        onDrop={onDrop}
        accept={accept}
        maxFiles={1}
        maxSize={maxSize}
        styles={{
          root: { 
            border: error ? '1px solid red' : '1px solid #CDD5DF',
            borderRadius: '10px',
            backgroundColor: '#FFF',
            '@media (maxWidth: 768px)': {
              minHeight: '100px'
            },
            '&:hover': {
              borderColor: '#029199'
            },
            ...(styles?.root || {}),
          }
        }}
      >
        {!value ? (
          <Stack justify="center" align="center" spacing="lg" style={{ 
            minHeight: '120px', 
            '@media (maxWidth: 768px)': {
              minHeight: '100px'
            }, 
            pointerEvents: 'none' 
          }}>
            <Dropzone.Accept>
              <IconUpload size={32} stroke={1.5} color="#029199" />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <IconX size={32} stroke={1.5} color="#ff0000" />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <IconPhoto size={32} stroke={1.5} color="#666" />
            </Dropzone.Idle>

            <div>
              <Text align="center" size={14} weight={500} color="#475467" inline
                sx={{
                  '@media (maxWidth: 768px)': {
                    fontSize: '12px'
                  }
                }}>
                <span style={{ marginRight: 4, color: '#F79009' }}>
                  {t("click-to-upload")}
                </span>
                {t("or")} {t("drag-and-drop")}
              </Text>
              <Text align="center" size={12} color="#475467" inline mt={7}
                sx={{
                  '@media (maxWidth: 768px)': {
                    fontSize: '11px',
                    marginTop: 5
                  }
                }}>
                {t("file-format")}
              </Text>
            </div>
          </Stack>
        ) : (
          <div style={{ 
            position: 'relative', 
            padding: '12px',
            pointerEvents: 'none'
          }}>
            <Text size="sm">{value.name}</Text>
            <IconX
              style={{ 
                position: 'absolute', 
                right: '10px', 
                top: '50%', 
                transform: 'translateY(-50%)', 
                cursor: 'pointer',
                color: '#666',
                pointerEvents: 'auto'
              }}
              onClick={(e) => {
                e.stopPropagation();
                removeFile();
              }}
            />
          </div>
        )}
      </Dropzone>
    </div>
  );
});

// Add display name for better debugging
DropzoneInput.displayName = 'DropzoneInput';

export default DropzoneInput; 