import React from "react";
import { Card, Skeleton, Group } from "@mantine/core";

const CommunityCardSkeleton = () => {
  return (
    <Card
      radius="21px"
      withBorder
      sx={{
        backgroundColor: "white",
        marginTop: "10px",
      }}
    >
      <Card.Section>
        <Skeleton height={69} radius="21px 21px 0 0" />
      </Card.Section>

      <div style={{ padding: "18px 0px 0px 0px" }}>
        {/* Community name skeleton */}
        <Skeleton height={16} width="70%" mb={8} />

        {/* Description skeleton */}
        <Skeleton height={14} width="100%" mb={8} />
        <Skeleton height={14} width="90%" mb={8} />

        {/* Members count skeleton */}
        <Group spacing={6} mt={6}>
          <Skeleton height={14} width={100} />
        </Group>

        {/* Button skeleton */}
        <Skeleton height={36} mt={10} radius={12} />
      </div>
    </Card>
  );
};

export default CommunityCardSkeleton;
