import React, { useState } from "react";
import PostLayout from "./PostLayout";
import { PollPostStyled } from "../../style";
import checkIcon from "../../../../Assets/SVGs/community/post/check-icon.svg";
import { useParams } from "react-router-dom";
import { useAnsweredToPost } from "../../hooks/post/useAnsweredToPost";
import { useAnswerToHighlightPost } from "../../hooks/highlight/useAnswerToHighlightPost";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";

const dummyData = {
  question: "Which is the best?",
  options: [
    { id: 1, text: "Option #1", votes: 4124 },
    { id: 2, text: "Option #2", votes: 32 },
    { id: 3, text: "Option #3", votes: 64 },
    { id: 4, text: "Option #4", votes: 322 }
  ]
};


const PollPost = ({type,post,postTab,label}) => {
  const { t } = useTranslation();
  const xs = useMediaQuery('(max-width: 768px)');
  const [selectedOption, setSelectedOption] = useState(null);
  const {id: communityId,highlightId} = useParams();
  const { text } = post;
  const question = post?.questions[0];
  const options = question?.community_question_answers_output;
  const totalVotes = options.reduce((sum, option) => sum + option?.votes, 0);
  const {mutate: answeredToPost} = useAnsweredToPost(communityId,type,postTab,label);
  const {mutate: answeredToHighlightPost} = useAnswerToHighlightPost(communityId,highlightId,type,postTab,label);


  const handleVote = (optionId) => {
    if (!question.is_answered) {
      setSelectedOption(optionId);
      // Call API to register vote
      if(highlightId){
        answeredToHighlightPost({
          post_id: post.id,
          question_id: question.id,
          answer_id: optionId
        });
      }else{
        answeredToPost({
          post_id: post.id,
          question_id: question.id,
          answer_id: optionId
        });
      }
    }
  };

  const calculatePercentage = (votes) => {
    return ((votes / totalVotes) * 100).toFixed(1);
  };

  return (
      <PollPostStyled>
        <h3 className="poll-question">{text}</h3>
        <div className="poll-options">
          {options.map((option, index) => (
            <div
              key={option.id}
              className={`poll-option ${(selectedOption === option.id || option.user_answer) ? 'selected' : ''}`}
              onClick={() => handleVote(option.id)}
            >
              {question.is_answered && (
                <div
                  className="progress-bar"
                  style={{ width: `${calculatePercentage(option.votes)}%` }}
                />
              )}
              <div className="option-content">
                <span className={`option-text ${(selectedOption === option.id || option.user_answer) ? 'selected-text' : ''}`}>
                  {`${index + 1}. `}
                  {option?.answer_text}
                  {(selectedOption === option.id || option.user_answer) && <img src={checkIcon} alt="check" className="check-icon" />}
                  </span>
                <div className={`option-stats ${question.is_answered ? 'visible' : ''}`}>
                  <span className="votes-count">{option.votes} {t("vote-count")}</span>
                  <span className="percentage">{calculatePercentage(option.votes)}%</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={`total-votes ${question.is_answered ? 'visible' : ''}`}>
          {t("total-answers")}: {totalVotes}
        </div>
      </PollPostStyled>
  );
};

export default PollPost;
