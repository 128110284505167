import { useQuery } from "@tanstack/react-query"
import { getAllReactions } from "../../../services/apiPost"

export const useGetAllReactions = (community_id, post_id) => {

    return useQuery({
        queryKey: ['all-reactions', community_id, post_id],
        queryFn: () => getAllReactions({community_id, post_id}),
    })
}
