import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAwardBadge } from "../../services/apiBadges";


export const useCreateAwardBadge = (id,setActiveBadgeTable) => {

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (data) => createAwardBadge(id,data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['awardBadges', id] });
            setActiveBadgeTable(false);
        },
        onError: (error) => {
            console.log(error);
        },
    });
};
