import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useInView } from "framer-motion";
import { ReactComponent as AboutSvg } from "../../../Assets/SVGs/landing/about.svg";

const AnimatedSvgWrapper = styled(motion.div)`
  animation: float 3s ease-in-out infinite;

  @keyframes float {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
`;

const About = ({ props }) => {
  const { t } = props;

  const aboutSectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      // Əgər ThirdPart hissəsi ekranın üstündə görünürsə isVisible'i true edirik
      const top = aboutSectionRef.current.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      setIsVisible(top < windowHeight);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const underTextVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.1,
        duration: 0.2,
      },
    },
  };
  const leftVariants = {
    hidden: { opacity: 0, x: -100 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
        duration: 0.5,
      },
    }),
  };

  const rightVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.2,
        duration: 0.5,
      },
    }),
  };

  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  return (
    <AboutSectionStyled ref={aboutSectionRef}>
      <motion.div
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={underTextVariants}
      >
        <Title >{t("our-history")}</Title>
      </motion.div>

      <AboutContainer ref={ref}>
        <motion.div
          custom={0}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={leftVariants}
        >
          <ContentSection>
            <HistoryText as={motion.p}>
              {t("history_text")}
            </HistoryText>

            <AchievementText as={motion.p}>
              {t("achievements_text")}
            </AchievementText>

            <VisionText as={motion.p}>
              {t("vision_text")}
            </VisionText>
          </ContentSection>
        </motion.div>
        <motion.div
          custom={0}
          initial="hidden"
          animate={isVisible ? "visible" : "hidden"}
          variants={rightVariants}
        >
          <ImageSection>
            <AnimatedSvgWrapper>
              <AboutSvg />
            </AnimatedSvgWrapper>
          </ImageSection>
        </motion.div>
      </AboutContainer>
    </AboutSectionStyled>
  );
};

const AboutSectionStyled = styled.div`
  padding: 2rem;
`;

const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr;
  gap: 2rem;
  width: 90%;
  margin: 0px auto;
  padding: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 1rem;
    width: 95%;
    gap: 1rem;
    
    & > div:first-child {
      order: 2;
    }
    
    & > div:last-child {
      order: 1;
    }
  }
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Title = styled.h1`
  color: #1a5059;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 41.051px;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
    margin-top: 40px;
  }
`;

const HistoryText = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #575757;
`;

const AchievementText = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #575757;
`;

const VisionText = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  color: #575757;
`;

const ImageSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: auto;
    max-width: 500px;
  }

  @media (max-width: 768px) {
    margin-bottom: 1rem;
    
    svg {
      max-width: 300px;
    }
  }
`;

export default About;
