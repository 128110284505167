import React from 'react'
import { CategoryTagStyled } from '../../style'
import { Text } from '@mantine/core'

const CategoryTag = ({ category, isSelected, onClick }) => {
  return (
    <CategoryTagStyled 
      className={isSelected ? 'active' : ''}
      onClick={onClick}
    >
      <div 
        dangerouslySetInnerHTML={{ __html: category.svg_icon }} 
        style={{ width: '24px', height: '24px' }}
      />
      <Text size="16px" color="#393838" weight={500}>
        {category.name}
      </Text>
    </CategoryTagStyled>
  )
}

export default CategoryTag