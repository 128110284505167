import { useMutation } from "@tanstack/react-query";
import { useHighlight } from "./useHighlight";
import { updateHighlightPost } from "../../services/apiHighlight";


export const useUpdateHighlightPost = ({closeModal, resetStates,community_id,highlight_id,post_tab,label}) => {
    const {invalidateHighlightQueries, handleError, handleSuccess} = useHighlight();
    return useMutation({
        mutationFn: ({community_id, highlight_id,post_id, data}) => updateHighlightPost({community_id, highlight_id,post_id, data}),
        onSuccess: () => {
            invalidateHighlightQueries(community_id,highlight_id,null,"allPost",post_tab,null);
            handleSuccess('Post redaktəlendi');
            resetStates();
            closeModal();
        },
        onError: (error) => handleError(error),
    })
}
