import { useQuery, useQueries } from "@tanstack/react-query";
import { getCommentReactions } from "../../../../services/apiPost";

export const useGetAllCommentReactions = (communityId,postId,allCommentIds) => {
    const queries = useQueries({
        queries: allCommentIds.map(commentId=>({
            queryKey: ['all-comment-reactions', communityId, postId, commentId],
            queryFn: () => getCommentReactions({community_id: communityId, post_id: postId, comment_id: commentId}),
        }))
    })
    return queries
}