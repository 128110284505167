import { useInfiniteQuery } from "@tanstack/react-query"
import { getSavedPosts } from "../../services/apiPost"
import { usePost } from "./usePost";

export const useGetAllSavePosts = (community_id, type,postTab,label) => {
    const { queryKeys } = usePost();
    return useInfiniteQuery({
        queryKey: queryKeys.savedPosts(community_id,postTab='posts',label=null),
        queryFn: ({ pageParam = 1 }) => getSavedPosts({
            community_id,
            page: pageParam
        }),
        getNextPageParam: (lastPage) => {
            if (lastPage.next) {
                const nextPage = new URLSearchParams(new URL(lastPage.next).search).get('page');
                return nextPage;
            }
            return undefined;
        },
    });
};
