import { showNotification } from "@mantine/notifications";
import { useQueryClient } from "@tanstack/react-query";


export const useHighlight = () => {
  const queryClient = useQueryClient();

  const queryKeys = {
    all: (community_id,page) => ['highlights', community_id,page],
    detail: (community_id,highlight_id) => ['highlight', community_id, highlight_id],
    allPost: (community_id,highlight_id,postTab,label) => ['highlight-posts', community_id, highlight_id,postTab,label],
    detailPost: (community_id,highlight_id,post_id,type) => ['highlight-post', community_id, highlight_id, post_id,type],
  }

  const invalidateHighlightQueries = (communityId,highlightId,postId,type,postTab,label) => {
    if(communityId && highlightId && postId){
      queryClient.invalidateQueries({ queryKey: queryKeys.detailPost(communityId,highlightId,postId,"highlight") });
    } else if (communityId && highlightId && type === "detail"){
      queryClient.invalidateQueries({ queryKey: queryKeys.detail(communityId,highlightId) });
    } else if (communityId && highlightId && type === "allPost"){
      queryClient.invalidateQueries({ queryKey: queryKeys.allPost(communityId,highlightId,postTab,label)});
    } else if (communityId){
      queryClient.invalidateQueries({ queryKey: queryKeys.all(communityId) });
    }
  }

  const handleError = (error) => {
    showNotification({
      title: 'Xəta',
      message: error?.message || 'Xəta baş verdi',
      color: 'red',
    });
  }

  const handleSuccess = (message) => {
    showNotification({
      title: 'Uğurlu',
      message,
      color: 'green',
    });
  }

  return {
    queryKeys,
    invalidateHighlightQueries,
    handleError,
    handleSuccess,
  }
}