import { useMutation } from "@tanstack/react-query";
import { usePost } from "./usePost";
import { createPost } from "../../services/apiPost";

export const useCreatePost = ({closeModal, resetStates,community_id,post_tab,label}) => {
    const {invalidatePostQueries, handleSuccess, handleError} = usePost();

    return useMutation({
        mutationFn: ({id, data}) => {
            return createPost({id, data});
        },
        onSuccess: (data,variables) => {
            invalidatePostQueries(community_id,null,"posts",null,1,post_tab,null);
            handleSuccess('Post uğurla yaradıldı');
            resetStates();
            closeModal();
        },
        onError: (error) => handleError(error),
    });
};
    
