import { useMutation } from "@tanstack/react-query";
import { createHighlightPost } from "../../services/apiHighlight";
import { useHighlight } from "./useHighlight";


export const useCreateHighlightPost = ({closeModal, resetStates,community_id,highlight_id,post_tab,label}) => {
    const {invalidateHighlightQueries, handleError, handleSuccess} = useHighlight();
    return useMutation({
        mutationFn: ({community_id, highlight_id, data}) => createHighlightPost({community_id, highlight_id, data}),
        onSuccess: () => {
            invalidateHighlightQueries(community_id,highlight_id,null,"allPost",post_tab,null);
            handleSuccess('Post yaradıldı');
            resetStates();
            closeModal();
        },
        onError: (error) => handleError(error),
    })
}
