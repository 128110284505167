import React, { useState, useEffect } from "react";
import { Group, Modal, Stack, Text, Image, Button as MantineButton, Paper } from "@mantine/core";
import { useForm, Controller } from "react-hook-form";
import TextInput from "../../../../Components/FormComponent/TextInput";
import TextAreaInput from "../../../../Components/FormComponent/TextAreaInput";
import SelectInput from "../../../../Components/FormComponent/SelectInput";
import Button from "../../../../Components/FormComponent/Button";
import DropzoneInput from "../../../../Components/FormComponent/DropzoneInput";
import { IconStarFilled, IconEye, IconUpload } from "@tabler/icons-react";
import { useCreateHighlight } from "../../hooks/highlight/useCreateHighlight";
import { useMediaQuery } from "@mantine/hooks";
import { useGetHighlight } from "../../hooks/highlight/useGetHighlight";
import { useUpdateHighlight } from "../../hooks/highlight/useUpdateHighlight";
import { useTranslation } from "react-i18next";

const ModalCreateHighlight = ({
  firstTime,
  communityId,
  highlight_id,
  opened,
  onClose,
  onShowNoticiationModal
}) => {
  const { t } = useTranslation();
  const [imageFile, setImageFile] = useState(null);
  const [previewOpened, setPreviewOpened] = useState(false);
  const [previewUrl, setPreviewUrl] = useState("");
  const { mutate: createHighlight, isLoading } = useCreateHighlight(
    communityId,
    firstTime,
    onClose,
    onShowNoticiationModal
  );
  const { data: highlight } = useGetHighlight(communityId, highlight_id);
  
  const {mutate : updateHighlight} = useUpdateHighlight(communityId, highlight_id,onClose);
  
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      only_me: "other",
      image: "",
      is_active: true,
    },
  });

  useEffect(() => {
    if (highlight) {
      reset({
        name: highlight.name,
        description: highlight.description,
        only_me: highlight.only_me ? "me" : "other",
        image: highlight.image,
        is_active: highlight.is_active,
      });
      
      if (highlight.image) {
        setImageFile({ name: "Existing Image" });
        setPreviewUrl(`https://taskilled.blob.core.windows.net/taskool-store/${highlight.image}`);
      }
    }
  }, [highlight, reset]);

  const handleDrop = (files) => {
    const file = files[0];

    // Convert to base64
    const reader = new FileReader();
    reader.onloadend = () => {
      setValue("image", reader.result);
      setPreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);

    setImageFile(file);
  };


  const handleChangeClick = () => {
    const dropzoneElement = document.querySelector('.mantine-Dropzone-root');
    if (dropzoneElement) {
      dropzoneElement.click();
    }
  };

  const onSubmit = (data) => {
    data.only_me = data.only_me === "me" ? true : false;
    
    if (highlight_id) {
      // Update əməliyyatı
      updateHighlight({
        community_id: communityId,
        highlight_id: highlight_id,
        data: {
          ...data,
          image: data.image.startsWith('data:image') ? data.image : highlight?.image || data.image
        }
      });
    } else {
      // Create əməliyyatı
      createHighlight({
        ...data,
        image: data.image
      });
    }
  };

  const xs = useMediaQuery("(max-width: 768px)");

  const modalTitle = highlight_id ? t("edit-highlight") : t("create-highlight");
  
  const buttonText = highlight_id ? t("update") : t("create");

  return (
    <>
      <Modal
        opened={opened}
        onClose={onClose}
        title={modalTitle}
        closeOnClickOutside={!firstTime}
        size={xs ? "100%" : "lg"}
        radius={xs ? 8 : 14}
        withCloseButton={!firstTime}
        styles={{
          header: {
            borderBottom: "1px solid #E9ECEF",
            paddingBottom: xs ? "12px" : "16px",
            marginBottom: xs ? "12px" : "16px",
          },
          title: {
            color: "#202939",
            fontSize: xs ? "16px" : "19px",
            fontWeight: 500,
          },
          body: {
            padding: xs ? "6px" : "12px",
          },
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="md">
            <Controller
              name="name"
              control={control}
              rules={{ required: t("highlight-name-required") }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  label={t("highlight-name")}
                  placeholder={t("enter-highlight-name")}
                  error={error?.message}
                  withAsterisk={true}
                  {...field}
                />
              )}
            />

            <Controller
              name="only_me"
              control={control}
              rules={{ required: t("share-permission-required") }}
              render={({ field, fieldState: { error } }) => (
                <SelectInput
                  label={t("share-permission")}
                  placeholder={t("select-option")}
                  data={[
                    { value: "me", label: t("only-me") },
                    { value: "other", label: t("other") },
                  ]}
                  error={error?.message}
                  withAsterisk={true}
                  {...field}
                />
              )}
            />

            <Controller
              name="description"
              control={control}
                rules={{ required: t("description-required") }}
              render={({ field, fieldState: { error } }) => (
                <TextAreaInput
                  label={t("description")}
                  placeholder={t("enter-description")}
                  error={error?.message}
                  minRows={4}
                  withAsterisk={true}
                  {...field}
                />
              )}
            />

            <Controller
              name="image"
              control={control}
              rules={{ required: t("choose-cover-photo-community") }}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <div style={{ marginBottom: xs ? 16 : 24 }}>
                  <Group mb={xs ? 4 : 8} align="center" spacing={4}>
                    <Text size={xs ? 13 : 15} color="#364152" weight={400}>
                      {t("cover-photo")}
                    </Text>
                    <IconStarFilled
                      style={{ marginBottom: xs ? 3 : 6 }}
                      size={xs ? 5 : 6}
                      color="#f65252"
                    />
                  </Group>
                  <Paper
                    padding={xs ? 8 : "xs"}
                    radius={xs ? 8 : "md"}
                    style={{
                      position: 'relative',
                      border: error?.message ? '1px solid #ff0000' : '1px solid #E9ECEF',
                      backgroundColor: '#FAFAFA',
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <DropzoneInput
                        onDrop={handleDrop}
                        value={imageFile}
                        onChange={(value) => {
                          setImageFile(value);
                          if (!value) {
                            setValue("image", "");
                            setPreviewUrl("");
                          }
                        }}
                        error={error?.message}
                        styles={{
                          root: {
                            border: 'none',
                            backgroundColor: 'transparent',
                            minHeight: xs ? 20 : 40,
                          },
                        }}
                      />
                      {previewUrl && (
                        <Group 
                          spacing={xs ? 4 : 8}
                          style={{
                            position: 'absolute',
                            right: xs ? 8 : 10,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            zIndex: 2
                          }}
                        >
                          <MantineButton
                            variant="light"
                            color="gray"
                            radius={xs ? 8 : "md"}
                            size={xs ? "xs" : "sm"}
                            leftIcon={<IconEye size={xs ? 14 : 16} />}
                            onClick={() => setPreviewOpened(true)}
                            styles={{
                              root: {
                                padding: xs ? '0 8px' : undefined,
                                height: xs ? 28 : undefined,
                                '&:hover': {
                                  backgroundColor: '#E9ECEF',
                                },
                              },
                            }}
                          >
                            {xs ? '' : t("preview")}
                          </MantineButton>
                          <MantineButton
                            variant="light"
                            color="blue"
                            radius={xs ? 8 : "md"}
                            size={xs ? "xs" : "sm"}
                            leftIcon={<IconUpload size={xs ? 14 : 16} />}
                            onClick={handleChangeClick}
                            styles={{
                              root: {
                                padding: xs ? '0 8px' : undefined,
                                height: xs ? 28 : undefined,
                                '&:hover': {
                                  backgroundColor: '#E7F5FF',
                                },
                              },
                            }}
                          >
                            {t("change")}
                          </MantineButton>
                        </Group>
                      )}
                    </div>
                    {error?.message && (
                      <Text size={xs ? 11 : "xs"} color="red" mt={xs ? 2 : 4}>
                        {error.message}
                      </Text>
                    )}
                  </Paper>
                </div>
              )}
            />

            <Button
              onClick={handleSubmit(onSubmit)}
              variant="primary"
              backgroundColor="#CCF075"
              color="#323922"
              loading={isLoading}
              disabled={isLoading}
            >
              {buttonText}
            </Button>
          </Stack>
        </form>
      </Modal>

      {/* Preview Modal */}
      <Modal
        opened={previewOpened}
        onClose={() => setPreviewOpened(false)}
        title={t("image-preview")}
        size={xs ? "100%" : "xl"}
        radius={xs ? 8 : "md"}
        padding={xs ? "md" : "lg"}
        styles={{
          header: {
            marginBottom: xs ? '16px' : '20px',
          },
          title: {
            fontWeight: 600,
            fontSize: xs ? '16px' : '18px',
          },
        }}
      >
        <Paper 
          shadow="sm" 
          radius={xs ? 8 : "md"}
          p={xs ? "sm" : "md"}
          style={{
            backgroundColor: '#FAFAFA',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            src={previewUrl}
            alt="Preview"
            fit="contain"
            height={xs ? 300 : 400}
            radius={xs ? 8 : "md"}
            withPlaceholder
            placeholder={
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <Text color="dimmed">{t("loading-image")}</Text>
              </div>
            }
          />
        </Paper>
      </Modal>
    </>
  );
};

export default ModalCreateHighlight;
