import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reactToCommunityPost } from "../../../services/apiPost";
import { usePost } from "../usePost";

export const useReactToPost = (communityId,postId,highlightId) => {

    const {handleError, invalidatePostQueries} = usePost();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({postId,data}) => reactToCommunityPost({community_id: communityId, post_id: postId,data}),
        onSuccess: () => {
            if(highlightId){
                queryClient.invalidateQueries({ queryKey: ['all-highlight-reactions', communityId, postId,highlightId] });
                queryClient.invalidateQueries({ queryKey: ['highlight-reaction', communityId, postId,highlightId] });
            }else{
                queryClient.invalidateQueries({ queryKey: ['all-reactions', communityId, postId] });
                queryClient.invalidateQueries({ queryKey: ['reactions',postId, communityId] });
            }
        },
        onError: (error) => {
            handleError(error);
        },
    });
};
