import { useQuery } from "@tanstack/react-query";
import { getCollaboratorsByCommuntyId } from "../../services/apiBadges";


export const useGetAllCollaboratorList = (id) => {
    return useQuery({
        queryKey: ["collaborators", id],
        queryFn: () => getCollaboratorsByCommuntyId(id),
    });
}
