import { Tabs } from "@mantine/core";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const CommunitiesStyled = styled.div`
  border-radius: 11px;
  background: #fff;
  padding: 26px 41px 31px 41px;
  @media (max-width: 768px) {
    padding: 26px 25px 31px 20px;
    margin-bottom: 50px;
  }
`;

export const DropDownCategoryStyled = styled.div`
  border-radius: 11px;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 14px 12px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  margin-top: 8px;
  width: 256px;
  min-width: max-content;

  .scroll-container {
    overflow-y: auto;
    max-height: 300px;

    /* Başlangıçta scrollbar'ı gizle */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hover durumunda scrollbar'ı göster */
    &:hover::-webkit-scrollbar {
      display: block;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #029199;
      border-radius: 4px;
      min-height: 20px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }

  /* Text overflow-u idarə etmək üçün yeni stillər */
  .mantine-Text-root {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px; /* və ya başqa bir uyğun dəyər */
  }

  .circle {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #323131;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export const CategoryTagStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px;
  border-radius: 42px;
  border: 1px solid #d9d9d9;
  background: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;

  &:hover {
    border-color: #029199;
    background: rgba(2, 145, 153, 0.1);
  }

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  &.active {
    border: 2px solid #029199;
    background: rgba(2, 145, 153, 0.1);
  }
`;

export const CoverPhotoStyled = styled.div`
  max-width: 65%;
  margin: 0 auto 50px auto;
  background: #fff;
  border-radius: 11px;
  .cover-photo-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f8fafd;
    padding: 80px 0;
    width: 100%;
  }
  @media (max-width: 768px) {
    max-width: 95%;
    padding: 0;
  }
`;

export const CoverCardImageStyled = styled.div`
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  background: #f8fafd;
  padding: 20px 2px 16px 2px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;

  &.active {
    border: 3px solid #029199;
  }

  .check-circle {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #029199;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CommunityTabsStyled = styled.div`
  .mantine-Tabs-root {
    width: 100%;
  }

  .mantine-Tabs-tabsList {
    margin-top: 37px;
    margin-bottom: 20px;
    border-bottom: none;
    display: flex;
    align-items: center;
    gap: 15px;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: row;
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      padding-bottom: 5px;

      /* Başlanğıcda scroll'u gizlət */
      &::-webkit-scrollbar {
        display: none;
      }

      /* Hover zamanı scroll'u göstər */
      &:hover {
        &::-webkit-scrollbar {
          display: block;
          height: 4px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
          border-radius: 4px;
        }
      }
    }
  }

  .mantine-Tabs-tab {
    color: #a8aeb4;
    font-size: 17px;
    font-weight: 500;
    padding: 0 0 8px 0;
    white-space: nowrap;
    flex-shrink: 0;

    &[data-active] {
      color: #029199;
      border-bottom: 3px solid #029199;
      width: fit-content;

      &:hover {
        border-bottom: 3px solid #029199;
      }
    }

    &:hover {
      background-color: #f5f5f5;
      transition: background-color 0.2s ease;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
`;

export const CommunityHeaderStyled = styled.div`
  .mantine-21wjnm:hover {
    background-color: #fff;
  }
  .mantine-Accordion-item.mantine-1dl71fz {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .mantine-Menu-dropdown.mantine-1gt7cpf {
    top: 46px !important;
    left: -78px !important;
    border-radius: 11px;
    box-shadow: 1px -1px 24px 0px rgba(0, 0, 0, 0.2);
  }

  .community-header-accordion-control {
    display: flex;
    align-items: flex-start;

    .mantine-1h6pkea {
      margin-top: 6px;
    }
  }
  .top-side {
    border-radius: 6px 6px 0 0;
    width: 100%;
    height: 155px;
    position: relative;
    object-fit: cover;
    @media (max-width: 768px) {
      height: 104px;
    }

    img {
      border-radius: 6px 6px 0 0;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .bottom-side {
    padding: 17px 26px 0px 34px;
    background: #fff;
    .button-groups {
      display: flex;
      align-items: center;
      gap: 10px;
      .bell-circle {
        width: 43px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #eee;
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ResponsiveCommunityHeaderStyled = styled.div`
  display: none;
  .mantine-21wjnm:hover {
    background-color: #fff;
  }
  .mantine-Accordion-item.mantine-1dl71fz {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .mantine-Menu-dropdown.mantine-1gt7cpf {
    top: 46px !important;
    left: -78px !important;
    border-radius: 11px;
    box-shadow: 1px -1px 24px 0px rgba(0, 0, 0, 0.2);
  }

  .community-header-accordion-control {
    display: flex;
    align-items: flex-start;

    .mantine-1h6pkea {
      margin-top: 6px;
    }
  }
  .top-side {
    border-radius: 6px 6px 0 0;
    width: 100%;
    height: 155px;
    position: relative;
    object-fit: cover;
    @media (max-width: 768px) {
      height: 104px;
    }

    img {
      border-radius: 6px 6px 0 0;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
  .bottom-side {
    padding: 17px 26px 0px 34px;
    background: #fff;
    .button-groups {
      display: flex;
      align-items: center;
      gap: 10px;
      .bell-circle {
        width: 43px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #eee;
      }
    }
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

export const StyledTabs = styled(Tabs)`
  margin-top: 50px;
  .mantine-Tabs-tabsList {
    border-bottom: none;
    display: flex;
    justify-content: space-around;
    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }

  .mantine-Tabs-tab {
    font-weight: 500;
    font-size: 17px;
    padding: 12px 24px;
    color: #a8aeb4;
    @media (max-width: 768px) {
      padding: 12px 2px;
    }

    &[data-active] {
      color: #029199;
      border-color: #029199;
      border-bottom: 3px solid #029199;
    }

    &:hover {
      background-color: transparent;
      border-color: #029199;
    }
  }
  @media (max-width: 768px) {
    background-color: #fff;
    padding: 19px 20px 0 20px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-top: 10px;
  }
`;

export const HighlightCarouselStyled = styled.div`
  margin-top: 2px;
  padding: 17px 0px 25px 40px;
  background: #fff;

  .highlight-swiper {
    padding: 10px 0;

    .swiper-slide {
      width: auto;
      max-width: 300px;
      transition: transform 0.3s ease;
    }
  }

  /* Mobil responsive */
  @media (max-width: 768px) {
    padding: 17px 15px 25px 15px;
  }

  .highlight-scroll-container {
    display: flex;
    gap: 40px;
    overflow-x: auto;
    padding-bottom: 10px;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .highlight-scroll-container::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

export const HighlightCardStyled = styled(Link)`
  background: #fff;
  border-radius: 7px;
  border: 1px solid #b8b8b8;
  padding: 7px 20px 7px 8px;
  width: 100%;
  max-width: 300px;
  display: block;
  @media (max-width: 768px) {
    padding: 7px 15px 7px 6px;
    width: 100%;
  }

  .card-content {
    display: flex;
    gap: 12px;
  }

  .image-container {
    width: 122px;
    height: 108px;
    border-radius: 7px;
    overflow: hidden;
    flex-shrink: 0;

    @media (max-width: 768px) {
      width: 100px;
      height: 88px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .text-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h3 {
      color: #393838;
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 23px;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    .meta-info {
      color: #8f8f8f;
      font-size: 14px;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 12px;
      }

      .line {
        width: 100%;
        height: 1px;
        background: #d2d2d2;
        margin: 9px 0;
        @media (max-width: 768px) {
          margin: 7px 0;
        }
      }
      p {
        margin: 0;
      }
    }
  }
`;

export const CommunityPanelStyled = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const MainPanelContainer = styled.div`
  width: 67%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AboutPanelContainer = styled.div`
  width: 28%;
`;

export const PostListStyled = styled.div`
  margin-top: 2px;
  padding: 17px 40px 25px 40px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    padding: 17px 15px 25px 15px;
  }
`;

export const PostLayoutStyled = styled.div`
  background: #fff;
  border-radius: 6px;
  border: 1px solid #dbe4e7;
  padding: 18.5px 20px 10px 20px;
  @media (max-width: 768px) {
    padding: 18.5px 15px 10px 15px;
  }
  .line {
    width: 100%;
    height: 1px;
    background: #dbe4e7;
    margin: 15px 0;
  }
  .svg-icon {
    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const ImagePostStyled = styled.div`
  .post-text {
    margin-bottom: 16px;
    font-size: 14px;
    color: #4a4a4a;
  }

  .image-grid {
    display: grid;
    gap: 4px;
    border-radius: 8px;
    overflow: hidden;

    &.single-image {
      grid-template-columns: 1fr;

      .image-container {
        aspect-ratio: 16/9;
        max-height: 400px;
      }
    }

    &.two-images {
      grid-template-columns: 1fr 1fr;

      .image-container {
        aspect-ratio: 1;
      }
    }

    &.three-plus-images {
      grid-template-columns: 1.1fr 1fr;
      grid-template-rows: 200px 200px;

      .image-container:first-child {
        grid-row: 1 / span 2;
      }
    }
  }

  .image-container {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.has-overlay {
      position: relative;
      cursor: pointer;

      &:hover .remaining-overlay {
        background: rgba(0, 0, 0, 0.6);
      }
    }

    .remaining-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.2s;

      span {
        color: white;
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
`;

export const VideoPostStyled = styled.div`
  .post-text {
    margin: 0 0 15px 0;
    color: #202020;
    font-size: 15px;
    line-height: 1.5;
  }

  .video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 15px;
    cursor: pointer;
    background: #000;

    .video-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.3s ease;
      z-index: 2;

      &:hover {
        background: rgba(0, 0, 0, 0.6);
      }

      .play-icon {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: transform 0.3s ease;

        img {
          width: 100%;
          height: 100%;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .video-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
  }
`;

export const PollPostStyled = styled.div`
  .poll-question {
    margin: 0 0 20px 0;
    color: #364152;
    font-size: 18px;
    font-weight: 600;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .poll-options {
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media (max-width: 768px) {
      gap: 8px;
    }
  }

  .poll-option {
    position: relative;
    padding: 12px 16px;
    border-radius: 8px;
    background: rgba(2, 145, 153, 0.05);
    border: 1px solid #029199;
    cursor: pointer;
    transition: all 0.2s ease;
    overflow: hidden;
    @media (max-width: 768px) {
      padding: 10px 12px;
    }

    &:hover {
      border-color: #029199;
    }

    .option-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      z-index: 2;
    }

    .option-text {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #029199;
      font-size: 14px;
      font-weight: 700;
      @media (max-width: 768px) {
        font-size: 13px;
      }

      .check-icon {
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      &.selected-text .check-icon {
        opacity: 1;
      }
    }

    .option-stats {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #029199;
      font-size: 14px;
      font-weight: 500;
      opacity: 0;
      transition: opacity 0.3s ease;
      @media (max-width: 768px) {
        font-size: 13px;
      }

      .votes-count {
        font-weight: 500;
        font-size: 12px;
        @media (max-width: 768px) {
          font-size: 11px;
        }
      }
      .percentage {
        font-weight: 400;
        font-size: 14px;
        color: #029199;
        @media (max-width: 768px) {
          font-size: 13px;
        }
      }
      &.visible {
        opacity: 1;
      }
    }

    .progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background: rgba(2, 145, 153, 0.1);
      transition: width 0.8s ease;
      z-index: 1;
    }
  }

  .total-votes {
    margin-top: 16px;
    color: #666;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s ease;
    @media (max-width: 768px) {
      font-size: 13px;
    }

    &.visible {
      opacity: 1;
    }
  }
`;

export const QuizPostStyled = styled.div`
  .quiz-question {
    margin: 0 0 20px 0;
    color: #364152;
    font-size: 18px;
    font-weight: 600;
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  .quiz-options {
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media (max-width: 768px) {
      gap: 8px;
    }
  }

  .quiz-option {
    position: relative;
    padding: 12px 16px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #dbe4e7;
    cursor: pointer;
    transition: all 0.2s ease;
    overflow: hidden;
    @media (max-width: 768px) {
      padding: 10px 12px;
    }

    &:hover {
      border-color: #029199;
      background: rgba(2, 145, 153, 0.05);
    }

    .option-content {
      display: flex;
      align-items: center;
      gap: 12px;
      position: relative;
      z-index: 2;
      @media (max-width: 768px) {
        gap: 8px;
      }
    }

    .radio-button {
      width: 20px;
      height: 20px;
      border: 2px solid #dbe4e7;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;
      @media (max-width: 768px) {
        width: 16px;
        height: 16px;
      }

      &::after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #029199;
        opacity: 0;
        transition: opacity 0.2s ease;
        @media (max-width: 768px) {
          width: 8px;
          height: 8px;
        }
      }

      &.selected::after {
        opacity: 1;
      }
    }

    .option-text {
      color: #364152;
      font-size: 15px;
      @media (max-width: 768px) {
        font-size: 13px;
      }
    }

    &.correct {
      background: #dbf4ba;
      border-color: #029199;
    }

    &.incorrect {
      background: #9292920d;
      border-color: #dfdfdf;
    }

    .option-stats {
      position: absolute;
      right: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
      color: #029199;
      font-size: 14px;
      opacity: 0;
      transition: opacity 0.3s ease;
      @media (max-width: 768px) {
        font-size: 13px;
      }

      &.visible {
        opacity: 1;
      }
    }

    .progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      transition: width 0.8s ease;
      z-index: 1;
    }

    &.correct {
      border-color: #a7e05a;

      .progress-bar {
        background: #a7e05a;
      }
    }

    &.incorrect {
      border-color: #9292920d;
      .progress-bar {
        background: #fe651399;
      }
    }
  }

  .total-answers {
    margin-top: 16px;
    color: #666;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s ease;
    @media (max-width: 768px) {
      font-size: 13px;
    }

    &.visible {
      opacity: 1;
    }
  }
`;

export const CreatePostSideStyled = styled.div`
  background: #fff;
  border-radius: 6px;
  padding: 18.5px 20px 10px 20px;
  margin: 15px 0;
  @media (max-width: 768px) {
    padding: 17px 15px 25px 15px;
  }
  .create-post-input {
    padding: 5px 10px;
    border-radius: 23px;
    border: 1px solid #b8b8b8;
    background: #fff;
    input {
      border: none;
      font-size: 16px;
      font-weight: 500;
      color: #b8b8b8;
    }
    @media (max-width: 768px) {
      padding: 1px 8px;
      border-radius: 20px;
      input {
        font-size: 14px;
      }
    }
  }
`;

export const ModalPostLayoutStyled = styled.div`
  .media-preview {
    margin-top: 16px;

    .media-item {
      position: relative;
      width: 100%;
      padding-top: 56.25%;
      margin-bottom: 8px;
      border-radius: 8px;
      overflow: hidden;
      background: #000;

      &.selected {
        border: 2px solid #029199;
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .video-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        iframe,
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .video-container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .upload-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    gap: 8px;
  }
`;

export const MediaPostStyled = styled.div`
  .post-text {
    margin-bottom: 16px;
    font-size: 14px;
    color: #4a4a4a;
    @media (max-width: 768px) {
      font-size: 13px;
    }
  }

  .media-grid {
    display: grid;
    gap: 4px;
    border-radius: 8px;
    overflow: hidden;

    &.single-image {
      grid-template-columns: 1fr;

      .media-container {
        max-height: 400px;
        @media (max-width: 768px) {
          max-height: 300px;
        }
      }
    }

    &.two-images {
      grid-template-columns: 1fr 1fr;

      .media-container {
        aspect-ratio: 1;
        @media (max-width: 768px) {
          max-height: 300px;
        }
      }
    }

    &.three-plus-images {
      grid-template-columns: 1.1fr 1fr;
      grid-template-rows: 200px 200px;
      @media (max-width: 768px) {
        grid-template-rows: 100px 100px;
      }

      .media-container:first-child {
        grid-row: 1 / span 2;
      }
    }
  }

  .media-container {
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.has-overlay {
      position: relative;
      cursor: pointer;

      &:hover .remaining-overlay {
        background: rgba(0, 0, 0, 0.6);
      }
    }

    .video-preview {
      position: relative;
      width: 100%;
      height: 100%;
      background: #000;
      cursor: pointer;

      .video-thumbnail {
        width: 100%;
        height: 100%;
        position: relative;

        .thumbnail-background {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.2);
        }

        &.thumbnail-error {
          background: #2a2a2a;
          display: flex;
          align-items: center;
          justify-content: center;

          &::after {
            content: "Video Preview";
            color: #ffffff;
            font-size: 14px;
            @media (max-width: 768px) {
              font-size: 13px;
            }
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .play-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.3);
        transition: background 0.3s ease;

        &:hover {
          background: rgba(0, 0, 0, 0.5);
        }

        .play-icon {
          width: 48px;
          height: 48px;
          transition: transform 0.3s ease;
          @media (max-width: 768px) {
            width: 38px;
            height: 38px;
          }

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .remaining-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background 0.2s;

      span {
        color: white;
        font-size: 24px;
        font-weight: 600;
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
    }
  }

  .thumbnail-placeholder {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const CustomSelectStyled = styled.div`
  position: relative;

  .select-header {
    padding: 12px;
    border-radius: 8px;
    background: rgba(2, 145, 153, 0.1);
    color: #029199;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid #029199;
  }

  .select-dropdown {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    overflow: hidden;
  }

  .select-item {
    padding: 10px 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #029199;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 14px;

    svg {
      color: #029199;
      margin-top: 4px;
    }

    &:hover {
      background: rgba(2, 145, 153, 0.05);
    }

    &.selected {
      background: rgba(2, 145, 153, 0.1);
      font-weight: 500;
    }
  }
`;

export const StyledInputWrapper = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1.5fr auto;
  align-items: center;
  gap: 16px;
  width: 100%;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
  padding: 18px 14px;

  div {
    font-size: 14px;
    color: #333;
  }

  .actions {
    display: flex;
    gap: 8px;
  }
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 0.9fr 3fr;
  gap: 18px;
  align-items: center;
  padding: 8px 25px 8px 0px;
`;

export const HighlightStyled = styled.div`
  max-width: 90%;
  margin: 20px auto;
  @media (max-width: 768px) {
    margin: 10px auto;
  }
  .highlight-join-container {
    .highlight-join-banner {
      width: 100%;
      height: 155px;
      background: #dbe4e7;
      border-radius: 11px 11px 0px 0px;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
      @media (max-width: 768px) {
        height: 120px;
      }
    }
    .highlight-join-about {
      padding: 33px 27px 21px 37px;
      background: #fff;
      border-radius: 0px 0px 11px 11px;
      .notification-circle {
        width: 47px;
        height: 47px;
        border-radius: 50%;
        background: #eee;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      @media (max-width: 768px) {
        padding: 22px 77px 17px 27px;
        .notification-circle {
          width: 37px;
          height: 37px;
        }
      }
    }
  }
`;

export const AdminAnalyticsStyled = styled.div`
  max-width: 90%;
  margin: 0px auto;
  background: #fff;
  border-radius: 14px;
  padding: 27px 54px 36px 34px;
  @media (max-width:768px) {
    padding: 18px 30px 12px 20px;
  }
`;

export const AdminAnalyticsCardStyled = styled.div`
  border-radius: 14px;
  border: 1px solid #e4e4e4;
  background: #fff;
  box-shadow: 6px 6px 54px 0px rgba(0, 0, 0, 0.05);
  padding: 25px 18px 14px 16px;
  min-width: 268px;

  @media(max-width:768px) {
  padding: 10px 12px 12px 10px;

  }
`;
