import React, { forwardRef, useEffect } from "react";
import { Modal, Stack, Skeleton } from "@mantine/core";
import { useForm, Controller } from "react-hook-form";
import TextInput from "../../../../Components/FormComponent/TextInput";
import NumberInput from "../../../../Components/FormComponent/NumberInput";
import TextAreaInput from "../../../../Components/FormComponent/TextAreaInput";
import SelectInput from "../../../../Components/FormComponent/SelectInput";
import Button from "../../../../Components/FormComponent/Button";
import { useCommunityContext } from "../../context/CommunityContext";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { useGetCommunity } from "../../hooks/community/useGetCommunity";
import { useTranslation } from "react-i18next";

const ModalCreateCommunity = forwardRef(({ opened, onClose, community_id }, ref) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      name: "",
      is_free: "",
      price: 0,
      description: "",
      lang_id: 1,
    },
  });
  const {t} = useTranslation()
  const { setCommunityData } = useCommunityContext();
  const navigate = useNavigate();
  const is_freeWatch = watch("is_free");
  const { data: communityData, isLoading : communityLoading } = useGetCommunity(community_id);

  useEffect(() => {
    if (community_id && communityData) {
      reset({
        name: communityData.name,
        is_free: communityData.is_free ? "free" : "paid",
        price: communityData.price || 0,
        description: communityData.description,
        lang_id: communityData.lang_id,
      });
    }
  }, [communityData, community_id, reset]);

  useEffect(() => {
    if (is_freeWatch === "paid") {
      setValue("price", 1);
    } else {
      setValue("price", 0);
    }
  }, [is_freeWatch, setValue]);

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      is_free: is_freeWatch === "free" ? true : false,
      category: community_id ? communityData?.category : null,
      cover_photo: community_id ? communityData?.cover_photo : null,
    };

    localStorage.setItem("communityData", JSON.stringify(formattedData));
    setCommunityData(formattedData);
    onClose();
    if (community_id) {
      navigate(`/categories/edit`);
    } else {
      navigate(`/categories`);
    }
  };

  const isLoading = (communityLoading && communityData && community_id)

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={community_id ? t("edit-community") : t("create-community")}
      size="lg"
      radius={14}
      styles={{
        header: {
          borderBottom: "1px solid #E9ECEF",
          paddingBottom: "16px",
          marginBottom: "16px",
        },
        title: {
          color: "#202939",
          fontSize: "19px",
          fontWeight: 500,
          '@media (max-width: 768px)': {
            fontSize: '17px',
          },
        },
      }}
    >
      {isLoading ? (
        <Stack spacing="md">
          <Skeleton height={40} radius="sm" />
          <Skeleton height={40} radius="sm" />
          <Skeleton height={40} radius="sm" />
          <Skeleton height={120} radius="sm" />
        </Stack>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing="md">
            <Controller
              name="name"
              control={control}
              rules={{ required: t("community-name-required") }}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  label={t("community-name")}
                  placeholder={t("enter-community-name")}
                  error={error?.message}
                  withAsterisk={true}
                  {...field}
                />
              )}
            />

            <Controller
              name="is_free"
              control={control}
              rules={{ required: t("select-option") }}
              render={({ field, fieldState: { error } }) => (
                <SelectInput
                  label={t("paid-free")}
                  placeholder={t("select-option")}
                  data={[
                    { value: "free", label: `${t("Free")}` },
                    { value: "paid", label: `${t("Paid")}` },
                  ]}
                  error={error?.message}
                  withAsterisk={true}
                  {...field}
                />
              )}
            />

            {is_freeWatch === "paid" && (
              <Controller
                name="price"
                control={control}
                rules={{
                  required: t("amount-required"),
                  min: { value: 1, message: t("amount-greater-than-zero") },
                }}
                render={({ field, fieldState: { error } }) => (
                  <NumberInput
                    label={t("amount-of-money")}
                    placeholder={t("enter-amount")}
                    error={error?.message}
                    withAsterisk={true}
                    min={1}
                    {...field}
                  />
                )}
              />
            )}

            <Controller
              name="description"
              control={control}
              rules={{ required: t("description-required") }}
              render={({ field, fieldState: { error } }) => (
                <TextAreaInput
                  label={t("description")}
                  placeholder={t("enter-description")}
                  error={error?.message}
                  minRows={4}
                  withAsterisk={true}
                  {...field}
                />
              )}
            />

            <div
              style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}
            >
              <Button
                variant="cancel"
                color="#323922"
                disabled={false}
                loading={false}
                onClick={onClose}
              >
                {t("cancel")}
              </Button>
              <Button
                disabled={false}
                loading={false}
                type="filled"
                onClick={handleSubmit(onSubmit)}
                variant="primary"
                color="#323922"
                backgroundColor="#CCF075"
              >
                {community_id ? t("save-changes") : t("create")}
              </Button>
            </div>
          </Stack>
        </form>
      )} 
    </Modal>
  );
});

export default ModalCreateCommunity;
