import React from 'react'
import { Modal, Button, Stack, Text } from "@mantine/core";
import success from '../../Assets/SVGs/programs/success.svg';
import failed from '../../Assets/SVGs/programs/failed.svg';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mantine/hooks';


const NotificationModal = ({modalOpened, closeModal, status,message}) => {
  const { t } = useTranslation();
  const xs = useMediaQuery('(max-width : 768px)');
  return (
    <Modal
    opened={modalOpened}
    onClose={closeModal}
    centered
    radius={15}
    size={619}
    >
    <Stack align="center" justify="center" spacing={8}>
      {status === "Accepted" ? (
        <>
          <img src={success} alt="Success" />
          <Text mt={10} size={xs ? 20 : 28} weight={600} color="#23A26D">
            {t('success')}
          </Text>
          <Text mt={10} align='center' size={xs ? 16 : 17} weight={500} color="#797979">
            {message || t('success_message')}
          </Text>
        </>
      ) : (
        <>
          <img src={failed} alt="Failed" />
          <Text size={xs ? 20 : 28} weight={600} color="#FF0000">
            {t('failed')}
          </Text>
          <Text size={xs ? 14 : 17} weight={500} color="#797979">
            {t('error_message')}
          </Text>
        </>
      )}
      <Button
        fullWidth
        onClick={closeModal}
        radius={29}
        mt={30}
        styles={() => ({
          root: {
            width:  "90%",
            height:  xs ? "40px" : "50px",
            fontSize:xs ?  "14px" : "16px",
            backgroundColor:
              status === "Accepted" ? "#23A26D" : "#FF0000",
            border: "none",
            "&:hover": {
              backgroundColor:
                status === "Accepted" ? "#23A26D" : "#FF0000",
            },
          },
        })}
      >
        {
          status === "Accepted" ? t('done') : t('try_again')
        }
      </Button>
    </Stack>
  </Modal>
  )
}

export default NotificationModal