import EmojiPicker from 'emoji-picker-react';
import { Popover } from '@mantine/core';
import styled from 'styled-components';
import { useMediaQuery } from '@mantine/hooks';

const StyledEmojiPickerWrapper = styled.div`
  .epr-search-container {
    input{
      padding-left: 35px !important;
    }
  }
`;

const CustomEmojiPicker = ({ onEmojiClick, opened, setOpened }) => {
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Popover 
      opened={opened} 
      onChange={setOpened}
      position={isMobile ? "bottom" : "top"}
      shadow="md"
      styles={{
        dropdown: {
          padding: 0,
          border: '1px solid #e0e0e0',
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          position: isMobile ? 'fixed' : 'absolute',
          left: isMobile ? '50%' : 'auto',
          top: '50%',
          transform: isMobile ? 'translate(-50%, -50%)' : 'translateX(-50%)',
          zIndex: 1000,
          maxWidth: '95vw',
          width: isMobile ? '300px' : '300px',
          margin: '0 auto',
        },
        arrow: {
          display: 'none',
        }
      }}
    >
      <Popover.Target>
        <div 
          onClick={() => setOpened((o) => !o)}
          style={{ cursor: 'pointer' }}
        >
          😊
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <StyledEmojiPickerWrapper>
          <EmojiPicker
            onEmojiClick={onEmojiClick}
            autoFocusSearch={false}
            searchPlaceholder="Search emoji..."
            width="100%"
            height={isMobile ? 350 : 400}
            lazyLoadEmojis={true}
            skinTonesDisabled
            categories={['smileys_people', 'animals_nature', 'food_drink', 'objects', 'symbols', 'flags']}
            style={{
              '--epr-bg-color': '#ffffff',
              '--epr-category-label-bg-color': '#f0f0f0',
              '--epr-picker-border-radius': '8px',
              '--epr-hover-bg-color': '#f5f5f5',
              '--epr-search-border-color': '#e0e0e0',
              '--epr-search-border-radius': '8px',
              '--epr-search-placeholder-color': '#909090',
              '--epr-search-input-bg-color': '#ffffff',
              '--epr-search-input-padding': '8px 12px 8px 40px',
              '--epr-category-navigation-button-size': isMobile ? '20px' : '24px',
            }}
          />
        </StyledEmojiPickerWrapper>
      </Popover.Dropdown>
    </Popover>
  );
};

export default CustomEmojiPicker;
