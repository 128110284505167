import { DropDownCategoryStyled } from "../../style";
import { mockCategories } from "../../mockData";
import { Group, Stack, Text, Skeleton } from "@mantine/core";
import { X } from "tabler-icons-react";
import { useGetAllCategories } from "../../hooks/category/useGetAllCategories";

const DropDownCategory = ({ handleCategory, onClose }) => {
  const handleCategoryClick = (e) => {
    onClose();
    handleCategory(e);
  };

  const { data: categories, isLoading } = useGetAllCategories();

  return (
    <DropDownCategoryStyled>
      <div className="scroll-container">
        <Stack direction="row" spacing="md">
          {isLoading ? (
            Array(5).fill(0).map((_, index) => (
              <Group pr={10} key={`skeleton-${index}`} noWrap>
                <Skeleton circle height={24} width={24} />
                <Skeleton height={20} width="100%" />
              </Group>
            ))
          ) : (
            categories?.map((category) => (
              <Group 
                pr={10} 
                key={category.id}
                onClick={() => handleCategoryClick(category.id)}
                style={{ cursor: 'pointer' }}
                noWrap
              >
                <div dangerouslySetInnerHTML={{ __html: category.svg_icon }} />
                <Text size="16px" color="#393838" weight={500}>
                  {category.name}
                </Text>
              </Group>
            ))
          )}
        </Stack>
      </div>
      <div className="circle" onClick={onClose}>
        <X size={13} color="#fff" strokeWidth={4} />
      </div>
    </DropDownCategoryStyled>
  );
};

export default DropDownCategory;
