import { useQueries  } from "@tanstack/react-query";
import { getCommentReaction } from "../../../../services/apiPost";

export const useGetCommentReaction = (communityId,postId,allCommentIds) => {
    const queries = useQueries({
        queries:allCommentIds.map(commentId=>({
            queryKey: ['comment-reaction', communityId, postId, commentId],
            queryFn: () => getCommentReaction({community_id: communityId, post_id: postId, comment_id: commentId}),
        }))
    });
    return queries
}