import React, { useContext, useEffect } from "react";
import { CommunityLayout } from "./CommunityPanel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetHighlight } from "../../features/Community/hooks/highlight/useGetHighlight";
import PostPanel from "../../features/Community/components/community-panel/post-panel";
import { AppContext } from "../../Helpers/Context";

const CommunityHighlight = ({type}) => {
  const { id, highlightId } = useParams();
  const { data: highlight, isLoading } = useGetHighlight(id, highlightId);

  const { user } = useContext(AppContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isOwner = user?.id === highlight?.author?.id;

  useEffect(() => {
    if (
      user &&
      highlight &&
      isOwner &&
      pathname === `/community/${id}/highlight/${highlightId}`
    ) {
      navigate(`/community/${id}/highlight/${highlightId}/owner`);
    }
  }, [isOwner, id, highlightId, pathname, navigate, user, highlight]);

  return (
    <CommunityLayout>
      <PostPanel
        isOwner={isOwner}
        type="highlight"
        highlight={[highlight]}
        isLoading={isLoading}
      />
    </CommunityLayout>
  );
};

export default CommunityHighlight;
