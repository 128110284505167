import { useQuery } from "@tanstack/react-query";
import { getHighlightPost } from "../../services/apiHighlight";
import { useHighlight } from "./useHighlight";


export const useGetHighlightPost = (communityId, highlightId, postId,type) => {
    const {queryKeys} = useHighlight();
    return useQuery({
        queryKey: queryKeys.detailPost(communityId, highlightId, postId,type),
        queryFn: () => getHighlightPost({community_id: communityId, highlight_id: highlightId, post_id: postId}),
        enabled: !!communityId && !!highlightId && !!postId && type === "highlight",
    })
}
