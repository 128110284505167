import { useMutation } from "@tanstack/react-query";
import { useHighlight } from "./useHighlight";
import { deleteHighlightPost } from "../../services/apiHighlight";

export const useDeleteHighlightPost = (community_id, highlight_id,post_tab,label) => {
    const {invalidateHighlightQueries, handleError, handleSuccess} = useHighlight();
    return useMutation({
        mutationFn: ({community_id, highlight_id, post_id}) => deleteHighlightPost({community_id, highlight_id, post_id}),
        onSuccess: () => {
            invalidateHighlightQueries(community_id,highlight_id,null,"allPost",post_tab,null);
            handleSuccess('Highlight post silindi');
        },
        onError: (error) => handleError(error),
    })
}
