import React from 'react';
import { Tabs } from '@mantine/core';
import { StyledTabs } from '../../../style';
import { useTranslation } from 'react-i18next'; 

const PostTabs = ({setPostTab}) => {
  const handleTabChange = (value) => {
    setPostTab(value);
    // Scroll to PostList
    const postList = document.querySelector('.post-list-container');
    if (postList) {
      postList.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const { t } = useTranslation();

  return (
    <StyledTabs defaultValue="posts" onTabChange={handleTabChange}>
      <Tabs.List>
        <Tabs.Tab value="posts">{t("posts")}</Tabs.Tab>
        <Tabs.Tab value="quizes">{t("quizes")}</Tabs.Tab>
        <Tabs.Tab value="hashtags">{t("hashtags")}</Tabs.Tab>
      </Tabs.List>
    </StyledTabs>
  );
};

export default PostTabs;