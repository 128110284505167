import { showNotification } from "@mantine/notifications";

export const useCategory = () => {
    const queryKeys = {
        all: ['categories'],
        cover: (id) => ['category', id, 'cover'],
    }

    const handleError = (error) => {
        showNotification({
            title: 'Xəta',
            message: error?.message || 'Xəta baş verdi',
            color: 'red',
        });
    }   

    return {
        queryKeys,
        handleError,
    }
}