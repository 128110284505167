import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Grid } from "@mantine/core";
import styled, { keyframes, css } from "styled-components";
import MyView from "./MyView";
import AboutView from "./About";
import ActivityView from "./ActivityView";
import EducationView from "./EducationView";
import StudyInterest from "./StudyInterest";
import ProfessionSkill from "./ProfessionSkill";
import SikllEndorsement from "./SkillEndorsement";
import SoftSkills from "./SoftSkills";
import Language from "./Language";
import Certificate from "./Certificate";
import ShareProfile from "../Aside/ShareProfile";
import Subscription from "../Aside/Subscription";
import JoinedPrograms from "../Aside/JoinedPrograms";
import { AppContext } from "../../../Helpers/Context";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "../../../Helpers/Axios";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import LottieLoading from "../../../Components/Loaders/LottieLoading"; // LottieLoading komponentini import edirik
import UserCountry from "./UserCountry";
import UserCity from "./UserCity";
import UserSchoolType from "./UserSchoolType";
import UserGrade from "./UserGrade";
import InfoBanner from "../../../Components/Profile/InfoBanner";

const Profile = ({ type }) => {
  const { user, isChangedLocal, setIsChangedLocal } = useContext(AppContext);
  const { id: guid } = useParams();
  const link = guid ? `/api/userskill?guid=${guid}` : `/api/userskill/`;
  const { t } = useTranslation();
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [selectedSchoolTypeId, setSelectedSchoolTypeId] = useState(null);
  const [selectedGradeId, setSelectedGradeId] = useState(null);

  const sm = useMediaQuery("(max-width: 768px)");
  const [selectedSkillHeaderId, setSelectedSkillHeaderId] = useState(null);

  const { data: publicUser, isLoading: isPublicUserLoading } = useQuery(
    ["user", guid],
    async () => {
      const response = await axios.get(`/api/v1/user-data-with-guid/${guid}/`);
      return response.data;
    },
    {
      enabled: !!guid,
    }
  );
  const queryClient = useQueryClient();

  const { data: skillDatas } = useQuery({
    queryKey: ["skillDatas", selectedSkillHeaderId],
    queryFn: async () => {
      return await axios
        .get(`/api/skillsbyheaderid/${selectedSkillHeaderId}/`)
        .then((res) => res.data);
    },
    enabled: !!selectedSkillHeaderId,
  });

  const { data: countries } = useQuery({
    queryKey: ["countries"],
    queryFn: async () => {
      const res = await axios.get("/api/countries/");
      return res.data.sort((a, b) => a.name.localeCompare(b.name));
    },
  });

  const { data: cities } = useQuery({
    queryKey: ["cities", selectedCountryId ? selectedCountryId : user?.country],
    queryFn: async () => {
      const response = await axios.get(
        `/api/cities/${selectedCountryId ? selectedCountryId : user?.country}/`
      );
      return response.data;
    },
    enabled: !!selectedCountryId || !!user?.country,
  });

  const { data: grades } = useQuery({
    queryKey: ["grades"],
    queryFn: async () => {
      const response = await axios.get(`/api/v1/student-grades/`);
      return response.data;
    },
  });

  const { mutate: createUserSkill } = useMutation({
    mutationFn: async (data) => {
      return await axios.post(`/api/create-userskill/`, data);
    },
    onSuccess: async (data, variables, context) => {
      queryClient.invalidateQueries(["userSkills"]);
    },
    onError: (error, variables, context) => {
      console.log("error", error);
    },
  });

  const { mutate: deleteUserSkill } = useMutation({
    mutationFn: async (id) => {
      return await axios.delete(`/api/userskill/${id}/`);
    },
    onSuccess: async (id, variables, context) => {
      queryClient.invalidateQueries(["userSkills"]);
    },
    onError: (error, variables, context) => {
      console.log("error", error);
    },
  });

  const { data: userSkills, isLoading: isUserSkillsLoading } = useQuery({
    queryKey: guid ? ["userSkills", guid] : ["userSkills"],
    queryFn: async () => {
      return await axios.get(link).then((res) => res.data);
    },
    enabled: !!link, // Ensure the query runs when `link` changes
  });

  const { mutate: editUser } = useMutation({
    mutationFn: async (data) => {
      const response = await axios.patch(
        `/api/v1/edit-user/${user?.id}/`,
        data
      );
      return response.data;
    },
    onSuccess: (data) => {
      localStorage.setItem("taskooluser", JSON.stringify(data));
      setIsChangedLocal(!isChangedLocal);
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const { data: joinedCourses } = useQuery({
    queryKey: ["joinedCourses"],
    queryFn: async () => {
      const response = await axios.get("/bootcamp/applied");
      return response.data.results;
    },
  });

  const { data : joinedSteams} = useQuery({
    queryKey: ["programList-applied"],
    queryFn: async () => {
      const response = await axios.get("/steam/steam/applied");
      return response.data.results;
    },
  })

  const filterSkillsByHeader = (header) => {
    return userSkills?.filter((skill) => skill.skill.header === header);
  };

  const profileUrl = `https://taskool.com/profile/${user?.guid}`;

  const userName = publicUser?.first_name || user?.first_name || "";
  const userSurname = publicUser?.last_name || user?.last_name || "";
  const isStudent = user?.user_type?.type === "Student";
  // Əgər əsas data yüklənməyibsə, LottieLoading göstəririk
  if ((guid && isPublicUserLoading) || isUserSkillsLoading) {
    return <LottieLoading />;
  }

  return (
    <StyledProfile guid={guid}>
      <Helmet>
        <title>{t("taskool_profile")}</title>
        <meta
          name="description"
          content={t("profile_description", {
            name: `${userName} ${userSurname}`,
          })}
        />
      </Helmet>
      {user && user?.required_empty_data?.length > 0 && <InfoBanner />}

      <ProfileWrapper>
        <ProfileContainer guid={guid}>
          <MyView
            t={t}
            user={user}
            publicUser={publicUser}
            isChangedLocal={isChangedLocal}
            setIsChangedLocal={setIsChangedLocal}
          />
          {!guid && sm && (
            <AsideContainer>
              <ShareProfile t={t} url={profileUrl} />
              <Subscription t={t} />
              <JoinedPrograms t={t} />
            </AsideContainer>
          )}
          <AboutView t={t} user={user} publicUser={publicUser} />
          <Grid spacing={20}>
            <Grid.Col md={6}>
              <UserCountry
                t={t}
                selectDatas={countries}
                selectedData={selectedCountryId}
                handleDataId={setSelectedCountryId}
                selectedCountryId={selectedCountryId}
                publicUser={publicUser}
                user={user}
                handleEditUser={editUser}
                type="country"
                isStudent={isStudent}
              />
            </Grid.Col>
            <Grid.Col md={6}>
              <UserCity
                t={t}
                selectDatas={cities}
                selectedData={selectedCityId}
                selectedCountryId={selectedCountryId}
                selectedCityId={selectedCityId}
                handleDataId={setSelectedCityId}
                publicUser={publicUser}
                user={user}
                handleEditUser={editUser}
                type="city"
                isStudent={isStudent}
              />
            </Grid.Col>
            {user?.user_type?.type === "Student" && (
              <Grid.Col md={6}>
                <UserSchoolType
                  t={t}
                  selectDatas={[
                    { id: 0, name: "Private" },
                    { id: 1, name: "Public" },
                  ]}
                  selectedData={selectedSchoolTypeId}
                  handleDataId={setSelectedSchoolTypeId}
                  publicUser={publicUser}
                  user={user}
                  handleEditUser={editUser}
                  type="school_type"
                />
              </Grid.Col>
            )}
            {user?.user_type?.type === "Student" && (
              <Grid.Col md={6}>
                <UserGrade
                  t={t}
                  selectDatas={grades}
                  selectedData={selectedGradeId}
                  handleDataId={setSelectedGradeId}
                  publicUser={publicUser}
                  user={user}
                  handleEditUser={editUser}
                  type="grade"
                />
              </Grid.Col>
            )}
          </Grid>
          {isStudent && (
            <>
              <ActivityView t={t} user={user} publicUser={publicUser} />
              <EducationView t={t} user={user} publicUser={publicUser} />
              <StudyInterest
                t={t}
                userSkills={filterSkillsByHeader(4)}
                user={user}
                selectDatas={skillDatas}
                handleSkillHeaderId={setSelectedSkillHeaderId}
                handleCreateUserSkill={createUserSkill}
                handleDeleteUserSkill={deleteUserSkill}
                publicUser={publicUser}
              />
              <SikllEndorsement
                t={t}
                user={user}
                selectDatas={skillDatas}
                handleSkillHeaderId={setSelectedSkillHeaderId}
                handleCreateUserSkill={createUserSkill}
                userSkills={filterSkillsByHeader(5)}
                handleDeleteUserSkill={deleteUserSkill}
                publicUser={publicUser}
              />

              <ProfessionSkill
                t={t}
                userSkills={filterSkillsByHeader(2)}
                user={user}
                selectDatas={skillDatas}
                handleSkillHeaderId={setSelectedSkillHeaderId}
                handleCreateUserSkill={createUserSkill}
                handleDeleteUserSkill={deleteUserSkill}
                publicUser={publicUser}
              />
              <SoftSkills
                t={t}
                userSkills={filterSkillsByHeader(1)}
                user={user}
                selectDatas={skillDatas}
                handleSkillHeaderId={setSelectedSkillHeaderId}
                handleCreateUserSkill={createUserSkill}
                handleDeleteUserSkill={deleteUserSkill}
                publicUser={publicUser}
              />
              <Language
                t={t}
                userSkills={filterSkillsByHeader(3)}
                user={user}
                selectDatas={skillDatas}
                handleSkillHeaderId={setSelectedSkillHeaderId}
                handleCreateUserSkill={createUserSkill}
                handleDeleteUserSkill={deleteUserSkill}
                publicUser={publicUser}
              />
              <Certificate t={t} user={user} publicUser={publicUser} />
            </>
          )}
        </ProfileContainer>
        {!guid && !sm && (
          <AsideContainer>
            <ShareProfile t={t} url={profileUrl} />
            <Subscription t={t} />
            {joinedCourses && joinedCourses.length > 0 && <JoinedPrograms t={t} joinedProgram={joinedCourses} type="course" />}
            {joinedSteams && joinedSteams.length > 0 && <JoinedPrograms t={t} joinedProgram={joinedSteams} type="steam" />}

          </AsideContainer>
        )}
      </ProfileWrapper>
    </StyledProfile>
  );
};

export default Profile;

const pulse = keyframes`
  0% {
    border-color: #FFE5E5;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.4);
  }
  
  70% {
    border-color: #FF5252;
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  
  100% {
    border-color: #FFE5E5;
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
`;

export const ViewContainer = styled.div`
  box-shadow: 0px 1px 10px 0px #0000001a;
  width: 100%;
  border-radius: 11px;
  background-color: #fff;
  border: 1px solid
    ${(props) => (props.isRequired && props.isEmpty ? "#FF5252" : "#fff")};
  background-color: ${(props) =>
    props.isRequired && props.isEmpty ? "#FFF5F5" : "#fff"};
  ${(props) =>
    props.isRequired &&
    props.isEmpty &&
    css`
      animation: ${pulse} 2s infinite;
    `}
  transition: all 0.3s ease;
`;

const StyledProfile = styled.div`
  max-width: ${(props) => (props.guid ? "90%" : "95%")};
  margin: 0 auto;
`;

const ProfileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  label {
    margin-bottom: 5px;
  }
`;

const ProfileContainer = styled.div`
  width: ${(props) => (props.guid ? "100%" : "77%")};
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 0 0 40px 0;

  //   accordion custom styles
  .mantine-1h6pkea.mantine-Accordion-chevron {
    display: none !important;
  }
  .mantine-Accordion-item.mantine-v4lv9f {
    border-bottom: 0px !important;
  }
  .mantine-UnstyledButton-root.mantine-Accordion-control.mantine-21wjnm {
    border-bottom: 1px solid #e1e1e1 !important;
    padding-left: 0px !important;
    margin-bottom: 8px;
  }
  .mantine-UnstyledButton-root.mantine-Accordion-control.mantine-21wjnm[aria-expanded="false"] {
    border-bottom: 0px !important;
    padding: 10px 0px !important;
    margin-bottom: 0px;

    .mantine-Text-root.mantine-o3i2x9 {
      font-weight: 500 !important;
      font-size: 16px !important;
      color: #979797 !important;
    }
  }
  .mantine-ukbbnm.mantine-Accordion-content {
    padding-left: 0px !important;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const AsideContainer = styled.div`
  width: 20%;
  height: 160vh;
  position: sticky;
  top: 100px;
  display: flex;
  flex-direction: column;
  gap: 25px;
  @media (max-width: 768px) {
    width: 100%;
    position: relative;
    top: 0;
    height: 100%;
  }
`;
