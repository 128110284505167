import React from "react";
import PostLayout from "./PostLayout";
import { Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const TextPost = ({ post }) => {
  const xs = useMediaQuery('(max-width: 768px)');
  const renderText = (text) => {
    const parts = text.split(/(#\w+)/);
    
    return parts.map((part, index) => {
      if (part.startsWith('#')) {
        return <Text key={index} component="span" weight={700}>{part}</Text>;
      }
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <Text size={xs ? 13 : 16} weight={400} color="202020" lh={xs ? "20px" : "28px"}>
      {renderText(post.text)}
    </Text>
  );
};

export default TextPost;
