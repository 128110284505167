import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usePost } from "../../usePost";
import { unreactToComment } from "../../../../services/apiPost";

export const useUnreactToComment = (communityId,postId,commentId) => {
    const {handleError, invalidatePostQueries} = usePost();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({community_id, post_id, comment_id, data}) => unreactToComment({community_id, post_id, comment_id, data}),
        onSuccess: (_, variables) => {
            const {community_id, post_id, comment_id} = variables;
            queryClient.invalidateQueries({ queryKey: ['all-comment-reactions', community_id, post_id, comment_id] });
            queryClient.invalidateQueries({ queryKey: ['comment-reaction', community_id, post_id, comment_id] });
        },
        onError: (error) => {
            handleError(error);
        },
    });
}