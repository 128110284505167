import { useQuery } from "@tanstack/react-query";
import { useCommunity } from "./useCommunity";
import { getCommunity } from "../../services/apiCommunity";

export const useGetCommunity = (id,highlightId) => {
    const {queryKeys,handleError} = useCommunity();
    return useQuery({
        queryKey: queryKeys.detail(id),
        queryFn: () => getCommunity(id),
        onError: (error) => handleError(error),
        enabled: !!id && !highlightId,
    })
}