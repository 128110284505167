import { useMutation } from "@tanstack/react-query";
import { deleteHighlightPostImage } from "../../services/apiHighlight";
import { useHighlight } from "./useHighlight";

export const useDeleteHighlightPostImage = ({community_id, highlight_id, post_id}) => {
    const {invalidateHighlightQueries, handleError, handleSuccess} = useHighlight();
    return useMutation({
        mutationFn: ({community_id, highlight_id, post_id, image_id}) => deleteHighlightPostImage({community_id, highlight_id, post_id, image_id}),
        onSuccess: () => {
            invalidateHighlightQueries(community_id,highlight_id,post_id);
            handleSuccess('Highlight post şəkl silindi');
        },
        onError: (error) => handleError(error),
    })
}
