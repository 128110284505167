import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion"; // Framer Motion import edin
import { Text, Title } from "@mantine/core";
import { Link } from "react-router-dom";

const LandingCard = ({ props }) => {
  const { t, icon, title, text , to } = props;

  return (
   <Link className="cardWrapper" style={{textDecoration : "none"}} to={to}>
    <Card
      whileHover={{ scale: 1.05, backgroundColor: "#c9f6f9" }} // Hover animasiyaları üçün Framer Motion istifadə edin
      initial={{ backgroundColor: "#fff" }}
      transition={{duration: 1}}
    >
      <motion.div
       whileHover={{ scale: 1.05, backgroundColor: "#c9f6f9" }} // Hover animasiyaları üçün Framer Motion istifadə edin
       initial={{ backgroundColor: "#fff" }}
       className="img-con">
        <img src={icon} alt="icon-1" />
      </motion.div>
      <Title color="#3a3a3a" size={18} weight={700}>
        {t(title)}
      </Title>
      <Text color="#585858" size={16} weight={400} className="text-content">
        {t(text)}
      </Text>
    </Card>
   </Link>
  );
};

export default LandingCard;

const Card = styled(motion.div)` // motion.div olaraq Card-i sarın
  width: 100%;
  cursor : pointer;
  background-color: #fff;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
  gap: 10px;
  padding: 20px 20px 20px 30px;
  min-height: 210px;
  cursor : pointer;

  .img-con {
    width: 44px;
    height: 41px;
    border-radius: 7px;
    background-color: #c9f6f9;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    width: 24px;
    height: 24px;
  }
  .text-content {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
  }

  &:hover .text-content {
    -webkit-line-clamp: unset; // Hover zamanı line-clamp-i yığışdırın
  }

  @media (max-width: 768px) {
    width: 70%;
    gap: 15px;
    padding: 20px 20px 28px 30px;
  }
`;
