import React, { useState } from "react";
import { useGetCommunity } from "../../features/Community/hooks/community/useGetCommunity";
import { useParams } from "react-router-dom";
import useGetAllHighligts from "../../features/Community/hooks/highlight/useGetAllHighligts";
import { CommunityLayout } from "./CommunityPanel";
import { Grid, Group, Pagination } from "@mantine/core";
import HighlightCard from "../../features/Community/components/highlight/HighlightCard";
import { CommunityHeader } from "../../features/Community/components/community-panel/post-panel/CommunityHeader";
import { useMediaQuery } from "@mantine/hooks";
import ModalCreateHighlight from "../../features/Community/components/modal/ModalCreateHighlight";
import Button from "../../Components/FormComponent/Button";
import { Plus } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
const CommunityHighlights = () => {
  const { id } = useParams();
  const { data: community, isLoading } = useGetCommunity(id);
  const [page, setPage] = useState(1);
  const { data: highlights, isLoading: isLoadingHighlights } =
    useGetAllHighligts(id, page);

  const [openHighlightModal, setOpenHighlightModal] = useState(false);

  const itemsPerPage = 10;
  const totalPages = Math.ceil((highlights?.count || 0) / itemsPerPage);
  const xs = useMediaQuery("(max-width:768px");
  const { t } = useTranslation();

  return (
    <CommunityLayout>
      <CommunityHeader
        type="community"
        community={community}
        isLoading={isLoading}
        route="highlight-list"
      />
      <Group position="right">
        <Button
          backgroundColor="transparent"
          border="none"
          color="#FE6513"
          leftIcon={<Plus size={xs ? 18 : 20} />}
          onClick={() => setOpenHighlightModal(true)}
          mb={xs ? 2 : 0}
        >
          {t("create-highlight")}
        </Button>
      </Group>
      <Grid mt={xs ? 10 : 20} align="center" justify={highlights?.results?.length > 1 ? "center" : "left"}>
        {highlights?.results?.map((highlight) => (
          <Grid.Col
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            md={6}
            key={highlight.id}
          >
            <HighlightCard
              highlight={{
                communityId: id,
                id: highlight.id,
                image: highlight.image,
                name: highlight.name,
                posts: highlight.posts_count || 0,
                date: new Date(highlight.created_at).toLocaleDateString(
                  "en-US",
                  {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }
                ),
              }}
            />
          </Grid.Col>
        ))}
      </Grid>
      <div style={{ width: xs ? "90%" : "97%", margin: "0 auto" }}>
        <Pagination
          page={page}
          onChange={setPage}
          total={totalPages}
          mt={20}
          siblings={1}
          boundaries={2}
          color="cyan"
          styles={(theme) => ({
            button: {
              backgroundColor: "#029199",
              border: "1px solid #A8AEB4",
              color: "#A8AEB4",
              fontSize: "14px",
              fontWeight: 500,
              height: "32px",
              minWidth: "32px",
              padding: "0 6px",

              "&[data-active]": {
                backgroundColor: "#029199 !important",
                borderColor: "#029199",
                color: theme.white,
              },

              "&:hover": {
                backgroundColor: "#f1f3f5 !important",
                borderColor: "#A8AEB4",
              },

              "&[data-active]:hover": {
                backgroundColor: "#027b82 !important",
              },
            },
          })}
        />
      </div>

      <ModalCreateHighlight
        firstTime={false}
        communityId={id}
        opened={openHighlightModal}
        onClose={() => setOpenHighlightModal(false)}
      />
    </CommunityLayout>
  );
};

export default CommunityHighlights;
