import React from 'react';
import { Skeleton } from '@mantine/core';
import PostLayoutSkeleton from './PostLayoutSkeleton';

const MediaPostSkeleton = () => {
  return (
    <PostLayoutSkeleton>
      <div>
        {/* Text content */}
        <Skeleton height={14} width="90%" mb={16} />
        
        {/* Media grid */}
        <div style={{ 
          borderRadius: 8,
          overflow: 'hidden',
          aspectRatio: '16/9',
          maxHeight: 400
        }}>
          <Skeleton height="100%" width="100%" radius={8} />
        </div>
      </div>
    </PostLayoutSkeleton>
  );
};

export default MediaPostSkeleton; 