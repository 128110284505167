import { useQuery } from "@tanstack/react-query";
import { getReactions } from "../../../services/apiPost";


export const useGetReactions = (communityId,postId) => {

    return useQuery({
        queryKey: ["reactions",postId, communityId],
        queryFn: () => getReactions({community_id: communityId, post_id: postId}),
    });
};
