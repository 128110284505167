import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Stack, Text } from '@mantine/core';

const CommunityTabs = ({tabs}) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Stack spacing={15}>
      {tabs.map((tab, index) => (
        <div
          key={index}
          onClick={() => navigate(tab.path)}
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            gap: '10px',
            cursor: 'pointer',
            position: 'relative',
            paddingBottom: '5px'
          }}
        >
          <tab.icon size={20} color={location.pathname === tab.path ? "#029199" : "#364152"} />
          <Text
            size="15px"
            weight={500}
            color={location.pathname === tab.path ? "#029199" : "#364152"}
            style={{ position: 'relative' }}
          >
            {tab.title}
            {location.pathname === tab.path && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '-5px',
                  left: 0,
                  width: '100%',
                  height: '2px',
                  backgroundColor: '#029199'
                }}
              />
            )}
          </Text>
        </div>
      ))}
    </Stack>
  );
};

export default CommunityTabs; 