import { Card, Text } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React from "react";
import { Plus } from "tabler-icons-react";
import { useTranslation } from "react-i18next";

const CreateCommunityCard = ({ setOpened }) => {
  const {t} = useTranslation()
  const xs = useMediaQuery('(max-width: 576px)');
  
  // Split the translation text into two parts
  const [firstWord, secondWord] = t("create-group").split('\n');

  return (
    <Card
      radius="21px"
      sx={{
        minHeight: xs ? "156px" : "226px",
        height: "100%",
        border: "1px solid #029199",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#f8f9fa",
        },
      }}
      onClick={() => setOpened(true)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: xs ? "40px" : "55px",
            height: xs ? "40px" : "55px",
            borderRadius: "50%",
            border: xs ? "1px solid #029199" : "2px solid #029199",
          }}
        >
          <Plus size={xs ? 20 : 24} color="#029199" />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Text style={{textTransform: "capitalize"}} align="center" size={xs ? 14 : 19} weight={500} color="#029199">
            {firstWord}
          </Text>
          <Text style={{textTransform: "capitalize"}} align="center" size={xs ? 14 : 19} weight={500} color="#029199">
            {secondWord}
          </Text>
        </div>
      </div>
    </Card>
  );
};

export default CreateCommunityCard;
