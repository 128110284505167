import { useMutation, useQueryClient } from "@tanstack/react-query";
import { reactToComment } from "../../../../services/apiPost";
import { usePost } from "../../usePost";

export const useReactToComment = () => {  // parametreleri kaldırıyoruz
    const { handleError } = usePost();
    const queryClient = useQueryClient();
  
    return useMutation({
      mutationFn: ({ community_id, post_id, comment_id, data }) =>  // tüm parametreleri buradan alıyoruz
        reactToComment({
          community_id,
          post_id,
          comment_id,
          data,
        }),
      onSuccess: (_, variables) => {  // variables parametresini ekliyoruz
        // variables'dan gerekli değerleri alıyoruz
        const { community_id, post_id, comment_id } = variables;
        
        queryClient.invalidateQueries({
          queryKey: ["all-comment-reactions", community_id, post_id, comment_id],
        });
        queryClient.invalidateQueries({
          queryKey: ["comment-reaction", community_id, post_id, comment_id],
        });
      },
      onError: (error) => {
        handleError(error);
      },
    });
  };