import axios from "../../../Helpers/Axios"

export const getAnalyticsData = async (communityId, dates) => {
  const params = new URLSearchParams();
  
  if (dates.to_date) {
    params.append('to_date', dates.to_date);
  }
  if (dates.from_date) {
    params.append('from_date', dates.from_date);
  }

  const response = await axios.get(`community/${communityId}/analytics?${params}`);
  return response.data;
};

