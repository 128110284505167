import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteReaction, unreactToCommunityPost } from "../../../services/apiPost";
import { usePost } from "../usePost";

export const useUnreactToPost = (communityId,postId,highlightId) => {
    const {handleError, invalidatePostQueries} = usePost();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({postId}) => deleteReaction({community_id: communityId, post_id: postId}),
        onSuccess: () => {
            if(highlightId){
                queryClient.invalidateQueries({ queryKey: ['all-highlight-reactions', communityId, postId,highlightId] });
                queryClient.invalidateQueries({ queryKey: ['highlight-reaction', communityId, postId,highlightId] });
            }else{
                queryClient.invalidateQueries({ queryKey: ['all-reactions', communityId, postId] });
                queryClient.invalidateQueries({ queryKey: ['reactions',postId, communityId] });
            }
        },
        onError: (error) => {
            handleError(error);
        },
    });
};
