import React from 'react';
import { Link } from 'react-router-dom';
import { IconBookmark } from '@tabler/icons-react';
import { useTranslation } from "react-i18next";

const SavedPostsSection = ({ id }) => {
  const {t} = useTranslation()
  return (  
  <Link to={`/community/${id}/saved`} className="panel-section saved-posts">
    <IconBookmark color="#029199" size={24} />
    <span className="section-title">{t('saved-posts')}</span>
  </Link>
);
};

export default SavedPostsSection; 