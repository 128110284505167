import { CommunityHeaderStyled } from "../../../style";
import {
  Group,
  Text,
  Skeleton,
  Menu,
  NumberInput,
  Select,
} from "@mantine/core";
import Button from "../../../../../Components/FormComponent/Button";
import {
  IconShare3,
  IconEdit,
} from "@tabler/icons-react";
import PostTabs from "./PostTabs";
import { useParams, useNavigate } from "react-router-dom";
import ShareDropdown from "./ShareDropdown";
import { useContext, useState } from "react";
import { useCommunityContext } from "../../../context/CommunityContext";
import { useUpdateCommunity } from "../../../hooks/community/useUpdateCommunity";
import { AppContext } from "../../../../../Helpers/Context";
import { useTranslation } from "react-i18next";

const CommunityHeaderSkeleton = () => {
  return (
    <CommunityHeaderStyled>
      <div className="top-side">
        <Skeleton height={160} radius="md" />
      </div>
      <div style={{ paddingTop: "40px" }} className="bottom-side">
        <Group position="apart">
          <Skeleton height={32} width={200} radius="md">
            <Text size="24px" weight={600} color="#202020">
              Community Name
            </Text>
          </Skeleton>
          <Group spacing={10}>
            <Skeleton height={40} width={100} radius="md" />
            <Skeleton height={40} width={40} circle />
            <Skeleton height={40} width={120} radius="md" />
          </Group>
        </Group>
        <PostTabs />
      </div>
    </CommunityHeaderStyled>
  );
};



export const CommunityHeader = ({
  type,
  highlight,
  community,
  isLoading,
  setPostTab,
  route,
}) => {
  const { t } = useTranslation();
  const data = type === "highlight" ? highlight[0] : community;
  const { id, highlight_id } = useParams();
  const navigate = useNavigate();
  const { setCommunityData, setCommunityId } = useCommunityContext();
  const image =
    type === "highlight"
      ? `https://taskilled.blob.core.windows.net/taskool-store/${highlight[0]?.image}`
      : community?.cover_photo_url;
  const [showShareDropdown, setShowShareDropdown] = useState(false);
  const [showPaymentMenu, setShowPaymentMenu] = useState(false);
  const [paymentType, setPaymentType] = useState(
    community?.is_free ? "free" : "paid"
  );
  const [amount, setAmount] = useState(community?.price || "");

  const getShareUrl = () => {
    const baseUrl = "https://taskool.com";
    if (type === "highlight") {
      return `${baseUrl}/community/${id}/highlight/${highlight_id}`;
    }
    return `${baseUrl}/community/${id}`;
  };
  const { mutate: updateCommunityPayment } =
    useUpdateCommunity(id);
  const {user} = useContext(AppContext);

  const handleEditBanner = () => {
      const existData = {
        cover_photo: data.cover_photo,
        category: data.category,
        name: data.name,
        description: data.description,
        lang_id: data.lang_id,
        price: data.price,
        is_free: data.is_free,
      };
      setCommunityData(existData);
      setCommunityId(id);
      localStorage.setItem("communityData", JSON.stringify(existData));
      localStorage.setItem("updated_community_id", id);
      navigate(`/categories/${data.category}/cover-photo/edit`);
    
   
  };

  const handleSavePayment = async () => {
    const updatedData = {
      cover_photo: data.cover_photo,
      category: data.category,
      name: data.name,
      description: data.description,
      lang_id: data.lang_id,
      is_free: paymentType === "free",
      price: paymentType === "paid" ? amount : 0,
    };
    updateCommunityPayment({ id, data: updatedData });
    setShowPaymentMenu(false);
  };


  const is_owner = data?.author?.id === user?.id;

  if (isLoading) return <CommunityHeaderSkeleton />;
  return (
    <CommunityHeaderStyled>
      <div className="top-side">
        <img src={image} alt="community-cover" />
        {(is_owner && type === 'community') && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "30px",
              height: "30px",
              borderRadius: "50px",
              boxShadow: "0 0 0 0.5em 0",
              position: "absolute",
              bottom: "10px",
              right: "10px",
              background: "#FFF",
            }}
          >
            <IconEdit onClick={handleEditBanner} size={20} color="#ECA200" />
          </div>
        )}
      </div>
      <div
        style={{ paddingBottom: route === "highlight-list" ? "20px" : "0px" }}
        className="bottom-side"
      >
        <Group position="apart" noWrap>
          <Text
            style={{ lineHeight: "31px" }}
            size="20px"
            weight={600}
            color="#202020"
          >
            {data?.name}
          </Text>
          <div className="button-groups">
            <div style={{ position: "relative" }}>
              {data?.is_joined && is_owner ? (
                <Menu opened={showPaymentMenu} onChange={setShowPaymentMenu}>
                  <Menu.Target>
                    <Button
                      disabled={false}
                      loading={false}
                      variant="primary"
                      backgroundColor="transparent"
                      color="#ECA200"
                      padding="8px 29px"
                      border="1px solid #707070"
                      leftIcon={<IconEdit color="#ECA200" size={20} />}
                    >
                      {(type === "community" && community?.is_free) ||
                      type === "highlight"
                        ? t("free")
                        : `${t("Paid")} ${community?.price}₼`}
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <div style={{ padding: "12px", width: "160px" }}>
                      <Select
                        label={t("paid-free")}
                        value={paymentType}
                        onChange={setPaymentType}
                        data={[
                          { value: "free", label: t("Free") },
                          { value: "paid", label: t("Paid") },
                        ]}
                        styles={{
                          root: {
                            width: "100%",
                          },
                          input: {
                            border: "1px solid #E4E4E4",
                            borderRadius: "8px",
                            height: "36px",
                            width: "100%",
                          },
                          label: {
                            marginBottom: "4px",
                            color: "#202020",
                            fontSize: "14px",
                            fontWeight: 500,
                          },
                          dropdown: {
                            border: "1px solid #E4E4E4",
                            borderRadius: "8px",
                          },
                        }}
                      />

                      {paymentType === "paid" && (
                        <NumberInput
                          label={t("amount-of-money")}
                          value={amount}
                          onChange={setAmount}
                          min={0}
                          placeholder={t("amount-of-money")}
                          hideControls
                          style={{ marginTop: "12px" }}
                          styles={{
                            root: {
                              width: "100%",
                            },
                            input: {
                              border: "1px solid #E4E4E4",
                              borderRadius: "8px",
                              height: "36px",
                              width: "100%",
                            },
                            label: {
                              marginBottom: "4px",
                              color: "#202020",
                              fontSize: "14px",
                              fontWeight: 500,
                            },
                          }}
                        />
                      )}

                      <Group noWrap position="apart" mt="md" >
                        <Button
                          variant="subtle"
                          onClick={() => setShowPaymentMenu(false)}
                          styles={{
                            root: {
                              color: "#202020",
                              backgroundColor: "transparent",
                              border: "1px solid #E4E4E4",
                              width: "49%",  
                              borderRadius: "8px",
                              padding: "0px",
                              height: "32px",
                              fontSize: "12px",
                              "&:hover": {
                                backgroundColor: "#f8f8f8",
                              },
                            },
                          }}
                        >
                          {t("cancel")}
                        </Button>
                        <Button
                          onClick={handleSavePayment}
                          styles={{
                            root: {
                              backgroundColor: "#FF6934",
                              color: "white",
                              borderRadius: "8px",
                              width: "49%",
                              height: "32px",
                              padding: "0px",
                              fontSize: "12px",
                              "&:hover": {
                                backgroundColor: "#ff5a20",
                              },
                            },
                          }}
                        >
                          {t("save")}
                        </Button>
                      </Group>
                    </div>
                  </Menu.Dropdown>
                </Menu>
              ) : (
                <Button
                  border="none"
                  color="#ECA200"
                  variant="outline"
                  backgroundColor="transparent"
                  padding="8px 16px"
                >
                  {t("joined")}
                </Button>
              )}
            </div>

            <Button
              onClick={() => setShowShareDropdown(true)}
              disabled={false}
              loading={false}
              variant="primary"
              backgroundColor="#eee"
              color="#202020"
              leftIcon={<IconShare3 size={20} />}
              padding="8px 29px"
            >
              {t("share")}
            </Button>
          </div>
        </Group>
        {route !== "highlight-list" ? (
          <PostTabs setPostTab={setPostTab} />
        ) : null}
      </div>

      <ShareDropdown
        isOpen={showShareDropdown}
        onClose={() => setShowShareDropdown(false)}
        url={getShareUrl()}
      />
    </CommunityHeaderStyled>
  );
};


