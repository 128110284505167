import React, { useEffect, useState } from 'react';
import { CoverPhotoStyled } from "../../features/Community/style";
import CoverCard from "../../features/Community/components/category/CoverCard";
import { Group, Stack, Text, Skeleton } from "@mantine/core";
import Button from "../../Components/FormComponent/Button";
import { useCommunityContext } from '../../features/Community/context/CommunityContext';
import { useGetCategoryCover } from '../../features/Community/hooks/category/useGetCategoryCover';
import { useParams } from 'react-router-dom';
import { useCreateCommunity } from '../../features/Community/hooks/community/useCreateCommunity';
import CoverCardSkeleton from '../../features/Community/components/skeletons/CoverPhotoSkeleton';
import { useMediaQuery } from '@mantine/hooks';
import { useUpdateCommunity } from '../../features/Community/hooks/community/useUpdateCommunity';
import { et } from 'date-fns/locale';
import { useGetAllCategories } from '../../features/Community/hooks/category/useGetAllCategories';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => props.$isMobile ? 'repeat(1, 1fr)' : 'repeat(1, 0.9fr)'};
  gap: 20px;
  justify-content: center;
  padding: 0 ${props => props.$isMobile ? '20px' : '0'};
  
  @media (max-width: 768px) {
    grid-template-rows: auto;
    grid-auto-flow: row;
  }
`;

const CoverPhotoList = ({type}) => {
  const {id} = useParams();
  const [selectedCover, setSelectedCover] = useState(null);
  const { communityData, updateCommunityData , communityId} = useCommunityContext();
  const {data : categoryData} = useGetAllCategories();
  const localCommunityData = JSON.parse(localStorage.getItem('communityData'));
  const updatedCommunityId = localStorage.getItem('updated_community_id');
  const {data : coverPhotos , isLoading} = useGetCategoryCover(id);
  const {mutate: createCommunity , isLoading: isCreating} = useCreateCommunity();
  const {mutate: updateCommunity , isLoading: isUpdating} = useUpdateCommunity(
    updatedCommunityId || communityId
  );
  const [categoryName, setCategoryName] = useState('');
  const handleCoverSelect = (id) => {
    setSelectedCover(id);
    updateCommunityData('cover_photo', id);
    const updatedData = { ...localCommunityData, cover_photo: id };
    localStorage.setItem('communityData', JSON.stringify(updatedData));
  };

  const handleCreate = () => {
    if (type === "create") {
      if(communityData.name !== ''){
        createCommunity(communityData);
      } else {
        createCommunity(localCommunityData);
      }
    } else {
      if(communityData.name !== '' && communityId){
        updateCommunity({id : communityId, data: communityData});
      } else {
        updateCommunity({id : updatedCommunityId, data: localCommunityData});
      }
    }
  }

  useEffect(() => {
    if (localCommunityData?.cover_photo) {
      setSelectedCover(localCommunityData?.cover_photo);
    }
  }, [localCommunityData]);
  useEffect(() => {
    if(categoryData){
      const categoryName = categoryData?.find(item => item.id === Number(id))?.name;
      setCategoryName(categoryName);
    }
  }, [categoryData, id]);


  const xs = useMediaQuery('(max-width: 768px)'); 
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <CoverPhotoStyled>
        <Text pt={ xs ? 20 : 30} pb={ xs ? 20 : 30} pl={ xs ? 20 : 55} size={20} weight={500} color="#202020">
          {t("cover-photo")}
        </Text>
        <div className="cover-photo-content">
          <Stack mb={50} align="center" spacing={5}>
            <Skeleton height={20} width={250} radius="sm" />
            <Skeleton height={16} width={200} radius="sm" />
          </Stack>

          <Group position='center'>
            {[1, 2, 3, 4].map((index) => (
              <CoverCardSkeleton key={index} />
            ))}
          </Group>
        </div>
        <Group 
          style={{
            width: '100%', 
            paddingTop: 30, 
            paddingBottom: 30,
            paddingRight: 45
          }} 
          align="center" 
          position="right"
        >
          <Button variant="cancel" disabled>
            {t("cancel")}
          </Button>
          <Button variant="primary" disabled>
            {t("next")}
          </Button>
        </Group>
      </CoverPhotoStyled>
    );
  }

  return (
    <CoverPhotoStyled>
      <Text pt={ xs ? 20 : 30} pb={ xs ? 20 : 30} pl={ xs ? 20 : 55} size={20} weight={500} color="#202020">
        {t("cover-photo")}
      </Text>
      <div className="cover-photo-content">
        <Stack mb={ xs ? 30 : 50} align="center" spacing={5}>
          <Text size={20} weight={500} color="#202020">
            {t("choose-for-math-statistics", { categoryName })}
          </Text>
          <Text size={16} weight={500} color="#6E6E6E">{t("choose-cover-photo-community")}</Text>
        </Stack>

        <GridContainer $isMobile={xs}>
          {coverPhotos?.map((item) => (
            <CoverCard 
              key={item.id}
              img={item.image} 
              isSelected={selectedCover === item.id}
              onClick={() => handleCoverSelect(item.id)}
            />
          ))}
        </GridContainer>
      </div>
      <Group style={{width: '100%', paddingTop: xs ? 25 : 30, paddingBottom: xs ? 25 : 30,paddingRight: xs ? 20 : 45}} align="center" position={xs ? "center" : "right"}>
        <Button
          disabled={false}
          loading={false}
          to='/categories'
          variant="cancel">
          {t("cancel")}
        </Button>
        <Button disabled={!selectedCover} loading={isCreating} onClick={handleCreate} variant="primary" backgroundColor="#CCF075" color="#323922">
          {t("next")}
        </Button>
      </Group>
    </CoverPhotoStyled>
  );
};

export default CoverPhotoList;
