import React, { useEffect, useState } from "react";
import { CommunitiesStyled } from "./style";
import { Text } from "@mantine/core";
import CommunityFilter from "./components/CommunityFilter";
import CommunityTabs from "./components/CommunityTabs";
import ModalCreateHighlight from "./components/modal/ModalCreateHighlight";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetAllCommunity } from "./hooks/community/useGetAllCommunity";
import NotificationModal from "../../Components/Modals/NotificationModal";
import { useTranslation } from "react-i18next";

const Communities = () => {
  const {t} = useTranslation()
  const [opened, setOpened] = useState(true);
  const [searchParams] = useSearchParams();
  const communityId = localStorage.getItem("communityId");
  const isFirstHighlight = localStorage.getItem("isFirstHighlight");
  const id = searchParams.get("id");
  const createHighlight = searchParams.get("createHighlight");
  const navigate = useNavigate();
  const [queryfilterParams, setQueryfilterParams] = useState({
    search: "",
    category: "",
  });
  const queryParams = {
    all: {},
    managed: { managed: true },
    joined: { joined: true },
  };
  const [activePage, setActivePage] = useState(1);
  const [activeTab, setActiveTab] = useState("all");
  const { data, isLoading } = useGetAllCommunity(
    queryParams[activeTab],
    activePage,
    queryfilterParams
  );
  const [hasActiveFilter, setHasActiveFilter] = useState(false);
  const [hasActiveTab, setHasActiveTab] = useState(false);
  const [isShowNotificationModal , setIsShowNotificationModal] = useState(false)

  useEffect(() => {
    if (communityId) {
      navigate(`/communities?id=${communityId}&createHighlight=active`);
    }
  }, [communityId, navigate]);

  useEffect(() => {
    setHasActiveFilter(
      !!(queryfilterParams.search || queryfilterParams.category)
    );
  }, [queryfilterParams.search, queryfilterParams.category]);

  useEffect(() => {
    setHasActiveTab(activeTab !== "all");
  }, [activeTab]);

  return (
    <>
      <CommunitiesStyled>
        <Text style={{borderBottom: "1px solid #E4E4E4"}} pb={8} mb={8} size={20} weight={500} color="#202020">
          {t("community")}
        </Text>
        {(data?.count > 0 || (hasActiveFilter || hasActiveTab)) && (
          <CommunityFilter
            queryfilterParams={queryfilterParams}
            setQueryfilterParams={setQueryfilterParams}
          />
        )}
        <CommunityTabs
          data={data}
          isLoading={isLoading}
          setActivePage={setActivePage}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
          activePage={activePage}
          hasActiveFilter={hasActiveFilter}
          hasActiveTab={hasActiveTab}
        />
      </CommunitiesStyled>
      {(communityId || (id && createHighlight === "active")) && (
        <ModalCreateHighlight
          firstTime={true}
          communityId={communityId || id}
          opened={opened}
          onClose={() => setOpened(false)}
          closeOnClickOutside={false} // Add this line
          onShowNoticiationModal={setIsShowNotificationModal}
        />
      )}

       {
        (isShowNotificationModal && isFirstHighlight) &&
           <NotificationModal 
           modalOpened={true}
           closeModal={() => {
            localStorage.removeItem('isFirstHighlight');
            setIsShowNotificationModal(false)}}
            status={'Accepted'}
            message="Qrupunuz Taskool komandası tərəfindən yoxlanılır. Yoxlama prosesi 
            tamamlandıqdan sonra qrupunuz aktiv olacaq və siz networking 
            fəaliyyətinizə başlaya biləcəksiniz."
           />
       }
      
    </>
  );
};

export default Communities;
